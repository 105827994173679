import { AUTH_TOKEN } from "./ApiService";
import { firebaseService } from "./FirebaseService";

class AuthService {
  static getInstance(): AuthService {
    return new AuthService();
  }

  async signInWithGoogle() {
    await firebaseService.signInWithGoogle();
    await this._fetchAndStoreToken();
  }

  async signInWithApple() {
    await firebaseService.signInWithApple();
    await this._fetchAndStoreToken();
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    await firebaseService.signInWithEmailAndPassword(email, password);
    await this._fetchAndStoreToken();
  }

  async signUpWithEmailAndPassword(email: string, password: string) {
    await firebaseService.signUpWithEmailAndPassword(email, password);
    await this._fetchAndStoreToken();
  }

  async sendPasswordResetEmail(email: string) {
    await firebaseService.sendPasswordResetEmail(email);
  }

  private async _fetchAndStoreToken() {
    const token = await firebaseService.fetchToken();
    localStorage.setItem(AUTH_TOKEN, token!);
  }
}

export const authService = AuthService.getInstance();
