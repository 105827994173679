import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Switch,
  Text,
  Flex,
  FormErrorMessage,
  useDisclosure,
  HStack,
  Box,
  Image,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { z } from "zod";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import { Privacy } from "../../enums/privacy";
import useSnackBar from "../../hooks/useSnackBar";
import { PlaylistModel } from "../../models/PlaylistModel";
import TextAreaField from "../../shared/textareafield/TextAreaField";
import TextField from "../../shared/textfield/TextField";
import { MdCloudUpload, MdDelete, MdInfo } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import DeleteConfirmationDialog from "../../shared/delete-confirmation-dialog/DeleteConfirmationDialog";
import DeleteButton from "../../shared/delete-button/DeleteButton";

interface EditPlaylistDialogProps extends GlobalProps {
  playlist: PlaylistModel;
  isOpen: boolean;
  onClose: () => void;
}

const EditPlaylistDialog: React.FC<EditPlaylistDialogProps> = ({
  playlist,
  isOpen,
  onClose,
  store,
}) => {
  const notify = useSnackBar();
  const history = useHistory();
  const playlistStore = store?.playlistStore;
  const orgStore = store?.organizationRoleStore;
  const deletePlaylistConfirmationDialogController = useDisclosure();
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [hasThumbnailChanged, setHasThumbnailChanged] = useState(false);
  const onDropThumbnail = React.useCallback((files: File[]) => {
    setThumbnail(files[0]);
  }, []);
  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
  } = useDropzone({ onDrop: onDropThumbnail });
  const _deletePlaylist = async () => {
    await playlistStore?.deletePlaylist(playlist.uid);
    notify("success", "Deleted playlist");
    onClose();
    history.push(`/org/${orgStore?.selectedOrganizationId!}/playlists`);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: playlist.title || "",
      description: playlist.description || "",
      isPubliclyVisible: playlist.privacy === Privacy.PUBLIC,
      email: playlist.contact ? playlist.contact.email || "" : "",
      phone: playlist.contact ? playlist.contact.phoneNumber || "" : "",
    },
    validate: (values) => {
      const PlaylistSchema = z.object({
        title: z.string().min(1, "Title is required"),
        description: z.string().optional(),
        isPubliclyVisible: z.boolean(),
        previewUrl: z.string().url().optional(),
        email: z.string().email().or(z.literal("")),
        phone: z.string().optional(),
      });

      const result = PlaylistSchema.safeParse(values);

      if (!result.success) {
        const errors: FormikErrors<z.infer<typeof PlaylistSchema>> = {};

        for (const issue of result.error.issues) {
          errors[issue.path[0] as keyof typeof errors] = issue.message;
        }

        return errors;
      }
    },
    onSubmit: async (values) => {
      const playlistStore = store!.playlistStore;

      const updateObj = {
        title: values.title,
        description: values.description,
        privacy: values.isPubliclyVisible ? Privacy.PUBLIC : Privacy.UNLISTED,
        previewUrl: hasThumbnailChanged ? null : playlist.previewUrl,
        contact: {
          email: values.email,
          phoneNumber: values.phone,
        },
      };

      try {
        setUploading(true);
        await playlistStore?.updatePlaylist(playlist.uid, updateObj, thumbnail);

        notify("success", `Playlist updated`);
      } catch (e) {
        notify("error", `Problem editing playlist. Please try again.`);
      }
      setUploading(false);
      onClose();
    },
  });

  // Shorthand for repeated attributes in fields
  function _fields<Key extends keyof typeof formik.values>(name: Key) {
    return {
      id: name,
      name: name,
      value: formik.values[name],
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      isInvalid: formik.touched[name] && Boolean(formik.errors[name]),
    };
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent p="40px" borderRadius="21px">
        <ModalCloseButton />
        <Heading fontSize="2xl" my="20px">
          Edit Playlist
        </Heading>
        <HStack spacing={6} alignItems="space-between">
          <Box width="50%">
            <TextField
              type="text"
              {..._fields("title")}
              value={formik.values.title}
              error={formik.errors.title}
              placeholder="Title"
              label="Title"
            />
            <TextAreaField
              {..._fields("description")}
              value={formik.values.description}
              error={formik.errors.description}
              label="Description"
              placeholder="Description"
            />
            <TextField
              type="email"
              {..._fields("email")}
              value={formik.values.email}
              error={formik.errors.email}
              label="Email"
              placeholder="contact@example.com"
            />

            <TextField
              type="tel"
              {..._fields("phone")}
              value={formik.values.phone}
              error={formik.errors.phone}
              label="Phone number"
              placeholder="+1 323-810-6923"
            />
          </Box>
          <Box>
            <FormLabel color="gray.500" fontWeight="600" fontSize="xs">
              Thumbnail
            </FormLabel>
            {!thumbnail ? (
              <Flex
                width="320px"
                height="180px"
                backgroundColor="#F7F7F7"
                borderRadius="9px"
                direction="column"
                justifyContent="center"
                alignItems="center"
                fontSize="xs"
                color="gray"
                {...getThumbnailRootProps()}
              >
                {playlist.previewUrl && !hasThumbnailChanged ? (
                  <>
                    <Image
                      width="320px"
                      height="180px"
                      borderRadius="lg"
                      src={playlist.previewUrl}
                    />
                    <input {...getThumbnailInputProps()} />
                  </>
                ) : (
                  <>
                    <MdCloudUpload size="38px" color="gray" />
                    <Text my="4px">Drag and drop a file here to upload</Text>
                    <input {...getThumbnailInputProps()} />
                  </>
                )}
              </Flex>
            ) : (
              <Image
                width="320px"
                height="180px"
                borderRadius="lg"
                src={URL.createObjectURL(thumbnail)}
              />
            )}

            <HStack mt="6px">
              <MdInfo color="gray" />
              <Text fontWeight="400" fontSize="0.7rem" color="gray">
                Recommended size 800x400
              </Text>
              {thumbnail && (
                <Button
                  onClick={() => setThumbnail(null)}
                  size="xs"
                  variant="ghost"
                >
                  Change
                </Button>
              )}
              {!thumbnail && playlist.previewUrl && !hasThumbnailChanged && (
                <Button
                  onClick={() => setHasThumbnailChanged(true)}
                  size="xs"
                  variant="ghost"
                >
                  Delete Thumbnail
                </Button>
              )}
            </HStack>
            <FormControl
              mt="3rem"
              width="320px"
              {..._fields("isPubliclyVisible")}
              mb={4}
            >
              <Flex alignItems="center">
                <Switch
                  colorScheme="primary"
                  id="isPubliclyVisible"
                  mr={2}
                  size="sm"
                />
                <Text color="brand" fontSize="xs">
                  Make this playlist public
                </Text>
              </Flex>
              <Text fontWeight="400" fontSize="0.7rem" color="gray">
                If the playlist is public, anyone will be able to view it. If
                the playlist isn't public, only admins will be able to see the
                playlist.
              </Text>
              <FormErrorMessage>
                {formik.errors.isPubliclyVisible}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </HStack>
        <HStack mt="20px" justifyContent="space-between">
          <DeleteButton
            onClick={deletePlaylistConfirmationDialogController.onOpen}
          >
            Delete playlist
          </DeleteButton>
          <Button
            onClick={async () => {
              try {
                const errors = await formik.validateForm();
                if (Object.keys(errors).length === 0) {
                  await formik.submitForm();
                }
              } catch (e) {
                return;
              }
            }}
            colorScheme="secondary"
            isLoading={uploading}
          >
            Save
          </Button>
        </HStack>

        <ModalBody mb={4}>
          {playlistStore && (
            <>
              <DeleteConfirmationDialog
                title={playlist.title}
                onDelete={_deletePlaylist}
                onClose={deletePlaylistConfirmationDialogController.onClose}
                isOpen={deletePlaylistConfirmationDialogController.isOpen}
              />
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default inject("store")(observer(EditPlaylistDialog));
