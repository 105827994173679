import { useContext } from "react";
import {
  SnackbarContext,
  VariantType,
} from "../shared/snackbar-provider/SnackBarProvider";

const useSnackBar = () => {
  const snackBarContext = useContext(SnackbarContext);
  return (variant: VariantType, message: string) => {
    snackBarContext.show!(variant, message);
  };
};
export default useSnackBar;
