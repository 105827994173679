import React, { useState } from "react";
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import TextField from "../../../shared/textfield/TextField";

interface ConfirmPasswordDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
}

const ConfirmPasswordDialog: React.FC<ConfirmPasswordDialogProps> = (
  props: ConfirmPasswordDialogProps
) => {
  const { isOpen, onClose, onSubmit } = props;
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const validatePassword = () => {
    if (!password || !confirmPassword || password !== confirmPassword) {
      setError("Password do not match.");
      return;
    }
    onSubmit(password);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Heading fontSize="lg">Password</Heading>
          <TextField
            value={password}
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Heading fontSize="lg">Confirm password</Heading>
          <TextField
            value={confirmPassword}
            name="password"
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {!!error && (
            <Text fontSize="xs" color="error">
              {error}
            </Text>
          )}
          <ModalFooter>
            <Button onClick={validatePassword} colorScheme="primary">
              Submit
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmPasswordDialog;
