import React, { useEffect } from "react";
import BillingSuccessPage from "../components/BillingSuccessPage/BillingSuccessPage";
import { BusinessPlan } from "../../enums/business-plan";
import { ACCOUNT_ID, PLAN_ID } from "../../utils/ConstantUtils";
import useSnackBar from "../../hooks/useSnackBar";
import { useHistory, useLocation } from "react-router";
import { CommonUtils } from "../../utils/CommonUtils";
import { stripeService } from "../../services/StripeService";
import { HttpError } from "../../services/ApiService";

const BillingSuccessView: React.FC = (props: any) => {
  const notify = useSnackBar();
  const history = useHistory();
  const location = useLocation();
  const { provision_id: provisionId } = CommonUtils.parseQueryString(
    location.search
  );
  const loadProvisions = async (accountId: string, delay = 100) => {
    try {
      const provisions = await stripeService.listProvisions(accountId);
      const currentProvision = provisions.filter(
        (p) => p.uid === provisionId
      )[0];
      if (currentProvision && currentProvision.active) {
        history.push(`/org/${currentProvision.orgId}/profile`);
        localStorage.removeItem(ACCOUNT_ID);
        localStorage.removeItem(PLAN_ID);
      } else {
        setTimeout(() => {
          loadProvisions(accountId, delay * 2);
        }, delay);
      }
    } catch (error) {
      notify("error", (error as HttpError).message);
    }
  };
  useEffect(() => {
    const accountId = localStorage.getItem(ACCOUNT_ID);
    if (accountId && provisionId) {
      loadProvisions(accountId).then();
    }
    // eslint-disable-next-line
    }, [provisionId]);
  return (
    <BillingSuccessPage plan={localStorage.getItem(PLAN_ID) as BusinessPlan} />
  );
};

export default BillingSuccessView;
