import { Image, Box, Heading, Icon, Skeleton } from "@chakra-ui/react";
import { mdiEye, mdiPlaylistPlay } from "@mdi/js";
import { useQuery } from "react-query";
import { contentService } from "../services/ContentService";

interface ContentPreviewProps {
  id: string;
  viewCount: number;
}

export function SkeletonContentPreview() {
  return (
    <Box display="inline-block" p="4" w="16em" shadow="md" rounded="lg">
      <Skeleton rounded="lg" w="full" h="24" mb="2" />

      <Box display="flex" alignItems="center">
        <Skeleton w="full" rounded="md" h="4" />
      </Box>
      <Box mt="2" display="flex" justifyContent="end">
        <Skeleton w="8" h="4" />
      </Box>
    </Box>
  );
}

export default function ContentPreview(props: ContentPreviewProps) {
  const content = useQuery(["/content", props.id], () =>
    contentService.getContent(props.id)
  );

  if (content.isLoading || content.isIdle) {
    return <SkeletonContentPreview />;
  }

  if (content.error || !content.data) {
    return <>There was an error loading the this content.</>;
  }

  return (
    <Box
      display="inline-block"
      width="16em"
      shadow="md"
      p={4}
      rounded="lg"
      mr="2"
    >
      <Box mb={2}>
        {content.data.previewUrl && (
          <Image
            maxH="120px"
            rounded="lg"
            src={content.data.previewUrl}
            objectFit="cover"
          />
        )}
      </Box>

      <Heading
        size="sm"
        overflow="hidden"
        textOverflow="ellipsis"
        noOfLines={2}
      >
        {content.data.title}
      </Heading>

      <Box
        display="flex"
        color="gray.400"
        alignItems="center"
        justifyContent="end"
      >
        <Icon mr="1">
          <path d={mdiEye} fill="currentColor" />
        </Icon>

        {props.viewCount}
      </Box>
    </Box>
  );
}
