/**
 * PUBIC ROUTE GUARD:
 * Takes a component and route path as a prop.
 *
 * Route guard for accessing all the routes which can only be accessed
 * when user is not authenticated. For ex. Login.
 * Checks for the auth token in local storage. If the auth token is not present, it allows
 * the navigation else it redirects to the dashboard component depending on the logged in user state.
 *
 */

import React from "react";
import { inject } from "mobx-react";
import { Redirect, Route } from "react-router";

const PublicRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const rootStore = rest.store;
  const authStore = rootStore.authStore;
  if (!authStore.isLoggedIn) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return (
      <Route {...rest}>
        <Redirect to="/" />
      </Route>
    );
  }
};

export default inject("store")(PublicRoute);
