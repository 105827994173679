import Store from "./Store";
import { OrganizationModel } from "../models/OrganizationModel";
import { RootStore } from "./RootStore";
import { action, computed, observable, runInAction } from "mobx";
import { organizationService } from "../services/OrganizationService";
import { CreateOrganisationDto } from "../dtos/create-organisation.dto";

export class OrganizationStore extends Store<OrganizationModel> {
  @observable isLoading = false;
  @observable isLoaded = false;

  constructor(private rootStore: RootStore) {
    super();
    OrganizationModel._store = this;
  }

  @action
  async fetchAllOrganizations() {
    if (this.isLoading || this.isLoaded) {
      return;
    }
    this.isLoading = true;
    try {
      await organizationService.listOrganizations();
      runInAction(() => {
        this.isLoaded = true;
        this.isLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
      });
      throw e;
    }
  }

  @action
  async deleteOrganization(orgId: string) {
    try {
      await organizationService.deleteOrganization(orgId);
      runInAction(() => {
        this.entities = this.entities.filter((e) => e.id !== orgId);
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async createOrganization(organisation: CreateOrganisationDto) {
    try {
      await organizationService.createOrganization(organisation);
    } catch (e) {
      throw e;
    }
  }

  @action
  async generateJoinUrl(orgId: string) {
    const org = this.get(orgId);
    if (!orgId) {
      return;
    }
    try {
      this.isLoading = true;
      const joinUrl = await organizationService.generateJoinUrl(orgId);
      runInAction(() => {
        this.isLoading = false;
        return OrganizationModel.fromJson({
          ...org?.toJson(),
          publicJoinUrl: joinUrl.joinUrl,
        });
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
      throw error;
    }
  }

  @computed
  get organizations(): OrganizationModel[] {
    return this.entities.filter(
      (e) =>
        e.uid !== this.rootStore.organizationRoleStore?.selectedOrganizationId
    );
  }
}
