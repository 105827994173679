/**
 * A generic class used to store models as entities.
 */
import { action, observable } from "mobx";

import Model from "../models/Model";

export type EntityIdentifier = string | number;

export abstract class Store<M extends Model> {
  @observable entities: M[] = [];
  @observable isEntityAdding = false;

  /**
   * Retrieves the entity based in unique identifier.
   * @param id Unique identifier
   */
  public get(id: EntityIdentifier): M | undefined {
    return this.entities.filter((e) => e.id === id)[0];
  }

  @action
  public setIsEntityAdding(val: boolean) {
    this.isEntityAdding = val;
  }

  /**
   * Pushed the new entity in the array if its not already present.
   * @param entity Entity to be pushed.
   */
  @action
  public push(entity: M) {
    const exists = this.entities.find((e) => e.id === entity.getId());
    exists || this.entities.push(entity);
  }
  /**
   * Pushed an array of entities in the array.
   * @param entities Array of entity to be pushed.
   */
  @action
  public addAll(entities: M[]) {
    entities.forEach(this.push.bind(this));
  }
}

export default Store;
