import React, { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import TextField from "../../../shared/textfield/TextField";

interface PasswordDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
}

const PasswordDialog: React.FC<PasswordDialogProps> = (
  props: PasswordDialogProps
) => {
  const { isOpen, onClose, onSubmit } = props;
  const [password, setPassword] = useState<string>("");
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack align="left" spacing={4}>
            <Heading fontSize="lg">Enter your password</Heading>
            <TextField
              value={password}
              name="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link as={ReactRouterLink} to="/forgot-password" color="brand">
                Forgot password?
              </Link>
              <Button onClick={() => onSubmit(password)} colorScheme="primary">
                Login
              </Button>
            </Box>
          </VStack>
          <ModalFooter></ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PasswordDialog;
