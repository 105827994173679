import { action, observable } from "mobx";
import { PlaylistModel } from "./PlaylistModel";
import { ContentModel } from "./ContentModel";
import Model from "./Model";
import { RootStore } from "../stores/RootStore";

export class AnalyticsModel extends Model {
  static _store: RootStore;
  @observable playlists!: PlaylistModel[];
  @observable content!: ContentModel[];
  @observable totalStats!: {
    views: number;
    watchTimeMs: number;
  };

  getId(): string | number {
    return "";
  }

  @action
  deserializeContent(data: any) {
    this.content = data.map(
      (d: any) => ContentModel.fromJson(d) as ContentModel
    );
  }

  @action
  deserializePlaylists(data: any) {
    this.playlists = data.map(
      (d: any) => PlaylistModel.fromJson(d) as PlaylistModel
    );
  }
}
