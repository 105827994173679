import React from "react";
import { RootStore } from "./stores/RootStore";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";
import PublicRoute from "./guards/PublicRoute";
import PageNotFound from "./errors/page-not-found/PageNotFound";
import AuthenticatedRoute from "./guards/AuthenticatedRoutes";
import NoPlan from "./no-plan/NoPlan";
import * as serviceWorker from "./serviceWorker";
import Invite from "./invite/Invite";
import { Button, Heading, HStack, Spinner, useToast } from "@chakra-ui/react";
import LoginView from "./auth/views/LoginView";
import MagicLinkAuthenticationView from "./auth/views/MagicLinkAuthenticationView";
import ForgetPasswordView from "./auth/views/ForgetPasswordView";
import SignupView from "./auth/views/SignupView";
import SelectAccountTypeView from "./onboarding/views/SelectAccountTypeView";
import SelectBillingAccountView from "./onboarding/views/SelectBillingAccountView";
import CreateBillingAccountView from "./onboarding/views/CreateBillingAccountView";
import StripeCheckoutView from "./onboarding/views/StripeCheckoutView";
import BillingSuccessView from "./onboarding/views/BillingSuccessView";
import BillingCancelledView from "./onboarding/views/BillingCancelledView";
import OnboardingUserDetailsView from "./onboarding/views/OnboardingUserDetailsView";
import OnboardingCompanyDetailsView from "./onboarding/views/OnboardingCompanyDetailsView";
import Dashboard from "./dashboard/Dashboard";
import LogoutProvider from "./shared/logout-provider/LogoutProvider";

export interface GlobalProps {
  store?: RootStore;
}

const App: React.FC<GlobalProps> = (props: GlobalProps) => {
  const isInternallyChecking = props.store?.authStore?.isInternallyChecking;
  const toast = useToast();

  const serviceWorkerConfig = {
    onUpdate: (registration: ServiceWorkerRegistration) => {
      /* Detect updated service worker "waiting" and show Toast */
      const callback = () => {
        // Attempt to accept registration and reload page
        if (registration && registration.waiting)
          registration.waiting?.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      };
      toast({
        duration: null,
        isClosable: true,
        position: "top",
        render: () => (
          <HStack
            padding="8px 16px"
            borderRadius="4px"
            backgroundColor="black"
            color="white"
          >
            <Heading fontSize="sm">
              A new version of the app is available!
            </Heading>
            <Button onClick={callback} size="xs" colorScheme="primary">
              Update
            </Button>
          </HStack>
        ),
      });
    },
    // onSuccess: _ => { /* unimplemented */ },
    // onFail: _ => { /* unimplemented */ },
    // onNoConnection: _ => { /* unimplemented */ },
  };
  serviceWorker.register(serviceWorkerConfig);

  return isInternallyChecking ? (
    <Spinner
      thickness="6px"
      color="secondary.500"
      size="xl"
      pos="absolute"
      top="50%"
      left="50%"
      transform="translateY(-50%, -50%)"
    />
  ) : (
    <Router>
      <LogoutProvider>
        <Switch>
          <PublicRoute exact path="/login" component={LoginView} />
          <PublicRoute exact path="/sign-up" component={SignupView} />
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgetPasswordView}
          />
          <PublicRoute
            exact
            path="/authenticate"
            component={MagicLinkAuthenticationView}
          />
          <AuthenticatedRoute path="/no-plan" component={NoPlan} />
          <AuthenticatedRoute path="/invite" component={Invite} />
          <AuthenticatedRoute
            exact
            path="/billing/success"
            component={BillingSuccessView}
          />
          <AuthenticatedRoute
            exact
            path="/billing/cancelled"
            component={BillingCancelledView}
          />
          <AuthenticatedRoute
            exact
            path="/billing/:accountId"
            component={StripeCheckoutView}
          />
          <AuthenticatedRoute
            exact
            path="/account-type"
            component={SelectAccountTypeView}
          />
          <AuthenticatedRoute
            exact
            path="/select-billing-account"
            component={SelectBillingAccountView}
          />
          <AuthenticatedRoute
            exact
            path="/create-billing-account"
            component={CreateBillingAccountView}
          />
          <AuthenticatedRoute
            exact
            path="/on-boarding/details/user"
            component={OnboardingUserDetailsView}
          />
          <AuthenticatedRoute
            exact
            path="/on-boarding/details/company"
            component={OnboardingCompanyDetailsView}
          />
          <AuthenticatedRoute path="/" component={Dashboard} />

          <Route
            render={(props) => (
              <div>
                <PageNotFound />
              </div>
            )}
          />
        </Switch>
      </LogoutProvider>

      {/*{*/}
      {/*    <Snackbar*/}
      {/*        anchorOrigin={{*/}
      {/*            vertical: 'top',*/}
      {/*            horizontal: 'center',*/}
      {/*        }}*/}
      {/*        open={state.newVersionAvailable}*/}
      {/*        message='A new version of the app is available!'*/}
      {/*        action={*/}
      {/*            <React.Fragment>*/}
      {/*                <button onClick={updateServiceWorker}>Reload</button>*/}
      {/*            </React.Fragment>*/}
      {/*        }*/}
      {/*    />*/}
      {/*}*/}
    </Router>
  );
};

export default inject("store")(observer(App));
