import { OrganizationRoleModel } from "../models/OrganizationRoleModel";
import { apiService } from "./ApiService";
import { OrganizationModel } from "../models/OrganizationModel";
import { UpdateOrganizationDto } from "../dtos/update-organization.dto";
import { AssignRoleDto } from "../dtos/assign-role.dto";
import { InviteDetails } from "../interface/invite-details";
import { CreateOrganisationDto } from "../dtos/create-organisation.dto";

class OrganizationService {
  static getInstance(): OrganizationService {
    return new OrganizationService();
  }

  async listUserOrganizations(): Promise<OrganizationRoleModel[]> {
    return await apiService
      .get<any[]>(`/profiles/roles/detailed`)
      .then((res) =>
        res
          .filter((role) => role.role !== "root")
          .map(
            (role) =>
              OrganizationRoleModel.fromJson(role) as OrganizationRoleModel
          )
      );
  }

  async listOrganizations(): Promise<OrganizationModel[]> {
    return await apiService
      .get<any[]>("/organizations")
      .then((res) =>
        res.map((org) => OrganizationModel.fromJson(org) as OrganizationModel)
      );
  }

  async updateOrganization(
    orgId: string,
    organisation: UpdateOrganizationDto
  ): Promise<OrganizationModel> {
    return apiService
      .patch(`/organizations/${orgId}`, { organisation })
      .then((res) => OrganizationModel.fromJson(res) as OrganizationModel);
  }

  async deleteOrganization(orgId: string) {
    return apiService.delete(`/organizations/${orgId}`);
  }

  async assignRole(orgId: string, data: AssignRoleDto): Promise<any> {
    return apiService.post(`/organizations/${orgId}/roles`, data);
  }

  async getInviteDetails(code: string): Promise<InviteDetails> {
    return apiService.get<InviteDetails>(`/invites/${code}`);
  }

  async createOrganization(
    organisation: CreateOrganisationDto
  ): Promise<OrganizationModel> {
    return apiService
      .post("/organizations", { organisation })
      .then((res) => OrganizationModel.fromJson(res) as OrganizationModel);
  }

  async getDashboardAnalytics(orgId: string) {
    return apiService.get(`/organizations/${orgId}/dashboard`);
  }

  async generateJoinUrl(orgId: string): Promise<{ joinUrl: string }> {
    return apiService.post<{ joinUrl: string }>(
      `/organizations/${orgId}/joinUrl`,
      null
    );
  }
}

export const organizationService = OrganizationService.getInstance();
