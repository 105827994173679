import React from "react";
import { MdOutlineHelpCenter, MdOutlineSettings } from "react-icons/md";
import { Box, Heading, Icon, Text } from "@chakra-ui/react";
import AppLayout from "../shared/app-layout/AppLayout";

interface HelpOption {
  content: string;
  subContent: JSX.Element;
  onClick: () => void;
}

const Settings: React.FC = (props) => {
  const helpOptions: HelpOption[] = [
    {
      content: "Version",
      // FIXME read this off of package.json version
      subContent: <Text fontSize="xs">2.2.4</Text>,
      onClick: () => null,
    },
    {
      content: "Terms and Conditions",
      subContent: <Icon as={MdOutlineHelpCenter} />,
      onClick: () => {
        window.open(
          "https://www.konnek.app/platform-terms-and-conditions",
          "_blank"
        );
      },
    },
    {
      content: "Privacy Notice",
      subContent: <Icon as={MdOutlineHelpCenter} />,
      onClick: () => {
        window.open("https://www.konnek.app/platform-privacy-notice", "_blank");
      },
    },
    {
      content: "Contact Us",
      subContent: <Icon as={MdOutlineHelpCenter} />,
      onClick: () => {
        window.open("https://www.konnek.app/contact-us", "_blank");
      },
    },
    {
      content: "Help and Support",
      subContent: <Icon as={MdOutlineHelpCenter} />,
      onClick: () => {
        window.open("https://www.konnek.app/resources", "_blank");
      },
    },
  ];
  const helpCard = (option: HelpOption) => (
    <Box
      key={option.content}
      borderWidth={1}
      borderRadius="6px"
      boxShadow="lg"
      cursor="pointer"
      onClick={option.onClick}
      display="flex"
      maxW="400px"
      height="50px"
      padding="0.5rem 1rem"
      my="1.2rem"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text>{option.content}</Text>
      {option.subContent}
    </Box>
  );
  return (
    <AppLayout
      colorScheme="primary"
      startLogo={<Icon fontSize="4rem" as={MdOutlineSettings} />}
      title="Settings"
      description={<>In here, you can change your KonnekApp portal settings.</>}
    >
      <Box>
        <Heading fontSize="xl">HELP</Heading>
        {helpOptions.map((option) => helpCard(option))}
      </Box>
    </AppLayout>
  );
};

export default Settings;
