import { apiService } from "./ApiService";
import { CreateVideoDto } from "../dtos/create-video.dto";
import { ContentModel } from "../models/ContentModel";
import { UploadService } from "./UploadService";

class ContentService {
  static getInstance(): ContentService {
    return new ContentService();
  }

  /**
   * Given a draft Content object, create a record of it on the back-end
   *
   * If a file is passed in, upload it
   *
   * @param orgId Organisation ID to link Content to
   * @param content Draft Content object
   * @param aFile Optional file object to upload
   * @param notifyUsers Send notifications to users
   * @returns Content record
   */
  async createContent(
    orgId: string,
    content: CreateVideoDto,
    aFile: File | null,
    notifyUsers: boolean
  ): Promise<ContentModel> {
    let file = null;
    if (aFile) {
      const { name, lastModified, size, type } = aFile;
      file = {
        name,
        lastModified,
        size,
        type,
        checksum: await UploadService.getChecksum(aFile),
      };
    }
    const res = await apiService.post<any>(`/content`, {
      content,
      orgId,
      file,
      notifyUsers,
    });
    if (file && "upload" in res && "ingest" in res.upload) {
      const { upload } = res;
      const { uid, ingest } = upload;

      /// Block until value in milliseconds passes
      const delay = (backoff: number) =>
        new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, backoff);
        });

      /// Block until Upload object is marked 'available'
      const waitForAvailable = async (
        retries = 5,
        backoff = 100,
        e?: Error
      ): Promise<void> => {
        if (retries < 0) throw e || new Error("Upload failed to be ingested");
        try {
          const uploadObj = await apiService.get<any>(`/uploads/${uid}`);
          const { status } = uploadObj;
          if (status !== "available") throw new Error("Upload not available");
          if (status === "rejected") throw new Error("Upload was rejected");
        } catch (e) {
          // Instantly propogate rejected files
          if ((e as Error).message.includes("rejected")) throw e;
          // Otherwise retry in case file was not processed yet
          await delay(backoff);
          return waitForAvailable(retries - 1, backoff * 2, e as Error);
        }
      };

      const { url } = ingest;
      await apiService.upload(url, aFile);
      await waitForAvailable();
    } else {
      console.warn("API did not return ingest object, skipping upload...");
    }
    return ContentModel.fromJson(res) as ContentModel;
  }

  /**
   * Given an existing Content record, upload a new file against it
   *
   * @param contentId Content ID to upload against
   * @param file File object to upload
   * @returns Content record
   */
  async uploadContent(contentId: string, aFile: File): Promise<ContentModel> {
    const file = {
      name: aFile.name,
      checksum: await UploadService.getChecksum(aFile),
    };
    const res = await apiService.post<any>(`/content/${contentId}`, { file });
    if ("upload" in res && "ingest" in res["upload"]) {
      const { url } = res["upload"]["ingest"];
      await apiService.upload(url, aFile);
    } else {
      console.error("API did not return ingest object");
    }
    return ContentModel.fromJson(res) as ContentModel;
  }

  getContent(contentId: string): Promise<ContentModel> {
    return apiService
      .get(`/content/${contentId}`)
      .then((res) => ContentModel.fromJson(res) as ContentModel);
  }

  listContent(orgId: string, params?: any): Promise<ContentModel[]> {
    return apiService
      .get<any[]>(`/content`, { ...params, orgId })
      .then((res) => res.map((v) => ContentModel.fromJson(v) as ContentModel));
  }

  listPlaylistContent(playlistId: string): Promise<ContentModel[]> {
    return apiService
      .get<{ items: any[] }>(`/playlists/${playlistId}/content`)
      .then((res) =>
        res["items"].map((v) => ContentModel.fromJson(v) as ContentModel)
      );
  }

  updateContent(contentId: string, content: any): Promise<ContentModel> {
    return apiService
      .patch(`/content/${contentId}`, { content })
      .then((res) => ContentModel.fromJson(res) as ContentModel);
  }

  deleteContent(orgId: string, contentId: string) {
    return apiService.delete(`/content/${contentId}`);
  }
}

export const contentService = ContentService.getInstance();
