import React, { FC, useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { XYCoord } from "dnd-core";
import { Box, Flex, VStack, Text, HStack } from "@chakra-ui/react";
import { MdMenu } from "react-icons/md";

export interface ReorderableListItemProps {
  id: any;
  children?: React.ReactElement;
  index: number;
  moveReorderableListItem: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ReorderableListItem: FC<ReorderableListItemProps> = ({
  id,
  children,
  index,
  moveReorderableListItem,
}) => {
  const ref = useRef<HTMLDivElement>(id);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveReorderableListItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box mb={8} opacity={opacity} ref={ref} data-handler-id={handlerId}>
      <Flex>
        <VStack
          cursor={"move"}
          boxShadow="0px 0px 25px rgba(0, 0, 0, 0.07)"
          justifyContent="center"
          borderRadius="6px 0 0 6px"
        >
          <HStack
            m="20px 0 20px 15px"
            justifyContent="center"
            alignItems="center"
            width="38px"
            height="38px"
            p="10px"
            backgroundColor="#f7f7f7"
            borderRadius="5px"
          >
            <Text fontSize="16px" color="#9E9E9E">
              {index + 1}
            </Text>
          </HStack>
        </VStack>
        {children}
        <VStack
          cursor={"move"}
          px={4}
          justifyContent="center"
          backgroundColor="#F7F7F7"
          borderRadius="6px"
        >
          <MdMenu size="30px" color="#9E9E9E" />
        </VStack>
      </Flex>
    </Box>
  );
};
