import React from "react";
import { Box, FormLabel, HStack, Select, Text } from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";
import KonnekAppTooltip from "../tooltip/Tooltip";

export interface PrimarySelectProps {
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  name: string;
  placeholder?: string;
  label?: string;
  info?: any;
  error?: string;
  value: string | number;
  options: any;
  displayOption: (data: any, index: number) => React.ReactNode;
}

const PrimarySelect: React.FC<PrimarySelectProps> = (
  props: PrimarySelectProps
) => {
  const {
    name,
    placeholder,
    label,
    info,
    error,
    value,
    options,
    displayOption,
    onChange,
  } = props;
  return (
    <Box w="100%" mb="10px">
      {label && (
        <HStack>
          <FormLabel
            mb="2px"
            mr="0px"
            color="gray.500"
            fontWeight="500"
            fontSize="xs"
            htmlFor={name}
          >
            {label}
          </FormLabel>
          {info && (
            <KonnekAppTooltip label={info}>
              <span>
                <MdInfoOutline />
              </span>
            </KonnekAppTooltip>
          )}
        </HStack>
      )}
      <Select
        variant="outline"
        backgroundColor="#F7F7F7"
        border="2px solid #E1E1E1"
        width="100%"
        height="48px"
        mt="4px"
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
      >
        {options.map(displayOption)}
      </Select>
      {!!error && (
        <Text fontSize="xs" color="error">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default PrimarySelect;
