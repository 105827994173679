import { Dictionary } from "../models/Model";
import { UserLike } from "../models/UserModel";
import parsePhoneNumber from "libphonenumber-js";

export class CommonUtils {
  static relativeTime = (ms?: number): string => {
    const msPerSecond = 1000;
    const msPerMinute = msPerSecond * 60;
    const msPerHour = msPerMinute * 60;
    if (!ms) return "No data";

    // Handle second values
    if (ms < msPerMinute) {
      const seconds = Math.round(ms / msPerSecond);
      return `${seconds} seconds`;
    }

    // Handle minute values
    if (ms < msPerHour) {
      const minutes = Math.round(ms / msPerMinute);
      return `${minutes} minutes`;
    }

    // Handle minute values

    const hours = Math.round(ms / msPerHour);
    return `${hours} hours`;
  };

  static getQueryString(map: Dictionary<string>): string {
    let queryString = "?";
    Object.keys(map).forEach((key) => {
      queryString += `${key}=${map[key]}&`;
    });
    return queryString.slice(0, queryString.length - 1);
  }

  static formatFileSize(n: number) {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let l = 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  static parseQueryString(query: string): Dictionary<string> {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split("&")
          .reduce((params: Dictionary<string>, param: string) => {
            const [key, value] = param.split("=");
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, " "))
              : "";
            return params;
          }, {})
      : {};
  }

  static validateYoutubeUrl(url: string): boolean {
    const regex =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return regex.test(url);
  }

  static iterateEnum(iteratee: any) {
    return Object.keys(iteratee).map((key) => iteratee[key]);
  }

  static validateEmail(email: string): boolean {
    const regex = /([\w.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/gim;
    return regex.test(email);
  }

  static validateUrl(url: string): boolean {
    const regex =
      /^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/gm;
    return regex.test(url);
  }

  static getUserDisplayDetail(user: UserLike): string {
    return (
      user.displayName ||
      user.firstName + user.lastName ||
      user.email ||
      "Anonymous User"
    );
  }

  static msToMin(ms: number | undefined): number {
    if (!ms) {
      return 0;
    } else {
      return Math.round(ms / (1000 * 60));
    }
  }

  static getCountryFromPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
      return "-";
    }
    const number = parsePhoneNumber(phoneNumber.toString());
    if (number) {
      return number?.country;
    } else {
      return "-";
    }
  }

  static getJobTitles(): string[] {
    return [
      "CEO (Chief Executive Officer)",
      "COO (Chief Operating Officer)",
      "CFO (Chief Financial Officer)",
      "CIO (Chief Information Officer)",
      "CTO (Chief Technology Officer)",
      "CMO (Chief Marketing Officer)",
      "CHRO (Chief Human Resources Officer)",
      "CDO (Chief Data Officer)",
      "CPO (Chief Product Officer)",
      "CCO (Chief Customer Officer)",
      "Accountant",
      "Administrator",
      "Advisor",
      "Analyst",
      "Architect",
      "Assistant",
      "Associate",
      "Auditor",
      "Consultant",
      "Coordinator",
      "Copy Writer",
      "Developer",
      "Director",
      "Editor",
      "Engineer",
      "Executive",
      "Expert",
      "Head of",
      "Inspector",
      "Intern",
      "Lawyer",
      "Lead",
      "Manager",
      "Member",
      "Not Applicable",
      "Officer",
      "Operator",
      "Partner",
      "Planner",
      "Producer",
      "Representative",
      "Scientist",
      "Specialist",
      "Student",
      "Support",
      "Team Leader",
      "Technician",
      "Temporary Worker",
      "Tester",
      "Writer",
      "Other",
      "Tutor",
      "Apprentice",
      "Agent",
      "Controller",
      "Investigator",
      "Personal Assistant",
      "Receptionist",
    ];
  }

  static getBusinessRoles(): string[] {
    return [
      "Accounting",
      "Advertising",
      "Analytics",
      "Asset",
      "Board",
      "Brand",
      "Business Development",
      "Content Production",
      "Customer Service",
      "Data",
      "Design",
      "Digital",
      "Distribution",
      "Diversity and Inclusion",
      "E-Commerce",
      "Energy",
      "Environment",
      "Events",
      "Finance",
      "Governance",
      "Hardware",
      "Health and Safety",
      "HR",
      "Information Security",
      "Information Technology",
      "Internal Auditing",
      "Legal",
      "Logistics",
      "Management",
      "Marketing",
      "Not Applicable",
      "Operations",
      "Other",
      "Procurement",
      "Product Development",
      "Production",
      "Project",
      "Public Relations",
      "Quality",
      "Regulatory",
      "Research",
      "Risk",
      "Sales",
      "Social Media",
      "Software",
      "Sourcing",
      "Strategic",
      "Sustainability",
      "Technical",
      "Video",
      "Website",
      "User Experience",
      "User Interface",
    ];
  }

  static pastDate = (days: number, months = 0) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    date.setMonth(date.getMonth() - months);

    return date;
  };

  static ranges = [
    { label: "Last 7 days", start: CommonUtils.pastDate(7) },
    { label: "Last 30 days", start: CommonUtils.pastDate(30) },
    { label: "Last 3 months", start: CommonUtils.pastDate(0, 3) },
    { label: "Last 12 months", start: CommonUtils.pastDate(0, 12) },
  ];

  static defaultRange = 0;
}
