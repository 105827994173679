import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { PlaylistModel } from "../../models/PlaylistModel";
import { z } from "zod";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import useSnackBar from "../../hooks/useSnackBar";
import { contentService } from "../../services/ContentService";
import { uploadService } from "../../services/UploadService";
import TextField from "../../shared/textfield/TextField";
import { ContentSchema, CreateVideoDto } from "../../dtos/create-video.dto";
import { ContentSourceType } from "../../enums/content-source-type";
import { playlistService } from "../../services/PlaylistService";
import { useDropzone } from "react-dropzone";
import TextAreaField from "../../shared/textareafield/TextAreaField";
import PrimarySelect from "../../shared/primary-select/PrimarySelect";
import { MdCloudUpload, MdDelete, MdInfo } from "react-icons/md";
import { Privacy } from "../../enums/privacy";
import DeleteConfirmationDialog from "../../shared/delete-confirmation-dialog/DeleteConfirmationDialog";
import DeleteButton from "../../shared/delete-button/DeleteButton";

interface EditContentDialogProps extends GlobalProps {
  uid: string;
  title: string;
  description: string;
  type: ContentSourceType;
  previewUrl?: string;
  url?: string;
  privacy: Privacy;

  playlist?: PlaylistModel;

  isOpen: boolean;
  onClose: () => void;
}

const EditContentDialog: React.FC<EditContentDialogProps> = (props) => {
  const notify = useSnackBar();

  const playlistStore = props.store?.playlistStore;
  const contentStore = props.store?.contentStore;
  const [isPrivate, setIsPrivate] = useState(props.privacy === "private");

  const deleteContentConfirmationDialogController = useDisclosure();

  const _deleteContent = async () => {
    try {
      if (props.playlist) {
        const itemsWithoutMe = props.playlist.items.filter(
          (x) => x !== props.uid
        );
        await playlistStore?.updatePlaylist(String(props.playlist.id), {
          items: itemsWithoutMe,
        });
      }

      await contentStore?.deleteVideo(props.uid);
      notify("success", "Deleted content.");
    } catch (e) {
      notify(
        "error",
        "Failed to delete content. Make sure it has been removed from all playlists."
      );
    }
    props.onClose();
  };

  useEffect(() => {
    playlistStore?.listPlaylists().catch((err) => {
      notify(
        "error",
        "There was a problem fetching your playlists. Try refreshing the page."
      );
    });
    // eslint-disable-next-line
  }, []);

  const [thumbnail, setThumbnail] = useState<File | undefined>();
  const onDrop = React.useCallback((files: File[]) => {
    setThumbnail(files[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const formik = useFormik({
    initialValues: {
      title: props.title || "",
      description: props.description || "",
      // Only send url to back-end if it's an external integration
      // Native content updates happen based on upload finalised events
      url: props.type === ContentSourceType.INTEGRATION ? props.url : "",
      previewUrl: props.previewUrl || "",
      playlistId: props.playlist ? props.playlist.uid : "",
      thumbnail: null,
      privacy: props.privacy,
      stats: {},
    },
    validate: (values) => {
      const result = ContentSchema.safeParse(values);
      if (!result.success) {
        const errors: FormikErrors<z.infer<typeof ContentSchema>> = {};

        for (const issue of result.error.issues) {
          errors[issue.path[0] as keyof typeof errors] = issue.message;
        }

        return errors;
      }
    },
    onSubmit: async (values) => {
      try {
        const content: CreateVideoDto = {
          title: values.title,
          description: values.description,
          url: values.url || null,
          privacy: isPrivate ? Privacy.PRIVATE : Privacy.PUBLIC,
          previewUrl: props.previewUrl || null,
        };

        if (thumbnail) {
          content.previewUrl = await uploadService.uploadFile(
            thumbnail,
            props.store?.organizationRoleStore!.selectedOrganizationId
          );
        }

        const res = await contentService.updateContent(props.uid, content);

        if (values.playlistId === "none") {
          if (!props.playlist) {
            console.warn("Cannot remove from playlist, need to query API");
          }
          const { uid: playlistId, items } = props.playlist!;

          // Update playlist, remove current contentId
          await playlistService.updatePlaylist(playlistId, {
            items: items.filter((val) => !val.includes(props.uid)),
          });
        } else if (props.playlist && values.playlistId !== props.playlist.uid) {
          // Add the content to the new playlist
          // FIXME: currently removes all other items from the playlist with id values.playlistId.
          await playlistService.updatePlaylist(values.playlistId, {
            items: [res.uid],
          });

          // Remove content from its original playlist
          await playlistService.updatePlaylist(props.playlist.uid, {
            items: props.playlist!.items.filter(
              (val) => !val.includes(props.uid)
            ),
          });
        }

        notify("success", "Edited content.");
        props.onClose();
      } catch (e) {
        notify(
          "error",
          "Problem adding content to playlist. Please try again."
        );
      }
    },
  });

  // Shorthand for repeated attributes in fields
  function _fields<Key extends keyof typeof formik.values>(name: Key) {
    return {
      id: name,
      name: name,
      value: formik.values[name],
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      isInvalid: formik.touched[name] && Boolean(formik.errors[name]),
    };
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="3xl">
      <ModalOverlay />

      <ModalContent p="40px" borderRadius="21px">
        <ModalCloseButton />
        <Heading fontSize="2xl" my="20px">
          Edit content
        </Heading>

        <ModalBody>
          <HStack spacing={6} alignItems="flex-start">
            <Box>
              {props.type === ContentSourceType.INTEGRATION && (
                <TextField
                  type="text"
                  {..._fields("url")}
                  value={formik.values.url || ""}
                  error={formik.errors.url}
                  label="Youtube/Vimeo Url"
                />
              )}
              <TextField
                type="text"
                {..._fields("title")}
                value={formik.values.title}
                error={formik.errors.title}
                label="Title"
              />
              <TextAreaField
                {..._fields("description")}
                value={formik.values.description}
                label="Description"
              />
              <PrimarySelect
                {..._fields("playlistId")}
                label="Select playlist"
                value={formik.values.playlistId || ""}
                options={playlistStore?.organizationPlaylists}
                displayOption={(playlist) => (
                  <option key={playlist.id} value={playlist.id}>
                    {playlist.title} ({playlist.items.length} items)
                  </option>
                )}
              />
            </Box>
            <Box>
              <FormLabel color="gray.500" fontWeight="600" fontSize="xs">
                Thumbnail
              </FormLabel>
              {!thumbnail ? (
                <Flex
                  width="320px"
                  height="180px"
                  backgroundColor="#F7F7F7"
                  borderRadius="9px"
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="xs"
                  color="gray"
                  {...getRootProps()}
                >
                  <MdCloudUpload size="38px" color="gray" />
                  <Text my="4px">Drag and drop a file here to upload</Text>
                  <input {...getInputProps()} />
                </Flex>
              ) : (
                <Image
                  width="320px"
                  height="180px"
                  borderRadius="lg"
                  src={URL.createObjectURL(thumbnail)}
                />
              )}

              <HStack mt="6px">
                <MdInfo color="gray" />
                <Text fontWeight="400" fontSize="0.7rem" color="gray">
                  Recommended size 800x400
                </Text>
              </HStack>
              <HStack mt="12px">
                <Switch
                  isChecked={formik.values.privacy === "private"}
                  onChange={(event) => setIsPrivate(event.target.checked)}
                  colorScheme="primary"
                />
                <Text color="brand" fontSize="xs">
                  Hide content from public users view
                </Text>
              </HStack>
            </Box>
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <DeleteButton
            onClick={deleteContentConfirmationDialogController.onOpen}
          >
            Delete content
          </DeleteButton>
          <Button
            colorScheme="secondary"
            fontSize="12px"
            isLoading={formik.isSubmitting}
            onClick={formik.submitForm}
          >
            Save
          </Button>
        </ModalFooter>
        {playlistStore && (
          <>
            <DeleteConfirmationDialog
              title={props.title}
              onDelete={_deleteContent}
              onClose={deleteContentConfirmationDialogController.onClose}
              isOpen={deleteContentConfirmationDialogController.isOpen}
            />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default inject("store")(observer(EditContentDialog));
