import React, { useState } from "react";
import AuthLayout from "../../AuthLayout";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import TextField from "../../../shared/textfield/TextField";

interface ForgetPasswordPageProps {
  onSubmit: (email: string) => void;
}

const ForgetPasswordPage: React.FC<ForgetPasswordPageProps> = (
  props: ForgetPasswordPageProps
) => {
  const { onSubmit } = props;
  const [email, setEmail] = useState<string>("");
  return (
    <AuthLayout>
      <Heading size="2xl">Forget Password!</Heading>
      <Text mt="10px" fontWeight="light">
        Please enter your email to continue.
      </Text>
      <Box display="flex" flexDir="column" mt="22px">
        <TextField
          value={email}
          placeholder="Email"
          name="email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          alignSelf="flex-end"
          colorScheme="primary"
          color="white"
          onClick={() => onSubmit(email)}
        >
          Send reset link
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default ForgetPasswordPage;
