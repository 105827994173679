import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CommonUtils } from "../utils/CommonUtils";
import { InviteDetails, InviteStatus } from "../interface/invite-details";
import { organizationService } from "../services/OrganizationService";
import { GlobalProps } from "../App";
import { inject } from "mobx-react";
import useLogout from "../hooks/useLogout";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Spinner,
} from "@chakra-ui/react";
import {
  MdOutlineCheck,
  MdClear,
  MdKeyboardBackspace,
  MdArrowForward,
} from "react-icons/md";

const Invite: React.FC<GlobalProps> = (props: GlobalProps) => {
  const authStore = props.store?.authStore;
  const location = useLocation();
  const history = useHistory();

  const [isInvalid, setIsInvalid] = useState(false);
  const [isInviteSuccess, setIsInviteSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [inviteDetails, setInviteDetails] = useState<InviteDetails>();
  const logout = useLogout();

  const logoutAndStoreRedirectUrl = async () => {
    props.store!.redirectUrl =
      history.location.pathname + history.location.search;
    logout!();
  };

  const checkInviteStatus = async (
    userEmail: string,
    code: string,
    retries = 6,
    delay = 100
  ) => {
    const res = await organizationService.getInviteDetails(code);
    if (res.userEmail !== userEmail) {
      setIsInvalid(true);
      return;
    }
    if (!inviteDetails) {
      setInviteDetails(res);
    }
    if (res.status === InviteStatus.ACTIVE) {
      setIsInviteSuccess(true);
      return;
    }
    if (retries > 0 && res.status === InviteStatus.PENDING) {
      setTimeout(() => {
        checkInviteStatus(userEmail, code, retries - 1, delay * 2);
      }, delay);
    } else {
      setIsInvalid(true);
    }
  };

  const validateInvite = async () => {
    const queryParams = CommonUtils.parseQueryString(location.search);
    if (!queryParams.code) {
      setIsInvalid(true);
      return;
    }
    try {
      setIsLoading(true);
      await checkInviteStatus(
        authStore?.loggedInUser?.email!,
        queryParams.code
      );
      setIsLoading(false);
    } catch (error) {
      setIsInvalid(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validateInvite().then();
    // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <Spinner
      thickness="3px"
      color="brand"
      size="md"
      pos="absolute"
      top="50%"
      left="50%"
      transform="translateY(-50%, -50%)"
    />
  ) : (
    <Box height="100vh" padding="2rem" position="relative">
      <Image mb="30px" src="/assets/icons/konnek-brand-black.png" alt="Brand" />
      {isInvalid ? (
        <HStack
          color="white"
          borderRadius="lg"
          width="fit-content"
          spacing="3"
          backgroundColor="black"
          padding="1rem 2rem"
        >
          <Icon as={MdClear} />
          <Heading>Invitation rejected!</Heading>
        </HStack>
      ) : (
        <HStack
          color="white"
          borderRadius="lg"
          width="fit-content"
          spacing={3}
          backgroundColor="brand"
          padding="1rem 2rem"
        >
          <Icon as={MdOutlineCheck} />
          <Heading>Invitation accepted!</Heading>
        </HStack>
      )}
      <Box mt="22px" color="gray">
        {isInvalid ? (
          <>
            Your invitation for the account with{" "}
            <Heading fontSize="lg" display="inline">
              {inviteDetails?.userEmail}
            </Heading>{" "}
            is{" "}
            <Heading fontSize="lg" display="inline">
              not valid.
            </Heading>
            .
            <br />
            Please request a new invite link for this account, or sign in with a
            different account.
          </>
        ) : (
          <>
            Your invitation for the account with{" "}
            <Heading fontSize="lg" display="inline">
              {inviteDetails?.userEmail}
            </Heading>
            , has been accepted.
            <br />
            Please wait while we add you to the organization, it takes usually
            takes upto 10 seconds.
          </>
        )}
      </Box>
      <Box mt="22px">
        {isInvalid ? (
          <Button
            onClick={logoutAndStoreRedirectUrl}
            leftIcon={<MdKeyboardBackspace />}
          >
            Logout
          </Button>
        ) : (
          <>
            {isInviteSuccess ? (
              <Button
                onClick={() => {
                  history.push(`/org/${inviteDetails?.orgId}/home`);
                }}
                rightIcon={<MdArrowForward />}
                colorScheme="primary"
              >
                To dashboard
              </Button>
            ) : (
              <Spinner size="sm" />
            )}
          </>
        )}
      </Box>
      <Image
        position="absolute"
        right="20px"
        bottom="20px"
        src="/assets/images/invite.svg"
        alt="Invite"
      />
    </Box>
  );
};

export default inject("store")(Invite);
