import * as Axios from "axios";
import { firebaseService } from "./FirebaseService";

export interface RequestOptions {
  useAuthHeaders?: boolean;
  useBaseUrl?: boolean;
  headers?: any;
  axiosParams?: any;
  queryParams?: {
    [key: string]: string;
  };
}

export class HttpError extends Error {
  constructor(public message: string, private status: number) {
    super(message);
  }
}

export const AUTH_TOKEN = "auth_token";

export class ApiService {
  protected BASE_URL: string;

  constructor() {
    this.BASE_URL = process.env.REACT_APP_BACKEND_URL!;
  }

  static getInstance(): ApiService {
    return new ApiService();
  }

  async get<T>(url: string, params?: any, config?: RequestOptions): Promise<T> {
    return Axios.default
      .get(this._getUrl(url, config), {
        ...config?.axiosParams,
        params: params || null,
        headers: await this._getHeaders(config),
      })
      .then((response) => response.data)
      .catch(this._handleErrors.bind(this));
  }

  async post<T>(url: string, body: any, config?: RequestOptions): Promise<T> {
    return Axios.default
      .post(this._getUrl(url, config), body, {
        headers: await this._getHeaders(config),
      })
      .then((response) => response.data)
      .catch(this._handleErrors.bind(this));
  }

  async put<T>(url: string, body: any, config?: RequestOptions): Promise<T> {
    return Axios.default
      .put(this._getUrl(url, config), body, {
        headers: await this._getHeaders(config),
      })
      .then((response) => response.data)
      .catch(this._handleErrors.bind(this));
  }

  async patch<T>(url: string, body: any, config?: RequestOptions): Promise<T> {
    return Axios.default
      .patch(this._getUrl(url, config), body, {
        headers: await this._getHeaders(config),
      })
      .then((response) => response.data)
      .catch(this._handleErrors.bind(this));
  }

  async delete<T>(
    url: string,
    body?: any,
    config?: RequestOptions
  ): Promise<T> {
    return Axios.default
      .delete(this._getUrl(url, config), {
        headers: await this._getHeaders(config),
        data: body,
      })
      .then((response) => response.data)
      .catch(this._handleErrors.bind(this));
  }

  async upload<T>(url: string, file: any, config?: RequestOptions): Promise<T> {
    return Axios.default
      .put(url, file, {
        headers: { "Content-Type": `${file.type}` },
      })
      .then((response) => {
        return response.data;
      })
      .catch(this._handleErrors.bind(this));
  }

  async retryUntilSuccess<T>(
    fn: () => Promise<T>,
    retryStatusCodes: number[],
    retries = 5,
    delay = 100
  ): Promise<any> {
    try {
      return await fn();
    } catch (error) {
      if (retries > 0 && retryStatusCodes.includes((error as any).status)) {
        return setTimeout(() => {
          return this.retryUntilSuccess(
            fn,
            retryStatusCodes,
            retries - 1,
            delay * 2
          );
        }, delay);
      } else {
        throw error;
      }
    }
  }

  private _getUrl(relativePath: string, config?: RequestOptions) {
    if (config && config.useBaseUrl === false) {
      return relativePath;
    }

    const url = new URL(this.BASE_URL + relativePath);
    if (config && config.queryParams) {
      for (const [key, value] of Object.entries(config.queryParams)) {
        url.searchParams.append(key, value);
      }
    }

    return url.toString();
  }

  private async _getHeaders(config?: RequestOptions) {
    if (config && config.useAuthHeaders === false) {
      return config.headers;
    }
    const token = await firebaseService.fetchToken();
    return {
      ...(config || {}).headers,
      Authorization: `Bearer ${token}`,
    };
  }

  private _handleErrors(error: any) {
    const genericErrorMessage = "Something went wrong. Try again later!";
    const message = error.response?.data?.message || genericErrorMessage;
    const status = error.response?.status || 500;
    throw new HttpError(message, status);
  }
}

export const apiService = ApiService.getInstance();
