/**
 * A base model abstract class which is responsible
 * for serialization and deserialization of models.
 */

import { action } from "mobx";
import Store, { EntityIdentifier } from "../stores/Store";
import { capitalize } from "lodash";

export type Dictionary<T = any> = { [key: string]: T };

export abstract class Model {
  constructor(readonly id: EntityIdentifier) {}

  /**
   * Fetches the store which is linked to the model. If a store is not found,
   * it throws an error on the console.
   */
  static getStore(): Store<Model> {
    const store = (this as any)._store;
    if (!store) {
      console.error(`_store not defined in ${this}
            Please define _store and assign 'this' to it in parent store's constructor`);
    }
    return store;
  }

  /**
   * Returns a model object from the JSON data.
   * @param json Data coming from backend or any other data source in form of JSON.
   * @param identifierKey An unique identifier which can be used to distinguish between the models.
   */
  @action
  static fromJson(json: any, identifierKey = "uid"): Model {
    const id = json[identifierKey] as EntityIdentifier;

    let entity = this.getStore().get(id);
    if (entity) {
      entity.updateFromJson(json);
      this.getStore().setIsEntityAdding(false);
    } else {
      entity = new (this as any)(id);
      entity!.updateFromJson(json);
      this.getStore().push(entity!);
    }
    this.getStore().setIsEntityAdding(false);
    return entity!;
  }

  /**
   * Returns an entity from the store based on the identifier. If the entity is not present,
   * it creates it on the go.
   * @param id Unique identifier by which, entities can be distinguished.
   */
  public static getOrNew(id: EntityIdentifier): Model {
    let entity = this.getStore().get(id);

    if (!entity) {
      entity = new (this as any)(id);
      this.getStore().push(entity!);
    }

    return entity!;
  }

  public static get(id: EntityIdentifier) {
    return this.getStore().get(id);
  }

  /**
   * Converts a class based model back to JSON.
   */
  toJson(): Dictionary {
    return Object.keys(this).reduce(
      (prev, next) => ({
        ...prev,
        [next]: (this as any)[next],
      }),
      {}
    );
  }

  // Abstract method for retrieving the unique identifier key from every model.
  abstract getId(): EntityIdentifier;

  /**
   * Responsible for deserialization of the JSON data.
   * @param json JSON data that needs to be converted into class based model instance.
   */
  @action
  updateFromJson(json: Dictionary<any>) {
    for (const k in json) {
      if (json.hasOwnProperty(k)) {
        const deserializer = this.getDeserializer(k);
        if (deserializer) {
          json[k] && deserializer.bind(this)(json[k]);
        } else {
          (this as any)[k] = json[k];
        }
      }
    }
  }

  /**
   * Retrieves the deserializer function for nested objects
   * @param prop Nested object key
   */
  private getDeserializer(prop: string) {
    return (this as any)[`deserialize${capitalize(prop)}`];
  }
}

export default Model;
