import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import TextField from "../../../shared/textfield/TextField";
import AuthLayout from "../../AuthLayout";
import SocialButton from "../SocialButton/SocialButton";
import { CommonUtils } from "../../../utils/CommonUtils";
import MagicLinkDialog from "../MagicLinkDialog/MagicLinkDialog";
import ConfirmPasswordDialog from "../ConfirmPasswordDialog/ConfirmPasswordDialog";
import { Link as ReactRouterLink } from "react-router-dom";
import useSnackBar from "../../../hooks/useSnackBar";

interface SignupPageProps {
  signUpWithEmailAndPassword: (email: string, password: string) => void;
  signUpWithGoogle: () => void;
  signUpWithApple: () => void;
  sendMagicLink: (email: string) => void;
}

const SignupPage: React.FC<SignupPageProps> = (props: SignupPageProps) => {
  const {
    signUpWithGoogle,
    signUpWithApple,
    signUpWithEmailAndPassword,
    sendMagicLink,
  } = props;
  const {
    isOpen: isMagicLinkDialogOpen,
    onOpen: onMagicLinkDialogOpen,
    onClose: onMagicLinkDialogClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmPasswordDialogOpen,
    onOpen: onConfirmPasswordDialogOpen,
    onClose: onConfirmPasswordDialogClose,
  } = useDisclosure();
  const notify = useSnackBar();
  const [email, setEmail] = useState<string>("");
  const signUp = async () => {
    const isValid = CommonUtils.validateEmail(email);
    if (!isValid) return;
    await sendMagicLink(email);
    onMagicLinkDialogOpen();
  };
  const signupManually = (password: string) => {
    signUpWithEmailAndPassword(email, password);
    onConfirmPasswordDialogClose();
  };
  const showPasswordDialog = () => {
    onMagicLinkDialogClose();
    onConfirmPasswordDialogOpen();
  };
  return (
    <>
      <AuthLayout>
        <Heading size="3xl">Hi there!</Heading>
        <Text mt="10px" fontWeight="light">
          Please enter your email to continue.
        </Text>
        <Box display="flex" flexDir="column" mt="22px">
          <TextField
            value={email}
            placeholder="Email"
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={signUp}
            alignSelf="flex-end"
            colorScheme="primary"
            color="white"
          >
            Sign up
          </Button>
        </Box>
        <Box my="22px" display="flex" alignItems="center">
          <Divider />
          <Text color="gray.400" mx="8px">
            or
          </Text>
          <Divider />
        </Box>
        <Box px={{ lg: "5rem" }}>
          <SocialButton
            text="Continue with Google"
            imageSrc="/assets/icons/google-icon.png"
            onClick={signUpWithGoogle}
          />
          <SocialButton
            text="Continue with Apple"
            imageSrc="/assets/icons/apple-icon.png"
            onClick={signUpWithApple}
          />
        </Box>
        <Center>
          <Text>
            Already have an account?
            <Link as={ReactRouterLink} to="/login" color="brand" ml="4px">
              Sign in here
            </Link>
          </Text>
        </Center>
      </AuthLayout>
      <MagicLinkDialog
        resendMagicLink={() => {
          sendMagicLink(email);
          notify("success", "Magic link sent successfully.");
        }}
        onSignInManually={showPasswordDialog}
        isOpen={isMagicLinkDialogOpen}
        onClose={onMagicLinkDialogClose}
      />
      <ConfirmPasswordDialog
        isOpen={isConfirmPasswordDialogOpen}
        onClose={onConfirmPasswordDialogClose}
        onSubmit={signupManually}
      />
    </>
  );
};

export default SignupPage;
