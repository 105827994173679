import React from "react";
import { Heading, Image, Stack, Text } from "@chakra-ui/react";

const PageNotFound: React.FC = () => (
  <Stack
    spacing={4}
    direction="column"
    justifyContent="center"
    alignItems="center"
  >
    <Image src="/assets/icons/error-404.png" alt="error-404" />
    <Heading fontSize="6xl">404</Heading>
    <Heading>Oops! Page Not Found</Heading>
    <Text width="50%" textAlign="center">
      We're sorry, but we can't find the page you are looking for. Please make
      sure you have typed in the correct URL or contact our support team
    </Text>
  </Stack>
);

export default PageNotFound;
