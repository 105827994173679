import React from "react";
import { inject, observer } from "mobx-react";
import { Formik, FormikErrors } from "formik";
import { z } from "zod";
import { GlobalProps } from "../App";
import useSnackBar from "../hooks/useSnackBar";
import { UpdateUserDto } from "../dtos/update-user.dto";
import { CommonUtils } from "../utils/CommonUtils";
import { HttpError } from "../services/ApiService";
import { Box, Button, Icon, Spinner, Stack } from "@chakra-ui/react";
import TextField from "../shared/textfield/TextField";
import PrimarySelect from "../shared/primary-select/PrimarySelect";
import Avatar from "../shared/avatar/Avatar";
import { uploadService } from "../services/UploadService";
import { MdPerson } from "react-icons/md";
import AppLayout from "../shared/app-layout/AppLayout";

const UserProfile: React.FC<GlobalProps> = (props: GlobalProps) => {
  const authStore = props.store?.authStore;
  const user = authStore?.loggedInUser;
  const [previewFileUrl, setPreviewFileUrl] = React.useState(user?.photoURL);
  const [avatarLoading, setAvatarLoading] = React.useState(false);
  const notify = useSnackBar();

  const uploadImage = async (file: File) => {
    try {
      setAvatarLoading(true);
      const imageUrl = await uploadService.uploadFile(file);
      setPreviewFileUrl(imageUrl);
    } catch (error) {
      notify(
        "error",
        (error as Error).message ? (error as Error).message : (error as string)
      );
    } finally {
      setAvatarLoading(false);
    }
  };

  return (
    <AppLayout
      colorScheme="primary"
      startLogo={<Icon fontSize="4rem" as={MdPerson} />}
      title={`${!!user?.firstName ? user.firstName + "'s" : "Your"} Profile`}
      description={
        <>
          Manage your KonnekApp profile information including your photo,
          contact details and more. <br />
          You can update your contact and employment information to reflect any
          changes instantly.
        </>
      }
    >
      <Stack spacing="54px" direction={{ base: "column", md: "row" }}>
        <Avatar
          onChange={uploadImage}
          avatarUrl={previewFileUrl || user?.photoURL}
          loading={avatarLoading}
        />
        <Box w={{ base: "90%", md: "46%" }}>
          <Formik
            initialValues={{
              firstName: user?.firstName || "",
              lastName: user?.lastName || "",
              company: user?.company || "",
              phoneNumber: user?.phoneNumber || "",
              jobTitle: user?.jobTitle || "",
              businessArea: user?.businessArea || "",
            }}
            validate={(values) => {
              const ProfileSchema = z.object({
                firstName: z
                  .string()
                  .min(1, { message: "First name is required " }),
                lastName: z
                  .string()
                  .min(1, { message: "Last name is required " }),
                company: z
                  .string()
                  .min(1, { message: "Company name is required " }),
                phoneNumber: z
                  .string()
                  .min(1, { message: "Phone number is required " }),
                jobTitle: z.string().optional(),
                businessArea: z.string().optional(),
              });

              const result = ProfileSchema.safeParse(values);

              if (!result.success) {
                const errors: FormikErrors<z.infer<typeof ProfileSchema>> = {};
                result.error.issues.forEach((issue) => {
                  errors[issue.path[0] as keyof typeof errors] = issue.message;
                });
                return errors;
              }
            }}
            onSubmit={async (values, actions) => {
              try {
                let dto: UpdateUserDto = {
                  ...values,
                  displayName: values.firstName + " " + values.lastName,
                };
                if (!!previewFileUrl && previewFileUrl !== user?.photoURL) {
                  dto = {
                    ...dto,
                    photoURL: previewFileUrl,
                  };
                }
                await authStore?.updateLoggedInUser(dto);
                actions.setSubmitting(false);
                notify("success", "Profile updated successfully");
              } catch (error) {
                actions.setSubmitting(false);
                notify("error", (error as HttpError).message);
              }
            }}
          >
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <TextField
                  type="text"
                  name="firstName"
                  label="First name"
                  onChange={formProps.handleChange}
                  placeholder="First name"
                  value={formProps.values.firstName}
                  error={
                    formProps.errors.firstName && formProps.touched.firstName
                      ? formProps.errors.firstName
                      : undefined
                  }
                />
                <TextField
                  type="text"
                  name="lastName"
                  label="Last name"
                  onChange={formProps.handleChange}
                  placeholder="Last name"
                  value={formProps.values.lastName}
                  error={
                    formProps.errors.lastName && formProps.touched.lastName
                      ? formProps.errors.lastName
                      : undefined
                  }
                />
                <TextField
                  type="text"
                  name="company"
                  label="Company"
                  onChange={formProps.handleChange}
                  placeholder="Company"
                  value={formProps.values.company}
                  error={
                    formProps.errors.company && formProps.touched.company
                      ? formProps.errors.company
                      : undefined
                  }
                />
                <PrimarySelect
                  name="jobTitle"
                  placeholder="Job title"
                  label="Job title"
                  onChange={formProps.handleChange}
                  value={formProps.values.jobTitle}
                  options={CommonUtils.getJobTitles()}
                  displayOption={(job, index) => (
                    <option key={job} value={job}>
                      {job}
                    </option>
                  )}
                />
                <PrimarySelect
                  name="businessArea"
                  placeholder="Business area"
                  label="Business area"
                  onChange={formProps.handleChange}
                  value={formProps.values.businessArea}
                  options={CommonUtils.getBusinessRoles()}
                  displayOption={(role, index) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  )}
                />
                <TextField
                  type="text"
                  name="phoneNumber"
                  label="Phone number"
                  onChange={formProps.handleChange}
                  placeholder="Phone number"
                  value={formProps.values.phoneNumber}
                  error={
                    formProps.errors.phoneNumber &&
                    formProps.touched.phoneNumber
                      ? formProps.errors.phoneNumber
                      : undefined
                  }
                />
                <Box>
                  {formProps.isSubmitting ? (
                    <Spinner color="brand" />
                  ) : (
                    <Button
                      colorScheme="primary"
                      disabled={formProps.isSubmitting}
                      type={"submit"}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Stack>
    </AppLayout>
  );
};

export default inject("store")(observer(UserProfile));
