import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";

export interface IntroScreenProps {
  primaryColor?: string;
  secondaryColor?: string;
  textColor?: string;
  logoUrl?: string;
}

const IntroScreen: React.FC<IntroScreenProps> = (props: IntroScreenProps) => {
  return (
    <Box position="relative" width="260px" height="530px">
      <Image
        position="absolute"
        top={0}
        left={0}
        width="260px"
        height="530px"
        src="/assets/images/mobile_outline.png"
      />
      <Box px="30px" height="100%" position="absolute">
        <Box
          backgroundColor={props.primaryColor}
          color={props.textColor}
          borderRadius="lg"
          height="350px"
          mt="54px"
          pt="30px"
        >
          <Center>
            <Image src={props.logoUrl} />
          </Center>

          <Heading textAlign="center" fontSize="sm" mt="20px">
            It's all about Playlists
          </Heading>
          <Text textAlign="center" fontSize="xs" mt="20px">
            {" "}
            Find all your favorite videos from us in one place... list!!
          </Text>
        </Box>
        <Flex pos="absolute" bottom="30px" width="200px">
          <Button size="sm" fontSize="xs">
            DON'T ALLOW
          </Button>
          <Spacer />
          <Button
            backgroundColor={props.secondaryColor}
            size="sm"
            fontSize="xs"
          >
            ALLOW
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default IntroScreen;
