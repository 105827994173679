import React, { PropsWithChildren } from "react";
import { Tooltip } from "@chakra-ui/react";

export interface KonnekAppTooltipProps {
  label: any;
}

const KonnekAppTooltip: React.FC<PropsWithChildren<KonnekAppTooltipProps>> = (
  props: PropsWithChildren<KonnekAppTooltipProps>
) => {
  return (
    <Tooltip
      label={props.label}
      fontSize="xs"
      hasArrow
      bg="secondary.500"
      color="white"
      placement="top"
    >
      {props.children}
    </Tooltip>
  );
};

export default KonnekAppTooltip;
