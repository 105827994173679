import React, { useEffect, useState } from "react";
import { GlobalProps } from "../App";
import {
  Box,
  Button,
  Grid,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdSupervisorAccount } from "react-icons/md";
import useSnackBar from "../hooks/useSnackBar";
import { CommonUtils } from "../utils/CommonUtils";
import { UserRole } from "../enums/user-roles";
import { UserModel } from "../models/UserModel";
import { HttpError } from "../services/ApiService";
import SingleUser from "./single-user/SingleUser";
import { inject, observer } from "mobx-react";
import SearchBar from "../shared/search-bar/Searchbar";
import UserDialog from "./user-dialog/UserDialog";
import InviteUserDialog from "./invite-user-dialog/InviteUserDialog";
import { CSVDownload } from "react-csv";
import AppLayout from "../shared/app-layout/AppLayout";

const exportHeaders = [
  { label: "User type", key: "type" },
  { label: "Time joined (if staff)", key: "roleDate" },
  { label: "Staff role", key: "roleName" },
  { label: "Time (consent)", key: "consentDate" },

  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Display Name", key: "displayName" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Country Code", key: "country" },
  { label: "Company", key: "company" },
  { label: "Job Title", key: "jobTitle" },
  { label: "Business Area", key: "businessArea" },
];

const Users: React.FC<GlobalProps> = (props: GlobalProps) => {
  const userRoleStore = props.store?.userRoleStore!;
  const userConsentStore = props.store?.userConsentStore!;
  // const userStore = props.store?.userStore!;
  const orgRoleStore = props.store?.organizationRoleStore!;
  const orgName = orgRoleStore?.selectedOrganization.name;
  const selectedOrganization = orgRoleStore?.selectedOrganization;
  const [role, setRole] = useState<UserRole | null>();
  const [showConsent, setShowConsent] = useState<boolean>(true);
  const [exportDataReport, setExportDataReport] = useState<any>();
  const [exportUser, setExportUser] = useState<boolean>(false);
  const notify = useSnackBar();
  const {
    isOpen: isUserDialogOpen,
    onOpen: onUserDialogOpen,
    onClose: onUserDialogClose,
  } = useDisclosure();
  const {
    isOpen: isInviteDialogOpen,
    onOpen: onInviteDialogOpen,
    onClose: onInviteDialogClose,
  } = useDisclosure();
  const [currentSelectedUser, setCurrentUser] = useState<UserModel | null>();

  const onUserClicked = (user: UserModel, role?: UserRole) => {
    if (!selectedOrganization) return;
    setCurrentUser(user);
    setRole(role || null);
    onUserDialogOpen();
  };

  const updateSearchQuery = (searchQuery: string) => {
    userRoleStore.setUserSearchQuery(searchQuery);
    userConsentStore.setUserSearchQuery(searchQuery);
  };

  const updateRole = async (role: UserRole) => {
    if (!currentSelectedUser) {
      return;
    }
    try {
      await userRoleStore.assignRoleToUser(currentSelectedUser.uid, role);
      setRole(role);
    } catch (e) {
      notify("error", (e as HttpError).message);
    }
  };

  const prepareExport = () => {
    const users: any[] = [];
    userRoleStore.displayStaffUsers.forEach((u) => {
      users.push({
        type: "staff",
        roleName: u.role,
        roleDate: u.timeCreated,
        firstName: u.user.firstName,
        lastName: u.user.lastName,
        displayName: u.user.displayName,
        email: u.user.email,
        phoneNumber: u.user.phoneNumber,
        country: CommonUtils.getCountryFromPhoneNumber(u.user.phoneNumber),
        company: u.user.company,
        jobTitle: u.user.jobTitle,
        businessArea: u.user.businessArea,
      });
    });
    userConsentStore.displayUsersConsent.forEach((u) => {
      // Skip adding user if already added from staff list
      const { uid } = u.user;
      if (
        userRoleStore.displayStaffUsers.find((staff) => staff.user.uid === uid)
      ) {
        return;
      }
      users.push({
        type: "external",
        consentDate: u.timeCreated,
        firstName: u.user.firstName,
        lastName: u.user.lastName,
        displayName: u.user.displayName,
        email: u.user.email,
        phoneNumber: u.user.phoneNumber,
        country: CommonUtils.getCountryFromPhoneNumber(u.user.phoneNumber),
        company: u.user.company,
        jobTitle: u.user.jobTitle,
        businessArea: u.user.businessArea,
      });
    });
    const csvReport = {
      data: users,
      headers: exportHeaders,
      filename: "KonnekAppUsers.csv",
    };
    setExportDataReport(csvReport);
    setExportUser(true);
  };

  useEffect(() => {
    userRoleStore.listUsers();
    userConsentStore.listUsers();
  }, [userRoleStore, userConsentStore]);

  return (
    <AppLayout
      colorScheme="secondary"
      startLogo={<Icon fontSize="4rem" as={MdSupervisorAccount} />}
      title={`${orgName || "Your"} Users`}
      description={
        <>
          Invite, search and export any user who has access to your KonnekApp.{" "}
          <br />
          Simply send a link to anyone you wish to have access to your KonnekApp
          portal and choose their level of access. <br />
          You can export user data including contact details, GDPR consent and
          employment information.
        </>
      }
      action={
        <Button
          size="sm"
          colorScheme="secondary"
          rightIcon={<MdSupervisorAccount />}
          onClick={() => onInviteDialogOpen()}
        >
          Invite admin
        </Button>
      }
    >
      <Box>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          my="12px"
        >
          <Box width={{ base: "100%", md: "500px" }}>
            <SearchBar onChange={updateSearchQuery} />
          </Box>
          <Box>
            <Button
              size="sm"
              colorScheme="secondary"
              mr={5}
              disabled={userConsentStore.displayUsersConsent.length === 0}
              onClick={() => setShowConsent(!showConsent)}
            >
              {showConsent ? "Admins only" : "Show all"}
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              disabled={exportUser}
              onClick={prepareExport}
            >
              Export
            </Button>
          </Box>
        </Stack>
        {userRoleStore.isUsersLoading || userConsentStore.isUsersLoading ? (
          // If stores are still loading, display loading spinner
          <Spinner size="sm" />
        ) : !(
            userRoleStore.displayStaffUsers.length === 0 &&
            userConsentStore.displayUsersConsent.length === 0
          ) ? (
          // If we have any number of users, display them
          <>
            <Heading color="secondary.500" fontSize="sm" display="inline">
              Total users:
            </Heading>

            <Heading display="inline" ml="6px" fontSize="sm">
              {showConsent
                ? (userRoleStore.displayStaffUsers.length || 0) +
                  (userConsentStore.displayUsersConsent.length || 0)
                : userRoleStore.displayStaffUsers.length}
            </Heading>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap="12px"
            >
              {userRoleStore.displayStaffUsers.map((userDetail) => (
                <Box
                  cursor="pointer"
                  onClick={() =>
                    onUserClicked(userDetail.user, userDetail.role)
                  }
                  key={userDetail.uid}
                  mr={{ base: "0", sm: "34px" }}
                  mt="20px"
                >
                  <SingleUser
                    profileUrl={userDetail.user?.photoURL}
                    name={CommonUtils.getUserDisplayDetail(userDetail.user)}
                    position={userDetail.user?.company}
                    organization={selectedOrganization.name}
                    role={userDetail.role}
                  />
                </Box>
              ))}
              {showConsent &&
                userConsentStore.displayUsersConsent
                  .filter((c) => {
                    // Do not display consent user if already staff
                    return !userRoleStore.displayStaffUsers.find((s) => {
                      return s.user.uid === c.user.uid;
                    });
                  })
                  .map((c) => (
                    <Box
                      cursor="pointer"
                      onClick={() => onUserClicked(c.user, UserRole.USER)}
                      key={c?.uid}
                      mr={{ base: "0", md: "34px" }}
                      mt="20px"
                    >
                      <SingleUser
                        profileUrl={c?.user.photoURL}
                        name={CommonUtils.getUserDisplayDetail(c.user)}
                        position={c?.user.company}
                        organization={selectedOrganization.name}
                        role={UserRole.USER}
                      />
                    </Box>
                  ))}
            </Grid>
          </>
        ) : (
          // No users to display
          <Text>No users available!</Text>
        )}
        {isUserDialogOpen && (
          <UserDialog
            user={currentSelectedUser!}
            role={role}
            onUpdateRole={updateRole}
            onClose={onUserDialogClose}
          />
        )}
        {isInviteDialogOpen && (
          <InviteUserDialog
            orgId={selectedOrganization?.uid}
            onClose={onInviteDialogClose}
          />
        )}
        {exportUser && <CSVDownload {...exportDataReport} target="_blank" />}
      </Box>
    </AppLayout>
  );
};

export default inject("store")(observer(Users));
