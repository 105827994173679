import React from "react";
import {
  Box,
  Heading,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PlaylistModel } from "../../../models/PlaylistModel";
import { CommonUtils } from "../../../utils/CommonUtils";
import { BsPinFill, IoReorderTwoOutline } from "react-icons/all";
import { GlobalProps } from "../../../App";
import { Privacy } from "../../../enums/privacy";
import PrivacyTag from "../../../shared/privacy-tag/PrivacyTag";

export interface PlaylistComponentProps extends GlobalProps {
  playlist: PlaylistModel;
  isReordering: boolean;
  onPinToggle: () => void;
  isPinned: boolean;
  isPinLoading?: boolean;
  onClick: () => void;
}

const Playlist: React.FC<PlaylistComponentProps> = ({
  playlist,
  isPinned,
  isReordering,
  isPinLoading,
  onPinToggle,
  onClick,
}) => {
  return (
    <HStack
      onClick={() => {
        if (isReordering) return;
        onClick();
      }}
      borderRadius={10}
      cursor="pointer"
      minW="380px"
      maxW="500px"
      height={110}
      mx={2}
      my={5}
      alignItems="stretch"
      position="relative"
      boxShadow="0 0px 25px 0 rgba(0, 0, 0, 0.07)"
      overflow="hidden"
    >
      <Image
        borderRadius="10px 0 0 10px"
        objectFit="cover"
        mr="5px"
        height={110}
        minW="170px"
        src={playlist.previewUrl || "/assets/images/playlist-placeholder.jpg"}
      />
      <VStack
        py="15px"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Heading
          size="sm"
          width={{ base: "100%", lg: "72%" }}
          noOfLines={1}
          textOverflow="ellipsis"
        >
          {playlist.title}
        </Heading>
        {!isReordering && <PrivacyTag privacy={playlist.privacy} />}
        <HStack color="gray">
          <Text fontSize="sm">Content: {playlist.items.length}</Text>
          <Text ml="10px" fontSize="sm">
            Watch time: {CommonUtils.msToMin(playlist.stats?.watchTime)}
          </Text>
        </HStack>
      </VStack>
      {!isReordering && playlist.privacy === Privacy.PUBLIC && (
        <Box position="absolute" top="15px" right="15px">
          {isPinLoading ? (
            <Spinner size="sm" />
          ) : (
            <BsPinFill
              cursor="pointer"
              onClick={(event) => {
                event.stopPropagation();
                onPinToggle();
              }}
              color={isPinned ? "#491BC9" : "gray"}
              aria-label="Pin Playlist"
              fontSize={20}
            />
          )}
        </Box>
      )}
      {isReordering && (
        <VStack
          alignItems="center"
          justifyContent="center"
          backgroundColor="gray.200"
          width="60px"
          borderRadius="0 10px 10px 0"
          justifySelf="flex-end"
        >
          <IoReorderTwoOutline fontSize={30} color="#9e9e9e" />
        </VStack>
      )}
    </HStack>
  );
};

export default Playlist;
