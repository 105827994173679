import React from "react";
import { MdMoreVert, HiPencil, MdOutlineCancel } from "react-icons/all";
import {
  Box,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  FormLabel,
} from "@chakra-ui/react";

export interface AvatarProps {
  avatarUrl: string | undefined;
  onChange: (file: File) => void;
  width?: string;
  height?: string;
  loading: boolean;
  label?: string;
}

const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const handleUpload = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      props.onChange(event.target.files[0]);
    }
  };
  return (
    <>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <Box
        pos="relative"
        width={props.width || "10.5rem"}
        height={props.height || "10.5rem"}
      >
        <Image
          width={props.width || "10.5rem"}
          height={props.height || "10.5rem"}
          objectFit="cover"
          src={props.avatarUrl || "/assets/images/avatar.svg"}
          borderRadius="lg"
        />
        {props.loading ? (
          <Spinner size="sm" pos="absolute" bottom="10px" right="10px" />
        ) : (
          <Menu placement="auto">
            <MenuButton
              pos="absolute"
              bottom="0"
              right="0"
              as={IconButton}
              variant={""}
              icon={<MdMoreVert />}
            />
            <MenuList px="6px" backgroundColor="#959DAD" fontSize="xs">
              <Input
                ref={inputRef}
                type="file"
                display="none"
                accept="image/*"
                onChange={handleUpload}
              />
              <MenuItem
                onClick={() => inputRef.current!.click()}
                icon={<HiPencil size="20px" />}
              >
                Update
              </MenuItem>
              <MenuItem icon={<MdOutlineCancel size="20px" />}>Cancel</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Box>
    </>
  );
};

export default Avatar;
