import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "./errors/error-boundary/ErrorBoundary";
import SnackBarProvider from "./shared/snackbar-provider/SnackBarProvider";
import { Provider } from "mobx-react";
import { rootStore } from "./stores/RootStore";
import { ChakraProvider } from "@chakra-ui/react";
import { StoreProvider } from "./stores/helpers/context";
import theme from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "",
  environment: process.env.REACT_APP_ENV || "staging",
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <StoreProvider>
            <Provider store={rootStore}>
              <SnackBarProvider>
                <App />
              </SnackBarProvider>
            </Provider>
          </StoreProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
