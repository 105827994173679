import React from "react";
import ForgetPasswordPage from "../components/ForgetPasswordPage/ForgertPasswordPage";
import { authService } from "../../services/AuthService";
import useSnackBar from "../../hooks/useSnackBar";
import { HttpError } from "../../services/ApiService";

const ForgetPasswordView = () => {
  const notify = useSnackBar();
  const forgotPassword = async (email: string) => {
    try {
      await authService.sendPasswordResetEmail(email);
      notify("success", "Password reset link sent on you email.");
    } catch (error) {
      notify("error", (error as HttpError).message);
    }
  };

  return <ForgetPasswordPage onSubmit={forgotPassword} />;
};

export default ForgetPasswordView;
