import React from "react";
import OnboardingLayout from "../../Layout";
import {
  Box,
  Button,
  Checkbox,
  Heading,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Formik, FormikErrors } from "formik";
import TextField from "../../../shared/textfield/TextField";
import { UserModel } from "../../../models/UserModel";
import {
  OnboardingSchema,
  OnboardingSchemaType,
} from "../../views/OnboardingUserDetailsView";

export interface OnboardingUserDetailsPageProps {
  user: UserModel;
  onUpdate: (value: OnboardingSchemaType) => void;
}

const OnboardingUserDetailsPage: React.FC<OnboardingUserDetailsPageProps> = (
  props: OnboardingUserDetailsPageProps
) => {
  const { user, onUpdate } = props;
  return (
    <OnboardingLayout>
      <Heading fontSize="5xl">Welcome to the KonnekApp</Heading>
      <Text mt="16px" mb="44px" color="gray" fontSize="xs">
        Please enter the following details to continue
      </Text>
      <Formik
        initialValues={{
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          phoneNumber: user.phoneNumber || "",
          termsAndConditions: false,
        }}
        validate={(values) => {
          const result = OnboardingSchema.safeParse(values);

          if (!result.success) {
            const errors: FormikErrors<OnboardingSchemaType> = {};
            result.error.issues.forEach((issue) => {
              errors[issue.path[0] as keyof typeof errors] = issue.message;
            });
            return errors;
          }
        }}
        onSubmit={onUpdate}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <TextField
              type="text"
              name="firstName"
              onChange={formProps.handleChange}
              placeholder="First name"
              value={formProps.values.firstName}
              error={
                formProps.errors.firstName && formProps.touched.firstName
                  ? formProps.errors.firstName
                  : undefined
              }
            />
            <TextField
              type="text"
              name="lastName"
              onChange={formProps.handleChange}
              placeholder="Last name"
              value={formProps.values.lastName}
              error={
                formProps.errors.lastName && formProps.touched.lastName
                  ? formProps.errors.lastName
                  : undefined
              }
            />
            <TextField
              type="text"
              name="phoneNumber"
              onChange={formProps.handleChange}
              placeholder="Phone number"
              value={formProps.values.phoneNumber}
              error={
                formProps.errors.phoneNumber && formProps.touched.phoneNumber
                  ? formProps.errors.phoneNumber
                  : undefined
              }
            />
            <br />
            <Checkbox
              name="termsAndConditions"
              checked={formProps.values.termsAndConditions}
              onChange={formProps.handleChange}
            >
              Please read and accept to our{" "}
              <Link
                href="https://www.konnek.app/platform-terms-and-conditions"
                target="_blank"
                rel="noreferrer"
                color="brand"
              >
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link
                href="https://www.konnek.app/platform-privacy-notice"
                target="_blank"
                rel="noreferrer"
                color="brand"
              >
                Privacy Notice
              </Link>
              .
            </Checkbox>
            <br />
            <br />
            <Box textAlign="end">
              {formProps.isSubmitting ? (
                <Spinner color="brand" />
              ) : (
                <Button
                  colorScheme="primary"
                  disabled={formProps.isSubmitting}
                  type={"submit"}
                >
                  Continue
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </OnboardingLayout>
  );
};

export default OnboardingUserDetailsPage;
