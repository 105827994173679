import { Image, Box, Heading, Icon, Skeleton, Text } from "@chakra-ui/react";
import { mdiEye, mdiPlaylistPlay } from "@mdi/js";
import { useQuery } from "react-query";
import { playlistService } from "../services/PlaylistService";

interface PlaylistPreviewProps {
  id: string;
  viewCount: number;
}

export function SkeletonPlaylistPreview() {
  return (
    <Box display="inline-block" p="4" w="16em" shadow="md" rounded="lg">
      <Skeleton rounded="lg" w="full" h="24" mb="2" />

      <Box display="flex" alignItems="center">
        <Icon color="purple.500" w="5">
          <path d={mdiPlaylistPlay} fill="currentColor" />
        </Icon>
        <Skeleton w="full" rounded="md" h="4" />
      </Box>
      <Box mt="2" display="flex" justifyContent="end">
        <Icon mr="1">
          <path d={mdiEye} fill="currentColor" />
        </Icon>
        <Skeleton w="8" h="4" />
      </Box>
    </Box>
  );
}

export default function PlaylistPreview(props: PlaylistPreviewProps) {
  const playlist = useQuery(["/playlists", props.id], () =>
    playlistService.fetchPlaylistById(props.id)
  );

  if (playlist.isLoading || playlist.isIdle) {
    return <SkeletonPlaylistPreview />;
  }

  if (playlist.error || !playlist.data) {
    return <>Erorr loading playlist.</>;
  }

  return (
    <Box
      display="inline-block"
      width="16em"
      shadow="md"
      p={4}
      rounded="lg"
      mr="2"
    >
      <Box mb={2}>
        {playlist.data.previewUrl && (
          <Image
            rounded="lg"
            src={playlist.data.previewUrl}
            objectFit="cover"
          />
        )}
      </Box>

      <Heading size="sm" overflow="hidden" textOverflow="ellipsis">
        <Icon color="purple.500" w="5">
          <path d={mdiPlaylistPlay} fill="currentColor" />
        </Icon>

        <Text isTruncated>{playlist.data.title}</Text>
      </Heading>

      <Box
        display="flex"
        color="gray.400"
        alignItems="center"
        justifyContent="end"
      >
        <Icon mr="1">
          <path d={mdiEye} fill="currentColor" />
        </Icon>

        {props.viewCount}
      </Box>
    </Box>
  );
}
