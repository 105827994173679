import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";

export interface WelcomeScreenProps {
  backgroundUrl?: string;
  logoUrl?: string;
  description?: string;
  secondaryColor?: string;
}

const getDescription = (desc: string | undefined) => {
  if (!desc) {
    return;
  }
  if (desc.length > 70) {
    return desc.substr(0, 70) + "...";
  }
  return desc;
};

const WelcomeScreen: React.FC<WelcomeScreenProps> = (
  props: WelcomeScreenProps
) => {
  return (
    <Box position="relative" width="260px" height="530px">
      <Image
        position="absolute"
        top={0}
        left={0}
        width="260px"
        height="530px"
        src="/assets/images/mobile_outline.png"
      />
      <Box px="30px" height="100%">
        <Image
          src={props.backgroundUrl || "/assets/images/org-background.svg"}
          mt="10px"
          width="260px"
          height="60%"
        />
        <Center>
          <Image src={props.logoUrl} pos="absolute" top="15%" />
        </Center>

        <Heading mt="12px" textAlign="center" fontSize="md">
          Welcome KonnekApp User!
        </Heading>
        <Text fontSize="xs" textAlign="center" mt="12px">
          {getDescription(props.description)}
        </Text>
        <Flex pos="absolute" bottom="30px" width="200px">
          <Button size="sm" fontSize="xs">
            DON'T ALLOW
          </Button>
          <Spacer />
          <Button
            backgroundColor={props.secondaryColor}
            size="sm"
            fontSize="xs"
          >
            ALLOW
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default WelcomeScreen;
