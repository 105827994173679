import Model from "./Model";
import { observable } from "mobx";
import { PlaylistStore } from "../stores/PlaylistStore";
import { Privacy } from "../enums/privacy";
import { MediaStats } from "../interface/media-stats";

export class PlaylistModel extends Model {
  static _store: PlaylistStore;

  uid!: string;
  @observable orgId!: string;
  @observable title!: string;
  @observable description!: string;
  @observable previewUrl!: string | null;
  @observable privacy!: Privacy;
  @observable contact?: PlaylistContact;
  @observable items!: string[];
  @observable stats!: MediaStats;
  @observable timeCreated!: Date;
  @observable timeModified!: Date;

  getId(): string {
    return this.uid;
  }
}

export interface PlaylistContact {
  phoneNumber?: string;
  email?: string;
}
