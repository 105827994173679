import React from "react";
import { z } from "zod";
import CreateBillingAccountPage from "../components/CreateBillingAccountPage/CreateBillingAccountPage";
import { AccountType } from "../../enums/account-type";
import { useHistory, useLocation } from "react-router";
import useSnackBar from "../../hooks/useSnackBar";
import { CommonUtils } from "../../utils/CommonUtils";
import { GlobalProps } from "../../App";
import { stripeService } from "../../services/StripeService";
import { ACCOUNT_ID } from "../../utils/ConstantUtils";
import { HttpError } from "../../services/ApiService";

export const BillingAccountSchema = z.object({
  friendlyName: z.string().min(1, { message: "Nick name is required" }),
  billingName: z.string().min(1, { message: "Billing name is required" }),
  billingEmail: z.string().min(1, { message: "Billing email is required." }),
  billingPhone: z.string().min(1, { message: "Billing phone is required" }),
});

export type BillingAccountSchemaType = z.infer<typeof BillingAccountSchema>;

const CreateBillingAccountView: React.FC<GlobalProps> = (
  props: GlobalProps
) => {
  const history = useHistory();
  const notify = useSnackBar();
  const location = useLocation();
  const user = props.store?.authStore?.loggedInUser;
  const { type } = CommonUtils.parseQueryString(location.search);
  const getInitialFormState = (): BillingAccountSchemaType => {
    const value = {
      friendlyName: "",
      billingName: "",
      billingEmail: "",
      billingPhone: "",
    };
    if (type === AccountType.INDIVIDUAL) {
      value.billingEmail = user?.email || "";
      value.billingName = user?.displayName || "";
    }
    return value;
  };

  const createBillingAccount = async (values: BillingAccountSchemaType) => {
    try {
      const account = await stripeService.createBillingAccounts({
        account: {
          ...values,
          billingPhone: values.billingPhone.toString(),
          billingAddress: null,
        },
      });
      if (account) {
        localStorage.setItem(ACCOUNT_ID, account.uid);
        history.push(`/billing/${account.uid}`);
      }
    } catch (error) {
      notify("error", (error as HttpError).message);
    }
  };
  return (
    <CreateBillingAccountPage
      initialFormState={getInitialFormState()}
      onCreate={createBillingAccount}
    />
  );
};

export default CreateBillingAccountView;
