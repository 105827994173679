import React from "react";
import {
  Avatar,
  Box,
  Center,
  Heading,
  HStack,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BsFillTelephoneFill, MdEmail } from "react-icons/all";
import { UserModel } from "../../models/UserModel";
import { CommonUtils } from "../../utils/CommonUtils";
import { UserRole } from "../../enums/user-roles";
import { EnumUtils } from "../../utils/EnumUtils";

export interface UserDialogProps {
  onClose: () => void;
  user: UserModel;
  role?: UserRole | null;
  onUpdateRole: (role: UserRole) => void;
}

const menuOptions = CommonUtils.iterateEnum(UserRole).reduce(
  (accumulator, role) => {
    return [
      ...accumulator,
      <option key={role} value={role}>
        {EnumUtils.getUserRoleTitle(role)}
      </option>,
    ];
  },
  []
);

const UserDialog: React.FC<UserDialogProps> = (props: UserDialogProps) => {
  const { onClose, user, role, onUpdateRole } = props;
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box
          pos="relative"
          borderRadius="8px 8px 0 0"
          bg="primary.100"
          height="180px"
          mb="52px"
        >
          <Center>
            <Avatar
              pos="absolute"
              top="60%"
              boxSize="120px"
              borderRadius="50%"
              name={CommonUtils.getUserDisplayDetail(user)}
              src={user.photoURL || "/assets/images/avatar.svg"}
            />
          </Center>
        </Box>
        <Box p="12px" overflow="hidden" width="100%">
          <VStack spacing={3} width="100%">
            <Box width="100%" textAlign="center">
              <Heading textOverflow="ellipsis" noOfLines={1}>
                {CommonUtils.getUserDisplayDetail(user)}
              </Heading>
            </Box>
            <Center>
              <Text textAlign="center" color="gray.500">
                {user.company}
              </Text>
            </Center>
            role && (
            <Center>
              <Select
                size="sm"
                borderRadius="8px"
                variant="filled"
                backgroundColor="primary.100"
                value={role!}
                onChange={(e) => onUpdateRole(e.target.value as UserRole)}
                border="none"
              >
                {menuOptions}
              </Select>
            </Center>
            )
            <Center>
              <HStack spacing={4}>
                <BsFillTelephoneFill />
                <Text fontWeight="bold" textAlign="center">
                  {user.phoneNumber || "N/A"}
                </Text>
              </HStack>
            </Center>
            <Center>
              <HStack spacing={4}>
                <MdEmail />
                <Text fontWeight="bold" textAlign="center">
                  {user.email || "N/A"}
                </Text>
              </HStack>
            </Center>
          </VStack>
        </Box>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default UserDialog;
