import React from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react";
import { MdKeyboardBackspace, MdOutlineInfo } from "react-icons/md";
import useLogout from "../hooks/useLogout";

const NoPlan: React.FC = () => {
  const logout = useLogout();
  return (
    <Box height="100vh" padding="1rem 3rem" position="relative">
      <Image src="/assets/icons/konnek-brand-black.png" alt="Brand" />
      <Heading my="20px">No active plan detected</Heading>
      <Text size="sm" my="20px" width="46%" color="gray">
        Your current subscription plan for KonnekApp has expired. Please renew
        your subscription by selecting one of the plans that matches your need.
        If you think there is something wrong, please feel free to contact the
        customer care.
      </Text>
      <HStack
        width="fit-content"
        borderRadius="lg"
        spacing={4}
        padding="1.4rem 1rem"
        backgroundColor="primary.300"
      >
        <Icon boxSize="30px" as={MdOutlineInfo} />
        <Text>Don’t lose leads. Recharge now and increase your reach</Text>
        <Button
          size="sm"
          colorScheme="primary"
          onClick={() => {
            window.open("https://www.konnek.app/pricing");
          }}
        >
          View Plans
        </Button>
      </HStack>
      <Image
        position="absolute"
        bottom="20px"
        right="20px"
        src="/assets/images/no-plan.png"
        alt="No plan"
      />

      <Button mt={"20px"} onClick={logout} leftIcon={<MdKeyboardBackspace />}>
        Logout
      </Button>
    </Box>
  );
};
export default NoPlan;
