import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import useSnackBar from "../../hooks/useSnackBar";
import { CommonUtils } from "../../utils/CommonUtils";
import { ACCOUNT_ID, PLAN_ID } from "../../utils/ConstantUtils";
import { BusinessPlan } from "../../enums/business-plan";
import SelectBillingAccountPage from "../components/SelectBillingAccountPage/SelectBillingAccountPage";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import { HttpError } from "../../services/ApiService";
import { BillingAccountModel } from "../../models/BillingAccountModel";

const SelectBillingAccountView: React.FC<GlobalProps> = (
  props: GlobalProps
) => {
  const billingAccountStore = props.store?.billingAccountStore;
  const history = useHistory();
  const notify = useSnackBar();
  const location = useLocation();

  const addBillingAccount = () => {
    history.push("/account-type");
  };

  const selectBillingAccount = (account: BillingAccountModel) => {
    localStorage.setItem(ACCOUNT_ID, account.uid);
    history.push(`/billing/${account.uid}`);
  };

  const listBillingAccounts = async () => {
    try {
      const queryParams = CommonUtils.parseQueryString(location.search);
      if (!queryParams.planId && !props.store?.planId) {
        notify("error", "Please select a plan to proceed!");
        // TODO: Add the link to the plans.
        return;
      }
      localStorage.setItem(PLAN_ID, queryParams.planId);
      props.store!.planId = queryParams.planId as BusinessPlan;
      billingAccountStore?.fetchBillingAccounts();
    } catch (error) {
      if ((error as { status: number }).status === 404) {
        history.push("/account-type");
      } else {
        notify("error", (error as HttpError).message);
      }
    }
  };
  useEffect(() => {
    listBillingAccounts();
    // eslint-disable-next-line
  }, []);
  return (
    <SelectBillingAccountPage
      isLoading={billingAccountStore?.isLoading!}
      billingAccounts={billingAccountStore?.entities || []}
      onAddBillingAccount={addBillingAccount}
      onSelect={selectBillingAccount}
    />
  );
};

export default inject("store")(observer(SelectBillingAccountView));
