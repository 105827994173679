import React from "react";
import OnboardingLayout from "../../Layout";
import {
  Button,
  Center,
  Divider,
  Heading,
  Spinner,
  Text,
} from "@chakra-ui/react";
import BillingAccountCard from "../BillingAccountCard/BillingAccountCard";
import { BillingAccountModel } from "../../../models/BillingAccountModel";

export interface SelectBillingAccountPageProps {
  isLoading: boolean;
  billingAccounts: BillingAccountModel[];
  onSelect: (account: BillingAccountModel) => void;
  onAddBillingAccount: () => void;
}

const SelectBillingAccountPage: React.FC<SelectBillingAccountPageProps> = (
  props: SelectBillingAccountPageProps
) => {
  const { isLoading, billingAccounts, onAddBillingAccount, onSelect } = props;
  return (
    <OnboardingLayout>
      <Heading fontSize="5xl">Use an existing billing account?</Heading>
      <Text mt="16px" mb="44px" color="gray" fontSize="xs">
        This is a list of billing accounts which have been previously used by
        you, choose one or create a billing account for this purchase.
      </Text>
      {isLoading ? (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brand"
            size="sm"
          />
        </Center>
      ) : (
        <>
          {billingAccounts.map((account) => (
            <BillingAccountCard
              key={account.uid}
              billingAccount={account}
              onSelect={() => {
                onSelect(account);
              }}
              loading={false}
            />
          ))}
          <Divider my="40px" />
          <Center>
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={onAddBillingAccount}
            >
              Add a new billing account
            </Button>
          </Center>
        </>
      )}
    </OnboardingLayout>
  );
};

export default SelectBillingAccountPage;
