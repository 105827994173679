import { UserModel } from "../models/UserModel";
import Store from "./Store";
import { computed } from "mobx";
import { RootStore } from "./RootStore";

export class UserStore extends Store<UserModel> {
  constructor(private rootStore: RootStore) {
    super();
    UserModel._store = this;
  }

  @computed
  get displayUser(): UserModel[] {
    return this.entities || [];
  }
}
