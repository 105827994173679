import { action, observable } from "mobx";
import { User } from "../models/UserModel";
import { userService } from "../services/UserService";
import { RootStore } from "./RootStore";

export class ProfileStore {
  rootStore: RootStore;

  @observable
  profiles: { [k: string]: User } = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  fetch(orgId: string, ...ids: string[]) {
    userService.getUsers(orgId, ids).then(
      action("fetchProfilesSuccess", (users) => {
        for (const user of users) {
          this.profiles[user.uid] = user;
        }
      })
    );
  }
}
