import React from "react";
import OnboardingLayout from "../../Layout";
import { Heading, Stack, Text } from "@chakra-ui/react";
import AccountTypeCard from "../AccountTypeCard/AccountTypeCard";
import {
  Agency,
  Business,
  User,
} from "../../../shared/custom-icons/CustomIcons";
import {
  AccountType,
  AccountType as Accounts,
} from "../../../enums/account-type";

export interface SelectAccountTypePageProps {
  onSelect: (type: AccountType) => void;
}

const SelectAccountTypePage: React.FC<SelectAccountTypePageProps> = (
  props: SelectAccountTypePageProps
) => {
  const { onSelect } = props;
  return (
    <OnboardingLayout>
      <Heading fontSize="5xl">
        What would you like to use KonnekApp for?
      </Heading>
      <Text mt="16px" color="gray" fontSize="xs">
        Choose the option that fits you: <br />
        You are an individual creator looking to use KonnekApp for personal use
        - set up an 'Individual' account. <br />
        You are looking to set up KonnekApp for your business's own commercial
        purposes - set up a 'Business' account. <br />
        You are an agency looking to set up lots of different organisations to
        promote brands, products and services - set up an 'Agency' account.
      </Text>
      <Stack
        mt="2rem"
        spacing={5}
        direction={{ base: "column", lg: "row" }}
        align={{ base: "center", lg: "start" }}
      >
        <AccountTypeCard
          icon={<User />}
          type={Accounts.INDIVIDUAL}
          description="An Individual account allows you to set up a KonnekApp account with you as the account owner. You alone are responsible for the account and billing."
          onSelect={onSelect}
        />
        <AccountTypeCard
          icon={<Business />}
          type={Accounts.BUSINESS}
          description="A Business account allows you to set up a KonnekApp account with you as the account owner on behalf of your business. You can add other Super Admins from your business, who can also be responsible for the account and billing."
          onSelect={onSelect}
        />
        <AccountTypeCard
          icon={<Agency />}
          type={Accounts.AGENCY}
          description="An Agency account allows you to set up a KonnekApp account with you as the account owner running multiple KonnekApp organizations. You can add other Super Admins from your agency who can be responsible for the account and billing."
          onSelect={onSelect}
        />
      </Stack>
    </OnboardingLayout>
  );
};

export default SelectAccountTypePage;
