import React from "react";
import { useContext } from "react";
import AppLayout from "../shared/app-layout/AppLayout";
import {
  Box,
  Icon,
  Flex,
  Table,
  Tr,
  Thead,
  Th,
  Tbody,
  Skeleton,
  Link,
  Stack,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { mdiBriefcase } from "@mdi/js";
import { useEffect } from "react";
import { Lead as LeadT } from "../services/AnalyticsService";
import { StoreContext } from "../stores/helpers/context";
import { User } from "../models/UserModel";
import Lead from "./Lead";
import { observer } from "mobx-react";
import _ from "lodash";
import { CSVLink } from "react-csv";

// Used for loading state.
const mockData: LeadT[] = _.times(5, () => ({
  orgId: "",
  userId: "",
  score: 1,
  temperature: "hot",
  lastModified: new Date(),
}));

interface LeadsPageProps {
  leads: LeadT[] | null;
  profiles: { [userId: string]: User };
  leadsCsv: string[][] | null;
  orgName?: string;
}

export function LeadsPage({
  leads,
  profiles,
  orgName,
  leadsCsv,
}: LeadsPageProps) {
  return (
    <AppLayout
      colorScheme="secondary"
      startLogo={
        <Icon fontSize="4rem">
          <path fill="currentColor" d={mdiBriefcase}></path>
        </Icon>
      }
      title={`${orgName || "Your"} Leads`}
      description={
        <>
          <span>
            Find all of your hot, cold and warm leads right away based on their
            engagement with your content.
          </span>
          <br></br>
          <span>
            See at a glance all of your lead information and contact your hot
            and warm leads right away to increase your conversion success.
          </span>
        </>
      }
    >
      <Flex justify="space-between" marginBottom="18px">
        {leads ? (
          <Stack spacing={8} direction={{ base: "column", lg: "row" }}>
            <Tag>
              <TagLabel>{`Total leads ${leads.length}`}</TagLabel>
            </Tag>
            <Tag colorScheme="red">
              <TagLabel>{`Hot leads ${
                leads.filter((l) => l.temperature === "hot").length
              }`}</TagLabel>
            </Tag>
            <Tag colorScheme="orange">
              <TagLabel>{`Warm leads ${
                leads.filter((l) => l.temperature === "warm").length
              }`}</TagLabel>
            </Tag>
            <Tag colorScheme="blue">
              <TagLabel>{`Cold leads ${
                leads.filter((l) => l.temperature === "cold").length
              }`}</TagLabel>
            </Tag>
          </Stack>
        ) : (
          <Skeleton height="20px" width="200px" />
        )}
      </Flex>
      <Box overflowX="auto">
        <Table minWidth="120ch">
          <Thead>
            <Tr>
              <Th textTransform="none">User</Th>
              <Th textTransform="none">Email</Th>
              <Th textTransform="none">Phone number</Th>
              <Th textTransform="none">Country</Th>
              <Th textTransform="none">Lead type</Th>
              <Th textTransform="none">Company</Th>
              <Th textTransform="none">Business area</Th>
              <Th textTransform="none">Job title</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!leads && mockData.map((x, i) => <Lead key={i} {...x} />)}
            {leads?.map((x, i) => (
              <Lead key={i} {...x} />
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box mt="8">
        {leads && leads?.length > 0 && leadsCsv && (
          <Link data={leadsCsv} as={CSVLink} color="gray.600">
            Download leads as .csv
          </Link>
        )}
      </Box>
    </AppLayout>
  );
}

export default observer(() => {
  const store = useContext(StoreContext);

  useEffect(() => {
    store.analyticsStore?.fetchLeads();
  }, []);

  return (
    <LeadsPage
      leads={store!.analyticsStore!.leads}
      profiles={store!.profileStore!.profiles}
      leadsCsv={store!.analyticsStore!.leadsCsv || null}
      orgName={store!.organizationRoleStore?.selectedOrganization.name}
    />
  );
});
