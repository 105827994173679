import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { PlaylistModel } from "../../../models/PlaylistModel";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import Playlist from "../Playlist/Playlist";
import {
  MdCheck,
  MdInfo,
  MdPlaylistPlay,
  MdPushPin,
  MdReorder,
} from "react-icons/md";
import Button from "../../../shared/button/Button";

interface PinnedPlaylistProps {
  pinnedPlaylists: PlaylistModel[];
  isPinned: (playlistId: string) => boolean;
  togglePinPlaylist: (playlistId: string) => void;
  isPinLoading: (playlistId: string) => boolean;
  onSubmit: (ids: string[]) => void;
  onPlaylistClick: (id: string) => void;
}

const PinnedPlaylist: React.FC<PinnedPlaylistProps> = ({
  pinnedPlaylists,
  isPinned,
  isPinLoading,
  togglePinPlaylist,
  onSubmit,
  onPlaylistClick,
}) => {
  const [playlists, reorderPlaylists] = useState([...pinnedPlaylists]);
  const [isReordering, setIsReordering] = useState(false);
  const playlistReorderDialogController = useDisclosure();
  const reorder = (
    list: PlaylistModel[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }
    reorderPlaylists(
      reorder(playlists, result.source.index, result.destination.index)
    );
  };

  useEffect(() => {
    reorderPlaylists([...pinnedPlaylists]);
  }, [pinnedPlaylists]);
  return (
    <>
      <HStack alignItems="center">
        <Icon color="secondary.700" fontSize="20px" as={MdPushPin} />
        <Heading fontSize="2xl">Pinned playlists</Heading>
      </HStack>
      <HStack my="20px" justifyContent="space-between">
        <HStack
          borderRadius="4"
          backgroundColor="secondary.100"
          color="secondary.700"
          padding="8px"
        >
          <Icon color="secondary.700" fontSize="20px" as={MdInfo} />
          <Text fontSize="sm">
            You can pin {4 - pinnedPlaylists.length!} more playlists
          </Text>
        </HStack>
        {isReordering ? (
          <Button
            onClick={() => {
              setIsReordering(false);
              onSubmit(playlists.map((playlist) => playlist.uid));
            }}
            leftIcon={<MdCheck />}
            colorScheme="secondary"
          >
            Done
          </Button>
        ) : (
          <Button
            onClick={() => {
              playlistReorderDialogController.onOpen();
              setIsReordering(true);
            }}
            leftIcon={<MdReorder />}
            colorScheme="gray"
          >
            Reorder
          </Button>
        )}
      </HStack>
      {!pinnedPlaylists || pinnedPlaylists.length === 0 ? (
        <VStack
          my="20px"
          justify="center"
          alignItems="center"
          width="100%"
          height="200px"
          borderRadius="4px"
          boxShadow="0 0px 25px 0 rgba(0, 0, 0, 0.07)"
        >
          <Icon fontSize="35px" color="secondary.700" as={MdPlaylistPlay} />
          <Text color="gray">No pinned playlists!</Text>
        </VStack>
      ) : (
        <Grid
          height="auto"
          overflow="scroll"
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
          gap={6}
        >
          {playlists.map((p, index) => (
            <Box key={p.id}>
              <GridItem w="100%">
                <Playlist
                  isPinned={isPinned(p.uid)}
                  isPinLoading={isPinLoading(p.uid)}
                  onPinToggle={() => {
                    togglePinPlaylist(p.uid);
                  }}
                  isReordering={isReordering}
                  playlist={p}
                  onClick={() => onPlaylistClick(p.uid)}
                />
              </GridItem>
            </Box>
          ))}
        </Grid>
      )}
      {playlistReorderDialogController.isOpen && (
        <>
          <Modal
            isOpen={playlistReorderDialogController.isOpen}
            onClose={() => {
              setIsReordering(false);
              playlistReorderDialogController.onClose();
            }}
            size="2xl"
          >
            <ModalOverlay />

            <ModalContent p="30px" borderRadius="21px">
              <ModalHeader>Reorder Pinned Playlist</ModalHeader>
              <ModalCloseButton />
              <VStack>
                <DragDropContext
                  // onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                >
                  <Droppable droppableId="playlist">
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        // height="540px"
                      >
                        {playlists.map((p, index) => (
                          <Draggable
                            key={p.uid}
                            draggableId={p.uid}
                            index={index}
                          >
                            {(provided, snapshot1) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Playlist
                                  isPinned={isPinned(p.uid)}
                                  isPinLoading={isPinLoading(p.uid)}
                                  onPinToggle={() => {
                                    togglePinPlaylist(p.uid);
                                  }}
                                  isReordering={true}
                                  key={p.id}
                                  playlist={p}
                                  onClick={() => onPlaylistClick(p.uid)}
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
                <HStack justifyContent="flex-end" w="100%">
                  <Button
                    onClick={() => {
                      setIsReordering(false);
                      playlistReorderDialogController.onClose();
                      onSubmit(playlists.map((playlist) => playlist.uid));
                    }}
                    leftIcon={<MdCheck />}
                    colorScheme="secondary"
                  >
                    Done
                  </Button>
                </HStack>
              </VStack>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
};

export default PinnedPlaylist;
