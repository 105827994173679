import React from "react";
import { z } from "zod";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import useSnackBar from "../../hooks/useSnackBar";
import { useHistory } from "react-router";
import { omit } from "lodash";
import OnboardingUserDetailsPage from "../components/OnboardingUserDetailsPage/OnboardingUserDetailsPage";
import { HttpError } from "../../services/ApiService";

export const OnboardingSchema = z.object({
  firstName: z.string().min(1, { message: "First name is required " }),
  lastName: z.string().min(1, { message: "Last name is required " }),
  phoneNumber: z.string().min(1, { message: "Phone number is required " }),
  termsAndConditions: z.boolean(),
});
export type OnboardingSchemaType = z.infer<typeof OnboardingSchema>;

const OnboardingUserDetailsView: React.FC<GlobalProps> = (
  props: GlobalProps
) => {
  const { store } = props;
  const user = store?.authStore?.loggedInUser!;
  const notify = useSnackBar();
  const history = useHistory();
  const updateUserProfile = async (value: OnboardingSchemaType) => {
    if (!value.termsAndConditions) {
      notify(
        "error",
        "Accept to our Terms and Conditions before proceeding further."
      );
      return;
    }
    try {
      await store?.authStore?.updateLoggedInUser(
        omit(value, "termsAndConditions")
      );
      history.push(store?.redirectUrl!);
    } catch (error) {
      notify("error", (error as HttpError).message);
    }
  };
  return <OnboardingUserDetailsPage user={user} onUpdate={updateUserProfile} />;
};

export default inject("store")(observer(OnboardingUserDetailsView));
