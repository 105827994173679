import React from "react";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ContentModel } from "../../models/ContentModel";
import ReactPlayer from "react-player";
import { ContentSourceType } from "../../enums/content-source-type";
import TypeIcon from "../TypeIcon";
import { MdDownload, MdEdit, MdRemoveRedEye } from "react-icons/md";

export interface ContentPreviewProps {
  content: ContentModel;
  onUpdate?: () => void;
}

const ContentPreview: React.FC<ContentPreviewProps> = (props) => {
  return (
    <VStack p={6} alignItems={"stretch"}>
      {props.content.type === ContentSourceType.INTEGRATION && (
        <ReactPlayer width="100%" url={props.content.url} />
      )}
      {props.content.type === ContentSourceType.NATIVE && (
        <Image
          rounded="md"
          objectFit="cover"
          src={
            props.content.previewUrl || "/assets/images/video-placeholder.jpg"
          }
        />
      )}
      <HStack py={5} justifyContent="space-between">
        <VStack alignItems={"stretch"}>
          <HStack>
            <TypeIcon
              integration={props.content.type === ContentSourceType.INTEGRATION}
              mime={props.content.file?.type || null}
            />

            <Heading noOfLines={2} as="h3" fontSize="sm" width="full">
              {props.content.title}
            </Heading>
          </HStack>
          <HStack color="rgba(0, 0, 0, 0.38)">
            <MdRemoveRedEye fontSize="1.5rem" />
            <Text fontSize="xs">{props.content.stats.views || 0} views</Text>
          </HStack>
        </VStack>
        <Box>
          {props.content.type === ContentSourceType.NATIVE && (
            <IconButton
              variant="ghost"
              aria-label="download"
              icon={<MdDownload size="22px" />}
              onClick={() => window.open(props.content.url, "_blank")}
            />
          )}
          {!!props.onUpdate && (
            <IconButton
              variant="ghost"
              aria-label="download"
              icon={<MdEdit size="22px" />}
              onClick={props.onUpdate}
            />
          )}
        </Box>
      </HStack>
      <Text fontSize="xs" color="rgba(0, 0, 0, 0.50)">
        {props.content.description}
      </Text>
    </VStack>
  );
};

export default ContentPreview;
