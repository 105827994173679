import React, { ReactNode } from "react";
import { capitalize } from "lodash";
import { Box, Button, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { AccountType } from "../../../enums/account-type";

export interface AccountTypeCardProps {
  type: AccountType;
  icon: ReactNode;
  description: string;
  onSelect: (accountType: AccountType) => void;
}

const AccountTypeCard: React.FC<AccountTypeCardProps> = (props) => {
  return (
    <Box
      width="240px"
      borderRadius="10px"
      p="36px 16px"
      textAlign="center"
      boxShadow="lg"
    >
      <VStack spacing={3}>
        {props.icon}
        <Heading fontSize="xl">{capitalize(props.type)}</Heading>
        <Divider />
        <Text fontSize="xs" color="gray">
          {props.description}
        </Text>
        <Button
          fontSize="14px"
          height="30px"
          colorScheme="primary"
          onClick={() => props.onSelect(props.type)}
          type="submit"
        >
          Choose
        </Button>
      </VStack>
    </Box>
  );
};

export default AccountTypeCard;
