import Model from "./Model";
import { EntityIdentifier } from "../stores/Store";
import { observable } from "mobx";
import { Theme } from "../interface/theme";
import { OrganizationStore } from "../stores/OrganizationStore";
import { Privacy } from "../enums/privacy";

export class OrganizationModel extends Model {
  static _store: OrganizationStore;
  uid!: string;
  @observable name!: string;
  @observable description!: string;
  @observable logoUrl!: string;
  @observable website!: string;
  @observable publicJoinUrl?: string;
  @observable privacy?: Privacy;
  @observable defaultPlaylist!: string;
  @observable pinnedPlaylists!: string[];
  @observable theme!: Theme;
  @observable contact!: {
    legalName: string;
    phoneNumber: string;
    address: string;
  };

  getId(): EntityIdentifier {
    return this.uid;
  }
}
