import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdEdit, MdRemoveRedEye } from "react-icons/md";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../App";
import TypeIcon from "./TypeIcon";
import { ContentSourceType } from "../enums/content-source-type";
import { ContentModel } from "../models/ContentModel";

// Content to display in component.
export interface ContentCardProps extends GlobalProps {
  content: ContentModel;
  editContent?: () => void;
}

/**
 * <ReorderableContentCard /> displays the thumbnail of the content, the title, and the
 * truncated description.
 */
const ReorderableContentCard: React.FC<ContentCardProps> = (props) => {
  return (
    <HStack
      width="100%"
      justifyContent="space-between"
      p={5}
      boxShadow="0px 0px 25px rgba(0, 0, 0, 0.07)"
    >
      <HStack>
        <Image
          rounded="md"
          objectFit="cover"
          height="3.5rem"
          width="7rem"
          minW="7rem"
          src={
            props.content.previewUrl || "/assets/images/video-placeholder.jpg"
          }
        />
        <VStack alignItems="flex-start">
          <HStack>
            <TypeIcon
              integration={props.content.type === ContentSourceType.INTEGRATION}
              mime={props.content.file?.type || null}
            />

            <Heading
              maxW="320px"
              isTruncated
              noOfLines={2}
              as="h3"
              fontSize="sm"
            >
              {props.content.title}
            </Heading>
          </HStack>
          <HStack>
            <MdRemoveRedEye fontSize="1.5rem" />
            <Text fontSize="xs">{props.content.stats.views || 0} views</Text>
          </HStack>
        </VStack>
      </HStack>

      {!!props.editContent && (
        <Button size="sm" variant="ghost" onClick={props.editContent}>
          <MdEdit />
        </Button>
      )}
    </HStack>
  );
};

export default inject("store")(observer(ReorderableContentCard));
