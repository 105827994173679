import React from "react";
import { useToast } from "@chakra-ui/react";

export type VariantType = "success" | "error" | "warning";

export interface NotificationContext {
  show?: (variant: VariantType, message: string) => void;
}

export const SnackbarContext = React.createContext<NotificationContext>({});

const SnackBarProvider: React.FC<any> = ({ children }) => {
  const toast = useToast();
  const showSnackBar = (variant: VariantType, message: string) => {
    toast({
      title: message,
      status: variant,
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <SnackbarContext.Provider value={{ show: showSnackBar }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackBarProvider;
