import React from "react";
import { UserRole } from "../../enums/user-roles";
import { Avatar, Heading, HStack, Text, VStack } from "@chakra-ui/react";

export interface SingleUserProps {
  profileUrl: string | null;
  name: string;
  position: string;
  organization: string;
  role?: UserRole;
  onClick?: () => void;
}

const SingleUser: React.FC<SingleUserProps> = (props: SingleUserProps) => {
  return (
    <HStack spacing={4} alignItems="start">
      <Avatar
        borderRadius="8px"
        size="lg"
        color="white"
        backgroundColor="brand"
        name={props.name}
        src={props.profileUrl || ""}
      />
      <VStack spacing="1px" alignItems="start">
        <Heading fontSize="lg">{props.name}</Heading>
        <Text fontWeight="bold" fontSize="xs" color="gray">
          {props.position}
        </Text>
        <Text fontWeight="bold" fontSize="xs" color="gray">
          {props.organization}
        </Text>
      </VStack>
    </HStack>
  );
};

export default React.memo(SingleUser);
