export enum InviteStatus {
  PENDING = "pending",
  ACTIVE = "active",
}

export interface InviteDetails {
  uid: string;
  timeCreated: string;
  orgId: string;
  expiry: string;
  userEmail: string;
  status: InviteStatus;
}
