import React from "react";

export interface IconProps {
  size?: number;
}

export const UserCheck: React.FC<IconProps> = (props: IconProps) => {
  const size = props.size || 25;
  return (
    <>
      <img width={size} src="/assets/icons/user-check.png" alt="user-check" />
    </>
  );
};

export const Crown: React.FC<IconProps> = (props: IconProps) => {
  const size = props.size || 25;
  return (
    <>
      <img width={size} src="/assets/icons/user-check.png" alt="user-check" />
    </>
  );
};

export const User: React.FC<IconProps> = (props: IconProps) => {
  const size = props.size || 25;
  return (
    <>
      <img width={size} src="/assets/icons/user.svg" alt="user" />
    </>
  );
};

export const Agency: React.FC<IconProps> = (props: IconProps) => {
  const size = props.size || 25;
  return (
    <>
      <img width={size} src="/assets/icons/agency.svg" alt="agency" />
    </>
  );
};

export const Business: React.FC<IconProps> = (props: IconProps) => {
  const size = props.size || 25;
  return (
    <>
      <img width={size} src="/assets/icons/business.svg" alt="business" />
    </>
  );
};
