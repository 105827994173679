import Store from "./Store";
import { ContentModel } from "../models/ContentModel";
import { RootStore } from "./RootStore";
import { action, computed, observable, runInAction } from "mobx";
import { contentService } from "../services/ContentService";
import { TimelineValue } from "../enums/timeline";
import { MediaUtils } from "../utils/MediaUtils";
import { CreateVideoDto } from "../dtos/create-video.dto";
import { uploadService } from "../services/UploadService";

export class ContentStore extends Store<ContentModel> {
  @observable isVideosLoading = false;
  @observable isVideosLoaded = false;
  @observable timelineValue = TimelineValue.TODAY;
  @observable addVideoSearchQuery = "";
  @observable lastLoadedId = "";
  @observable isOnLastPage = false;

  constructor(private rootStore: RootStore) {
    super();
    ContentModel._store = this;
  }

  @action
  async listVideos() {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    if (this.isVideosLoading || this.isVideosLoaded) {
      return;
    }
    try {
      this.isVideosLoading = true;
      const videos = await contentService.listContent(orgId);
      runInAction(() => {
        this.isVideosLoading = false;
        this.isVideosLoaded = true;
        if (videos && videos.length > 10) {
          this.lastLoadedId = videos[videos.length - 1].uid;
        } else {
          this.isOnLastPage = true;
        }
      });
    } catch (e) {
      runInAction(() => {
        this.isVideosLoading = false;
        throw e;
      });
    }
  }

  @action
  async listMoreVideos() {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    if (this.isVideosLoading || this.isOnLastPage || !this.lastLoadedId) {
      return;
    }
    try {
      const videos = await contentService.listContent(orgId, {
        startAfter: this.lastLoadedId,
      });
      runInAction(() => {
        if (
          videos.length === 0 ||
          this.lastLoadedId === videos[videos.length - 1].uid
        ) {
          this.isOnLastPage = true;
        }
      });
    } catch (e) {
      runInAction(() => {
        throw e;
      });
    }
  }

  @action
  async createVideo(
    data: CreateVideoDto,
    notifyUsers: boolean,
    file?: File | null,
    thumbnail?: File | null
  ): Promise<ContentModel> {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;

    // Upload thumbnail if one was provided
    let { previewUrl } = data;
    if (thumbnail) {
      previewUrl = await uploadService.uploadFile(thumbnail, orgId);
    }

    // Create content and upload if file was provided
    return await contentService.createContent(
      orgId,
      { ...data, previewUrl },
      file || null,
      notifyUsers
    );
  }

  @action
  async deleteVideo(videoId: string) {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    try {
      await contentService.deleteContent(orgId, videoId);
      this.entities = this.entities.filter((e) => e.uid !== videoId);
    } catch (error) {
      throw error;
    }
  }

  @action
  setTimelineValue(value: TimelineValue) {
    this.timelineValue = value;
  }

  @action
  setAddVideoSearchQuery(value: string) {
    this.addVideoSearchQuery = value;
  }

  @computed
  get organizationVideos(): ContentModel[] {
    return this.entities.slice().sort((v1, v2) => {
      return (
        new Date(v1?.timeCreated!).getTime() -
        new Date(v2?.timeCreated!).getTime()
      );
    });
  }

  @computed
  get timelineVideos(): ContentModel[] {
    return MediaUtils.getMediaForTimeline(
      this.timelineValue,
      this.organizationVideos
    );
  }

  @computed
  get videosForAddDialog() {
    return this.organizationVideos.filter(
      (v) =>
        v.title
          .toLowerCase()
          .indexOf(this.addVideoSearchQuery.toLowerCase()) !== -1
    );
  }
}
