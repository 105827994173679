import { RootStore } from "./RootStore";
import { action, computed, observable, reaction } from "mobx";

// FIXME Is this still the right naming convention?
import {
  AnalyticGraph as WatchTime,
  Lead,
  fetchLeads,
} from "../services/AnalyticsService";
import {
  fetchOrgWatchTime,
  fetchUserWatchTime,
} from "../services/AnalyticsService";
import { CommonUtils } from "../utils/CommonUtils";

export class AnalyticsStore {
  rootStore: RootStore;
  @observable error?: string;
  @observable watchTime?: WatchTime;
  @observable leads: Lead[] | null = null;

  // Mini version of AnalyticsStore for each user.
  @observable userAnalytics: {
    [userId: string]: {
      watchTime: WatchTime | undefined;
    };
  } = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const { organizationRoleStore } = rootStore;

    // When analytics gets leads, get profiles too.
    reaction(
      () => this.leads,
      (leads) => {
        if (leads && leads.length && organizationRoleStore) {
          this.rootStore!.profileStore!.fetch(
            organizationRoleStore.selectedOrganizationId,
            ...leads.map((x) => x.userId)
          );
        }
      }
    );
  }

  @action.bound
  fetchUserWatchTime(userId: string, startTime: Date, endTime: Date) {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId;

    if (!orgId) {
      throw new Error("No orgId");
    }

    let save: WatchTime | undefined;
    if (this.userAnalytics[userId]) {
      save = this.userAnalytics[userId].watchTime;
      this.userAnalytics[userId].watchTime = undefined;
    }

    fetchUserWatchTime(userId, orgId, startTime, endTime)
      .then(
        action("userWatchTimeSuccess", (r) => {
          if (userId in this.userAnalytics) {
            this.userAnalytics[userId].watchTime = r;
          } else {
            this.userAnalytics[userId] = { watchTime: r };
          }
        })
      )
      .catch(
        action("userWatchTimeFail", (r) => {
          if (save) {
            this.userAnalytics[userId].watchTime = save;
          }
        })
      );
  }

  @action.bound
  fetchOrgWatchTime(startTime: Date, endTime: Date) {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId;

    if (!orgId) {
      throw new Error("No orgId");
    }

    const save = this.watchTime;
    this.watchTime = undefined;

    fetchOrgWatchTime(orgId, startTime, endTime)
      .then(
        action("orgWatchTimeSuccess", (watchTime) => {
          this.watchTime = watchTime;
        })
      )
      .catch(
        action("orgWatchTimeFail", (e) => {
          console.error(e);
          this.watchTime = save;
        })
      );
  }

  @action.bound
  fetchLeads() {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId;

    if (!orgId) {
      throw new Error("No orgId");
    }

    fetchLeads(orgId)
      .then(
        action("leadsSuccess", (leads) => {
          this.leads = leads.data;
        })
      )
      .catch(
        action("leadsFail", (e) => {
          console.error(e);
        })
      );
  }

  @action.bound
  fetch(startTime: Date, endTime: Date, userId?: string) {
    if (userId) {
      this.fetchUserWatchTime(userId, startTime, endTime);
    } else {
      this.fetchOrgWatchTime(startTime, endTime);
      this.fetchLeads();
    }
  }

  @computed
  get leadsCsv() {
    const leads = this.leads;
    const profiles = this.rootStore.profileStore?.profiles;

    if (!leads || !profiles) {
      return null;
    }

    for (const lead of leads) {
      if (lead.userId in profiles) {
        continue;
      } else {
        return null;
      }
    }

    return [
      [
        "User Id",
        "Temperature",
        "First Name",
        "Last Name",
        "Display Name",
        "Country",
        "Email",
        "Phone Number",
        "Company",
        "Job Title",
        "Business Area",
      ],
      ...leads.map((lead) => {
        const profile = profiles[lead.userId];
        if (!profile) {
          return [];
        }

        return [
          lead.userId,
          lead.temperature,
          profile.firstName || "",
          profile.lastName || "",
          profile.displayName || "",
          CommonUtils.getCountryFromPhoneNumber(profile.phoneNumber) || "",
          profile.email || "",
          profile.phoneNumber || "",
          profile.company || "",
          profile.jobTitle || "",
          profile.businessArea || "",
        ];
      }),
    ];
  }
}
