import Model from "./Model";
import { OrganizationModel } from "./OrganizationModel";
import { action, observable } from "mobx";
import { OrganizationRoleStore } from "../stores/OrganizationRoleStore";
import { UserRole } from "../enums/user-roles";

export class OrganizationRoleModel extends Model {
  static _store: OrganizationRoleStore;
  uid!: string;
  @observable userId!: string;
  @observable timeCreated!: string;
  @observable role!: UserRole;
  @observable organisation!: OrganizationModel;

  getId(): string {
    return this.uid;
  }

  @action
  deserializeOrganisation(data: any) {
    this.organisation = OrganizationModel.fromJson(data) as OrganizationModel;
  }
}
