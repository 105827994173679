import Store from "./Store";
import { PlaylistModel } from "../models/PlaylistModel";
import { rootStore, RootStore } from "./RootStore";
import { action, computed, observable, runInAction } from "mobx";
import { playlistService } from "../services/PlaylistService";
import { contentService } from "../services/ContentService";
import { TimelineValue } from "../enums/timeline";
import { MediaUtils } from "../utils/MediaUtils";
import { CreatePlaylistDto } from "../dtos/create-playlist.dto";
import { ContentModel } from "../models/ContentModel";
import { uniq } from "lodash";
import { uploadService } from "../services/UploadService";
import { organizationService } from "../services/OrganizationService";

export class PlaylistStore extends Store<PlaylistModel> {
  @observable isPlaylistLoading = false;
  @observable isPlaylistUpdating = false;
  @observable isPlaylistLoaded = false;
  @observable isVideosLoading = false;
  @observable currentSelectedPlaylistId?: string;
  @observable playlistVideos: { [playlistId: string]: ContentModel[] } = {};
  @observable playlistVideosFirstLoad: { [playlistId: string]: boolean } = {};
  @observable timelineValue = TimelineValue.TODAY;
  @observable lastLoadedId = "";
  @observable isOnLastPage = false;
  @observable isPlaylistPinning: { [id: string]: boolean } = {};

  constructor(private rootStore: RootStore) {
    super();
    PlaylistModel._store = this;
  }

  @action
  async listPlaylists() {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    if (this.isPlaylistLoading || this.isPlaylistLoaded) {
      return;
    }
    try {
      this.isPlaylistLoading = true;
      const playlists = await playlistService.listPlaylists(orgId);
      runInAction(() => {
        if (playlists && playlists.length > 10) {
          this.lastLoadedId = playlists[playlists.length - 1].uid;
        } else {
          this.isOnLastPage = true;
        }
        this.isPlaylistLoading = false;
        this.isPlaylistLoaded = true;
      });
    } catch (e) {
      runInAction(() => {
        this.isPlaylistLoading = false;
        throw e;
      });
    }
  }

  @action
  async fetchPlaylistVideos(playlistId: string) {
    if (this.playlistVideosFirstLoad[playlistId]) {
      return;
    }
    try {
      this.isVideosLoading = true;
      const videoList = await contentService.listPlaylistContent(playlistId);
      runInAction(() => {
        this.playlistVideos[playlistId] = [...videoList];
        this.playlistVideosFirstLoad[playlistId] = true;
        this.isVideosLoading = false;
      });
    } catch (e) {
      this.isVideosLoading = false;
      throw e;
    }
  }

  @action
  async listMorePlaylists() {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    if (this.isPlaylistLoading || this.isOnLastPage || !this.lastLoadedId) {
      return;
    }
    try {
      const playlists = await playlistService.listPlaylists(orgId, {
        startAfter: this.lastLoadedId,
      });
      runInAction(() => {
        if (
          playlists.length === 0 ||
          this.lastLoadedId === playlists[playlists.length - 1].uid
        ) {
          this.isOnLastPage = true;
        }
      });
    } catch (e) {
      runInAction(() => {
        throw e;
      });
    }
  }

  @action
  async getPlaylistById(id: string): Promise<PlaylistModel | undefined> {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    if (!orgId) {
      return;
    }
    if (this.get(id)) {
      return;
    }
    try {
      this.isVideosLoading = true;
      await playlistService.fetchPlaylistById(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.isVideosLoading = false;
      });
    }
  }

  @action
  async createPlaylist(
    data: CreatePlaylistDto,
    notifyUsers: boolean,
    thumbnail?: File | null
  ): Promise<PlaylistModel> {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    // Upload thumbnail if one was provided
    let { previewUrl } = data;
    if (thumbnail) {
      previewUrl = await uploadService.uploadFile(thumbnail, orgId);
    }
    try {
      return await playlistService.createPlaylist(
        orgId,
        {
          ...data,
          previewUrl,
        },
        notifyUsers
      );
    } catch (error) {
      throw error;
    }
  }

  @action
  async addVideosToPlaylist(videos: ContentModel[], playlistId: string) {
    if (!videos || videos.length <= 0) {
      return;
    }
    const playlist = this.get(playlistId);
    if (!playlist) {
      return;
    }
    const oldVideoIds = playlist.items;
    const videoIds = videos.map((v) => v.uid);
    try {
      await playlistService.updatePlaylist(playlistId, {
        items: uniq([...oldVideoIds, ...videoIds]),
      });
      runInAction(() => {
        this.playlistVideos[playlistId] = [
          ...(this.playlistVideos[playlistId] || []),
          ...videos,
        ];
      });
    } catch (error) {
      throw error;
    }
  }

  @action
  async updatePlaylist(
    playlistId: string,
    updates: Partial<PlaylistModel>,
    thumbnail?: File | null
  ) {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    try {
      this.isPlaylistUpdating = true;
      let { previewUrl } = updates;
      if (thumbnail) {
        previewUrl = await uploadService.uploadFile(thumbnail, orgId);
      }
      if (previewUrl) {
        updates = {
          ...updates,
          previewUrl,
        };
      }
      const playlist = await playlistService.updatePlaylist(playlistId, {
        ...updates,
      });
      runInAction(() => {
        this.playlistVideos[playlistId] = playlist.items.map(
          (e) => rootStore.contentStore!.get(e)!
        );
        this.isPlaylistUpdating = false;
      });
    } catch (error) {
      this.isPlaylistUpdating = false;
      throw error;
    }
  }

  @action
  setTimelineValue(value: TimelineValue) {
    this.timelineValue = value;
  }

  @action
  async deletePlaylist(id: string) {
    try {
      await playlistService.deletePlaylist(id);
      this.entities = this.entities.filter((p) => p.id !== id);
    } catch (e) {
      throw e;
    }
  }

  @action
  async togglePinPlaylist(playlistId: string) {
    try {
      const orgId =
        this.rootStore.organizationRoleStore?.selectedOrganizationId!;
      let pinnedPlaylists =
        this.rootStore.organizationRoleStore?.selectedOrganization
          .pinnedPlaylists || [];
      if (pinnedPlaylists.includes(playlistId)) {
        pinnedPlaylists = pinnedPlaylists.filter((id) => id !== playlistId);
      } else {
        pinnedPlaylists = [...pinnedPlaylists, playlistId];
      }
      this.isPlaylistPinning = {
        ...this.isPlaylistPinning,
        [playlistId]: true,
      };
      await organizationService.updateOrganization(orgId, {
        pinnedPlaylists,
      });
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.isPlaylistPinning = {
          ...this.isPlaylistPinning,
          [playlistId]: false,
        };
      });
    }
  }

  @action
  async reorderPlaylists(playlistIds: string[]) {
    try {
      const orgId =
        this.rootStore.organizationRoleStore?.selectedOrganizationId!;
      await organizationService.updateOrganization(orgId, {
        pinnedPlaylists: playlistIds,
      });
    } catch (err) {
      throw err;
    }
  }

  @computed
  get organizationPlaylists(): PlaylistModel[] {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;
    return (
      this.entities
        .slice()
        // Ensure only currently selected organisation playlists are returned
        .filter((val) => val.orgId === orgId)
        // Sort them in ascending order
        .sort((p1, p2) => {
          const t1 = p1.timeModified || p1.timeCreated;
          const t2 = p2.timeModified || p2.timeCreated;
          return new Date(t1).getTime() - new Date(t2).getTime();
        })
    );
  }

  @computed
  get timelinePlaylists(): PlaylistModel[] {
    return MediaUtils.getMediaForTimeline(
      this.timelineValue,
      this.organizationPlaylists
    );
  }

  /**
   * ready - returns true if the current user has no playlists. Returns undefined when loading.
   */
  @computed
  get hasNoPlaylists() {
    if (this.isPlaylistLoading) {
      return undefined;
    }

    return this.organizationPlaylists?.length === 0;
  }

  @computed
  get pinnedPlaylists(): PlaylistModel[] {
    return this.rootStore.organizationRoleStore!.selectedOrganization.pinnedPlaylists.map(
      (playlistId) =>
        this.entities.find((playlist) => playlist.uid === playlistId)!
    );
  }

  @computed
  get pinnedPlaylistsIds(): string[] {
    return this.rootStore.organizationRoleStore!.selectedOrganization
      .pinnedPlaylists;
  }

  @computed
  get pinnedPlaylistCount(): number {
    return (
      this.rootStore.organizationRoleStore?.selectedOrganization.pinnedPlaylists
        .length || 0
    );
  }
}
