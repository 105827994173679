import { action, observable } from "mobx";
import { KonnekAppThemes } from "../enums/themes";
import { RootStore } from "./RootStore";

export class UiStore {
  @observable activeTheme: KonnekAppThemes = KonnekAppThemes.LIGHT;

  @action switchTheme(theme: KonnekAppThemes) {
    this.activeTheme = theme;
    this.addThemeClass();
  }

  constructor(private rootStore: RootStore) {
    this.addThemeClass();
  }

  addThemeClass() {
    document.documentElement.className = "";
    document.documentElement.classList.add(`theme-${this.activeTheme}`);
  }
}
