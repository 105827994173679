import Store from "./Store";
import { OrganizationRoleModel } from "../models/OrganizationRoleModel";
import { RootStore } from "./RootStore";
import { action, computed, observable, runInAction } from "mobx";
import { UserRole, compareRoles } from "../enums/user-roles";
import { OrganizationModel } from "../models/OrganizationModel";
import { organizationService } from "../services/OrganizationService";

export class OrganizationRoleStore extends Store<OrganizationRoleModel> {
  @observable isOrganizationRolesLoading = false;
  @observable isOrganizationRolesLoaded = false;
  @observable selectedOrganizationId!: string;

  constructor(private rootStore: RootStore) {
    super();
    OrganizationRoleModel._store = this;
  }

  @action
  async listUserOrganizations() {
    if (!this.isOrganizationRolesLoading && !this.isOrganizationRolesLoaded) {
      try {
        await this.loadUserOrganizations();
      } catch (error) {
        runInAction(() => {
          this.isOrganizationRolesLoaded = false;
          this.isOrganizationRolesLoading = false;
        });
        throw error;
      }
    }
  }

  @action
  async listUserOrganizationsWithDelay(delay = 100) {
    try {
      await this.loadUserOrganizations();
    } catch (error) {
      if ((error as any).status === 404) {
        return setTimeout(() => {
          this.listUserOrganizationsWithDelay(delay * 2);
        }, delay);
      } else {
        runInAction(() => {
          this.isOrganizationRolesLoaded = false;
          this.isOrganizationRolesLoading = false;
        });
        throw error;
      }
    }
  }

  @action
  setSelectedOrganizationId(orgId?: string) {
    const id = orgId ?? "";
    const orgs = this.entities
      .filter((e) => e.role !== UserRole.USER)
      .map((r) => r.organisation);
    if (orgs.map((o) => o.uid).includes(id)) {
      this.selectedOrganizationId = id;
    } else {
      this.selectedOrganizationId = orgs[0].uid;
    }
    this.rootStore.resetStoreOnOrgSwitch();
  }

  @action
  private async loadUserOrganizations() {
    this.isOrganizationRolesLoading = true;
    await organizationService.listUserOrganizations();
    runInAction(() => {
      this.isOrganizationRolesLoaded = true;
      this.isOrganizationRolesLoading = false;
    });
  }

  @computed
  get roleSelection(): OrganizationRoleModel[] {
    return this.entities.reduce<OrganizationRoleModel[]>((acc, c) => {
      // Do not allow user roles to be picked on staff portal
      if (c.role === UserRole.USER) return acc;

      // Do not display more than one role per organisaion
      const idx = acc.findIndex(
        (role) => role.organisation.uid === c.organisation.uid
      );
      if (idx > 0) {
        const { role: compareRole } = acc[idx];

        // If higher tier role, replace
        if (compareRoles(c.role, compareRole)) {
          acc[idx] = c;
        }

        return acc;
      }

      acc.push(c);
      return acc;
    }, []);
  }

  @computed
  get hasAccessToDashboard(): boolean {
    return this.roleSelection.length > 0;
  }

  @computed
  get selectedOrgUserRole(): UserRole {
    const orgId = this.selectedOrganizationId;
    const role = this.roleSelection.filter((e) => {
      return e.organisation.uid === orgId;
    })[0];
    return role && role.role;
  }

  @computed
  get selectedOrganization(): OrganizationModel {
    return this.roleSelection.filter(
      (e) => e.organisation.uid === this.selectedOrganizationId
    )[0]?.organisation;
  }
}
