import { z } from "zod";
import { Privacy } from "../enums/privacy";

export interface CreateVideoDto {
  url: string | null;
  title: string;
  description: string;
  previewUrl: string | null;
  privacy: Privacy;
}

export const ContentSchema = z.object({
  title: z.string().nonempty("Title is required"),
  description: z.string().nullish(),
  playlistId: z.string().nullish(),
});
