import { Box, Image } from "@chakra-ui/react";
import React from "react";

const AuthLayout = (props: any) => {
  const { children } = props;
  return (
    <Box display="flex" height="100vh">
      <Box width="45%" display={{ base: "none", md: "block" }}>
        <Image
          position="absolute"
          m="40px"
          src="/assets/icons/konnek-brand.png"
          width="150px"
          fit="contain"
          alt="Auth image"
        />
        <Image
          boxSize="100%"
          fit="cover"
          src="/assets/images/auth-image.svg"
          alt="Auth image"
        />
      </Box>
      <Box width={{ sm: "100%", md: "55%" }} padding="7%" margin="auto">
        <Image
          src="/assets/icons/konnek-brand-black.png"
          width="180px"
          fit="contain"
          alt="Auth image"
          mb="3rem"
        />
        {children}
      </Box>
    </Box>
  );
};

export default AuthLayout;
