import { TimelineValue } from "../enums/timeline";
import moment from "moment";
import { PlaylistModel } from "../models/PlaylistModel";
import { ContentModel } from "../models/ContentModel";

export class MediaUtils {
  static getMediaForTimeline<T extends PlaylistModel | ContentModel>(
    timeline: TimelineValue,
    data: T[]
  ): T[] {
    let timelineData: T[] = [];
    if (!data || !data.length) {
      return timelineData;
    }
    timelineData = data.filter((d) => {
      return moment().diff(moment(d.timeCreated), timeline) < 1;
    });
    return timelineData;
  }
}
