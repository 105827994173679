import { UserRole } from "../enums/user-roles";
import { AccessPolicy, RolePermission } from "../interface/access-policy";
import { OrganizationPolicy } from "../enums/access-policy";

export class RolesUtils {
  private static readonly ACCESS_POLICY: AccessPolicy = {
    [UserRole.USER]: {
      extends: null,
      permissions: [
        OrganizationPolicy.ORGANIZATION_READ,
        OrganizationPolicy.PLAYLIST_READ,
        OrganizationPolicy.PLAYLIST_LIST,
        OrganizationPolicy.PLAYLIST_CONTENT_LIST,
        OrganizationPolicy.ORGANIZATIONS_USERS_CONSENT_UPDATE,
      ],
    },
    [UserRole.TEAM_USER]: {
      extends: UserRole.USER,
      permissions: [
        OrganizationPolicy.PLAYLIST_LIST,
        OrganizationPolicy.ORGANIZATION_CONTENT_LIST,
        OrganizationPolicy.ORGANIZATION_CONTENT_READ,
        OrganizationPolicy.ORGANIZATIONS_ANALYTICS,
      ],
    },
    [UserRole.TEAM_LEADER]: {
      extends: UserRole.TEAM_USER,
      permissions: [
        OrganizationPolicy.PLAYLIST_CREATE,
        OrganizationPolicy.PLAYLIST_UPDATE,
        OrganizationPolicy.PLAYLIST_DELETE,
        OrganizationPolicy.ORGANIZATION_ROLES_LIST,
        OrganizationPolicy.ORGANIZATION_ROLES_CREATE,
        OrganizationPolicy.ORGANIZATION_ROLES_DELETE,
        OrganizationPolicy.ORGANIZATIONS_USERS_CONSENT_LIST,
        OrganizationPolicy.ORGANIZATION_CONTENT_CREATE,
        OrganizationPolicy.ORGANIZATION_CONTENT_UPDATE,
        OrganizationPolicy.ORGANIZATION_CONTENT_DELETE,
      ],
    },
    [UserRole.MANAGER]: {
      extends: UserRole.TEAM_LEADER,
      permissions: [],
    },
    [UserRole.ACCOUNT_ADMIN]: {
      extends: UserRole.MANAGER,
      permissions: [
        // organisations.billing
        OrganizationPolicy.ORGANIZATIONS_USERS_LIST,
        OrganizationPolicy.ORGANIZATION_UPDATE,
      ],
    },
    [UserRole.SUPER_ADMIN]: {
      extends: UserRole.ACCOUNT_ADMIN,
      permissions: [],
    },
    // [UserRole.ROOT]: {
    //     extends: UserRole.SUPER_ADMIN,
    //     permissions: [
    //         OrganizationPolicy.ORGANIZATION_LIST,
    //         OrganizationPolicy.ORGANIZATION_CREATE,
    //         OrganizationPolicy.ORGANIZATION_DELETE,
    //         OrganizationPolicy.ORGANIZATION_ROLES_ADMIN_LIST,
    //         OrganizationPolicy.ORGANIZATION_ROLES_ADMIN_CREATE,
    //         OrganizationPolicy.ORGANIZATION_ROLES_ADMIN_DELETE
    //     ],
    // }
  };

  private static readonly ROLE_WEIGHT: { [id: string]: number } = {
    [UserRole.SUPER_ADMIN]: 100,
    [UserRole.ACCOUNT_ADMIN]: 20,
    [UserRole.MANAGER]: 12,
    [UserRole.TEAM_LEADER]: 11,
    [UserRole.TEAM_USER]: 10,
    [UserRole.USER]: 0,
  };

  static isAccessAllowed(role: UserRole, permission: string): boolean {
    let res: boolean;
    let parentRole;
    if (!role) {
      return false;
    }
    do {
      const rolePolicy: RolePermission =
        RolesUtils.ACCESS_POLICY[parentRole || role];
      if (!rolePolicy) {
        res = false;
        break;
      }
      if (rolePolicy.permissions.includes(permission)) {
        res = true;
        break;
      }
      parentRole = rolePolicy.extends;
    } while (parentRole);
    return res!;
  }

  static compareRoles(baseRole: UserRole, compareRole: UserRole): boolean {
    return RolesUtils.ROLE_WEIGHT[baseRole] > this.ROLE_WEIGHT[compareRole];
  }
}
