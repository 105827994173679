import React from "react";
import { GlobalProps } from "../../App";
import { firebaseService } from "../../services/FirebaseService";
import { useHistory } from "react-router";
import { inject } from "mobx-react";

export interface LogoutContextType {
  logout?: () => void;
}

export const LogoutContext = React.createContext<LogoutContextType>({});

const LogoutProvider: React.FC<GlobalProps> = (props: GlobalProps) => {
  const history = useHistory();
  const logout = async () => {
    await firebaseService.logout();
    props.store!.resetStoreOnLogout();
    history.push("/login");
  };
  return (
    <LogoutContext.Provider value={{ logout }}>
      {(props as any).children}
    </LogoutContext.Provider>
  );
};

export default inject("store")(LogoutProvider);
