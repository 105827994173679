/**
 * Authenticated ROUTE GUARD:
 * Takes a component and route path as a prop.
 *
 * Route guard for accessing all the private routes which can only be accessed
 * when user is authenticated. For ex. Home.
 * Checks if the user is logged in from the store. If the user is logged in, it allows the
 * navigation else redirects it to the login component.
 *
 */

import React from "react";
import { Redirect, Route, useHistory } from "react-router";
import { inject } from "mobx-react";

const AuthenticatedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const rootStore = rest.store;
  const authStore = rootStore.authStore;
  const history = useHistory();
  const redirectUrl = history.location.pathname + history.location.search;
  if (!authStore.isLoggedIn) {
    rest.store.redirectUrl = redirectUrl;
    return (
      <Route {...rest}>
        <Redirect to="/login" />
      </Route>
    );
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default inject("store")(AuthenticatedRoute);
