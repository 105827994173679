import Store from "./Store";
import { action, computed, observable, runInAction } from "mobx";
import { userService } from "../services/UserService";
import { RootStore } from "./RootStore";
import { CommonUtils } from "../utils/CommonUtils";
import { UserConsentModel } from "../models/UserConsentModel";

export class UserConsentStore extends Store<UserConsentModel> {
  @observable isUsersLoading = false;
  @observable searchQuery = "";
  @observable isUsersLoaded = false;

  constructor(private rootStore: RootStore) {
    super();
    UserConsentModel._store = this;
  }

  @action
  setUserSearchQuery(query: string) {
    this.searchQuery = query;
  }

  @action
  async listUsers() {
    const orgId = this.rootStore.organizationRoleStore?.selectedOrganizationId!;

    if (this.isUsersLoading || this.isUsersLoaded) {
      return;
    }
    try {
      this.isUsersLoading = true;
      const data = await userService.getUsersConsentList(orgId);
      runInAction(() => {
        this.addAll(data);
        this.isUsersLoading = false;
        this.isUsersLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.isUsersLoading = false;
      });
    }
  }

  @computed
  get displayUsersConsent(): UserConsentModel[] {
    return this.entities.filter((e) => {
      const name = CommonUtils.getUserDisplayDetail(e.user);
      return name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1;
    });
  }
}
