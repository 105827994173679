import { apiService } from "./ApiService";
import { loadStripe } from "@stripe/stripe-js";
import { CreateBillingAccountDto } from "../dtos/create-billing-account.dto";
import { AccountProvision } from "../interface/account-provision";
import { BusinessPlan } from "../enums/business-plan";
import { BillingAccountModel } from "../models/BillingAccountModel";

class StripeService {
  private stripe: any;

  static getInstance(): StripeService {
    return new StripeService();
  }

  private constructor() {
    this._initializeStripe();
  }

  async redirectToCheckout(accountId: string, planId: BusinessPlan) {
    if (!this.stripe || !accountId) {
      return;
    }
    try {
      const session: any = await this.createCheckoutSession(accountId, planId);
      await this.stripe.redirectToCheckout({
        sessionId: session.id,
      });
    } catch (error) {
      throw error;
    }
  }

  async listProvisions(accountId: string): Promise<AccountProvision[]> {
    return await apiService.get<AccountProvision[]>(
      `/accounts/${accountId}/provisions`
    );
  }

  async createCheckoutSession(accountId: string, planId: BusinessPlan) {
    return await apiService.post(`/accounts/${accountId}/checkout`, { planId });
  }

  async listBillingAccounts(): Promise<BillingAccountModel[]> {
    return await apiService
      .get<BillingAccountModel[]>("/accounts")
      .then((res) =>
        res.map(
          (acc) => BillingAccountModel.fromJson(acc) as BillingAccountModel
        )
      );
  }

  async createBillingAccounts(
    billingAccount: CreateBillingAccountDto
  ): Promise<BillingAccountModel> {
    return await apiService
      .post<any>("/accounts", billingAccount)
      .then((res) => BillingAccountModel.fromJson(res) as BillingAccountModel);
  }

  async generateBillingManagementSession(accountId: string) {
    return await apiService.post(`/accounts/${accountId}/manage`, {});
  }

  private async _initializeStripe() {
    this.stripe = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
    );
  }
}

export const stripeService = StripeService.getInstance();
