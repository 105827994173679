import React from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

interface MagicLinkDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSignInManually: () => void;
  resendMagicLink: () => void;
}

const MagicLinkDialog: React.FC<MagicLinkDialogProps> = (
  props: MagicLinkDialogProps
) => {
  const { isOpen, onClose, onSignInManually, resendMagicLink } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box
          pos="relative"
          borderRadius="8px 8px 0 0"
          bg="primary.100"
          height="180px"
          mb="52px"
        >
          <Center>
            <Image
              pos="absolute"
              top="60%"
              src="/assets/images/magic-link.png"
            />
          </Center>
        </Box>
        <Box p="12px">
          <VStack spacing={3}>
            <>
              <Heading fontSize="xl">To Sign in, we emailed you a</Heading>
              <Heading fontSize="xl" color="brand">
                Magic Link
              </Heading>
            </>
            <Center>
              <Text fontSize="sm" textAlign="center" color="gray.500">
                Didn't receive it in your inbox or junk, try adding: <br />
                <Text display="inline" color="black">
                  noreply@konnek-platform.firebaseapp.com
                </Text>{" "}
                to your email contacts list then
              </Text>
            </Center>
            <>
              <Button
                variant="ghost"
                colorScheme="primary"
                size="sm"
                onClick={resendMagicLink}
              >
                Resend Magic Link
              </Button>
              <Button
                variant="ghost"
                colorScheme="primary"
                size="sm"
                onClick={onSignInManually}
              >
                or, Sign in manually
              </Button>
            </>
          </VStack>
        </Box>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default MagicLinkDialog;
