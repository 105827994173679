import Store from "./Store";
import { BillingAccountModel } from "../models/BillingAccountModel";
import { action, observable, runInAction } from "mobx";
import { stripeService } from "../services/StripeService";

export class BillingAccountStore extends Store<BillingAccountModel> {
  @observable isLoading = false;

  constructor() {
    super();
    BillingAccountModel._store = this;
  }

  @action
  async fetchBillingAccounts() {
    if ((this.entities && this.entities.length > 0) || this.isLoading) {
      return;
    }
    try {
      this.isLoading = true;
      await stripeService.listBillingAccounts();
      runInAction(() => {
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
      });
      throw err;
    }
  }
}
