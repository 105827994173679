import React from "react";
import OnboardingCompanyDetailsPage from "../components/OnboardingCompanyDetailsPage/OnboardingCompanyDetailsPage";
import { GlobalProps } from "../../App";
import { inject, observer } from "mobx-react";
import useSnackBar from "../../hooks/useSnackBar";
import { useHistory } from "react-router";
import { HttpError } from "../../services/ApiService";

const OnboardingCompanyDetailsView: React.FC<GlobalProps> = (
  props: GlobalProps
) => {
  const { store } = props;
  const user = store?.authStore?.loggedInUser!;
  const notify = useSnackBar();
  const history = useHistory();
  const updateUserProfile = async (value: any) => {
    try {
      await store?.authStore?.updateLoggedInUser(value);
      history.push(store?.redirectUrl!);
    } catch (error) {
      notify("error", (error as HttpError).message);
    }
  };
  return (
    <OnboardingCompanyDetailsPage user={user} onUpdate={updateUserProfile} />
  );
};

export default inject("store")(observer(OnboardingCompanyDetailsView));
