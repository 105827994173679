import { apiService } from "./ApiService";
import { User, UserModel, UserSchema } from "../models/UserModel";
import { UserConsentModel } from "../models/UserConsentModel";
import { UpdateUserDto } from "../dtos/update-user.dto";
import { UserRoleModel } from "../models/UserRoleModel";
import { UserWatchHistory } from "../interface/user-watch-history";
import { z } from "zod";

class UserService {
  static getInstance(): UserService {
    return new UserService();
  }

  // FIXME Handle 401 by logging out
  async getUserDetails(): Promise<UserModel> {
    return await apiService
      .get("/profiles")
      .then((res) => UserModel.fromJson(res) as UserModel);
  }

  async updateUserDetails(user: UpdateUserDto): Promise<UserModel> {
    return await apiService
      .patch("/profiles", { user })
      .then((res) => UserModel.fromJson(res) as UserModel);
  }

  async getUsersConsentList(orgId: string): Promise<UserConsentModel[]> {
    return await apiService
      .get<any[]>(`/organizations/${orgId}/users/consent`)
      .then((res) =>
        res.map((role) => UserConsentModel.fromJson(role) as UserConsentModel)
      );
  }

  async getStaffUsersList(orgId: string): Promise<UserRoleModel[]> {
    return await apiService
      .get<any[]>(`/organizations/${orgId}/users/staff`)
      .then((res) =>
        res.map((role) => UserRoleModel.fromJson(role) as UserRoleModel)
      );
  }

  async getUserHistory(
    timeStart: string,
    timeEnd: string,
    orgId: string,
    userId: string
  ): Promise<UserWatchHistory[]> {
    return await apiService.get<UserWatchHistory[]>(
      `/organizations/${orgId}/users/${userId}/history`,
      {
        timeStart,
        timeEnd,
      }
    );
  }

  async getUsers(orgId: string, ids: string[]): Promise<User[]> {
    if (!ids.length) {
      throw new Error("UsersService: must request at least one user.");
    }

    return apiService
      .get(`/profiles/${ids.join(";")}?orgId=${orgId}`)
      .then((r) => {
        return z
          .object({
            data: z.array(UserSchema),
          })
          .parseAsync(r);
      })
      .catch((err: any) => {
        console.error(err);
        return Promise.reject(err);
      })
      .then((data) => Promise.resolve(data.data));
  }
}

export const userService = UserService.getInstance();
