import React from "react";
import { Button } from "@chakra-ui/button";
import { omit } from "lodash";

interface ButtonProps extends React.ComponentProps<typeof Button> {
  primary?: boolean;
  secondary?: boolean;
}

export default React.forwardRef((props: ButtonProps, ref) => {
  let style: any = {
    px: "6",
    py: "5",
    fontWeight: "semibold",
  };

  if (props.primary) {
    style = { ...style, colorScheme: "primary" };
  } else if (props.secondary) {
    style = {
      ...style,
      color: "primary.600",
      background: "primary.50",
      _hover: {
        background: "primary.100",
      },
    };
  }

  const propsWithoutExtras = omit(props, ["primary", "secondary"]);

  return (
    <Button ref={ref} {...style} {...propsWithoutExtras}>
      {props.children}
    </Button>
  );
});
