import Model from "./Model";
import { BillingAccountStore } from "../stores/BillingAccountStore";
import { EntityIdentifier } from "../stores/Store";
import { observable } from "mobx";

export class BillingAccountModel extends Model {
  static _store: BillingAccountStore;
  uid!: string;
  @observable billingAddress!: string;
  @observable billingEmail!: string;
  @observable billingName!: string;
  @observable billingPhone!: string;
  @observable friendlyName!: string;
  @observable managingUsers!: string[];
  @observable timeCreated!: string;

  getId(): EntityIdentifier {
    return this.uid;
  }
}
