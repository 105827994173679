import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import DeleteButton from "../delete-button/DeleteButton";

interface DeleteConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  title?: string;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const _delete = async () => {
    setIsLoading(true);
    await props.onDelete();
    props.onClose();
    setIsLoading(false);
  };

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
      isOpen={props.isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent p="40px 20px" borderRadius="21px">
        <AlertDialogHeader>Confirmation</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text fontSize="sm">
            Are you sure you want to delete <b>{props.title}</b>? This action
            cannot be undone.
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={props.onClose} mr="12px">
            Cancel
          </Button>
          <DeleteButton onClick={_delete}>Yes, Delete</DeleteButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteConfirmationDialog;
