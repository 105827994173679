import React from "react";
import { Box, Flex, Heading, Text, Stack } from "@chakra-ui/react";

export interface AppLayoutProps {
  colorScheme: "primary" | "secondary";
  startLogo: React.ReactElement;
  title: string;
  description: React.ReactElement;
  action?: React.ReactElement;
  children?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = (props: AppLayoutProps) => {
  return (
    <Box>
      <Box
        padding="2.8rem"
        minH={{ base: "100px", md: "190px" }}
        backgroundColor={`${props.colorScheme}.100`}
        color={`${props.colorScheme}.500`}
        borderRadius="0 0 22px 22px"
      >
        <Flex
          direction={{ base: "column", lg: "row" }}
          justify="space-between"
          alignItems={{ base: "flex-start", lg: "center" }}
        >
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={4}
            alignItems={{ base: "flex-start", lg: "center" }}
          >
            {props.startLogo}
            <Box width="90%">
              <Heading fontSize="4xl">{props.title}</Heading>
              <Text mt="12px" fontSize="xs">
                {props.description}
              </Text>
            </Box>
          </Stack>
          <Box mt={{ base: "10px", lg: "0" }}>{props.action}</Box>
        </Flex>
      </Box>
      <Box p={{ base: "7", lg: "3%" }}>{props.children}</Box>
    </Box>
  );
};

export default AppLayout;
