import { EntityIdentifier } from "../stores/Store";
import Model from "./Model";
import { UserStore } from "../stores/UserStore";
import { observable } from "mobx";
import { z } from "zod";

export const UserSchema = z.object({
  uid: z.string(),
  displayName: z.string().nullish(),
  email: z.string(),
  phoneNumber: z.string(),
  photoURL: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  company: z.string(),
  linkedIn: z.string().optional(),
  address: z.string().optional(),
  jobTitle: z.string().optional(),
  businessArea: z.string().optional(),
  timeCreated: z.preprocess((a: any) => new Date(a), z.date()),
});

export type User = z.infer<typeof UserSchema>;

export type UserLike = {
  displayName?: string | null;
  firstName: string;
  lastName: string;
  email?: string;
};

export class UserModel extends Model {
  static _store: UserStore;

  uid!: string;
  @observable displayName!: string;
  @observable email!: string;
  @observable phoneNumber!: string;
  @observable photoURL!: string;
  @observable firstName!: string;
  @observable lastName!: string;
  @observable company!: string;
  @observable linkedIn?: string;
  @observable address?: string;
  @observable jobTitle?: string;
  @observable businessArea?: string;
  @observable timeCreated?: string;

  getId(): EntityIdentifier {
    return this.uid;
  }
}
