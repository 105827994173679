import React from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ImCancelCircle } from "react-icons/all";
import { EnumUtils } from "../../../utils/EnumUtils";
import { BusinessPlan } from "../../../enums/business-plan";

export interface BillingCancelledPageProps {
  onRetry: () => void;
  plan: BusinessPlan;
}

const BillingCancelledPage: React.FC<BillingCancelledPageProps> = (
  props: BillingCancelledPageProps
) => {
  return (
    <Box backgroundColor="#232323" height="100vh">
      <Center>
        <Image my="20px" width="150px" src="/assets/icons/konnek-brand.png" />
      </Center>
      <Center>
        <Box
          backgroundColor="white"
          width={{ base: "90%", lg: "25%" }}
          mt="3rem"
          p="2.5rem 3rem"
          borderRadius="xl"
        >
          <VStack spacing={4}>
            <Icon boxSize={12} as={ImCancelCircle} color="error" />
            <Text color="error" fontSize="lg">
              Payment Failed for
            </Text>
            <Heading fontSize="lg">
              {EnumUtils.getPlanTitle(props.plan)}
            </Heading>
            <Divider />
            <Text fontSize="xs" color="gray" textAlign="center">
              There were some error in the payment. Please check with your
              partner bank.
            </Text>
            <Button colorScheme="primary" onClick={props.onRetry}>
              Retry Payment
            </Button>
          </VStack>
        </Box>
      </Center>
    </Box>
  );
};

export default BillingCancelledPage;
