import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CommonUtils } from "../../utils/CommonUtils";
import { InviteUserRole, UserRole } from "../../enums/user-roles";
import useSnackBar from "../../hooks/useSnackBar";
import { organizationService } from "../../services/OrganizationService";
import { HttpError } from "../../services/ApiService";
import { EnumUtils } from "../../utils/EnumUtils";

const menuOptions = CommonUtils.iterateEnum(InviteUserRole).reduce(
  (accumulator, role) => {
    return [
      ...accumulator,
      <option value={role}>{EnumUtils.getUserRoleTitle(role)}</option>,
    ];
  },
  []
);
export interface InviteUserDialogProps {
  orgId: string;
  onClose: () => void;
}

const InviteUserDialog: React.FC<InviteUserDialogProps> = (
  props: InviteUserDialogProps
) => {
  const { orgId, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<UserRole>(UserRole.SUPER_ADMIN);
  const [error, setError] = useState<string | null>();
  const notify = useSnackBar();

  const inviteUser = async () => {
    if (!CommonUtils.validateEmail(email!)) {
      return setError("Invalid email!");
    }
    setError(null);
    setIsLoading(true);
    try {
      await organizationService.assignRole(orgId, {
        userEmail: email,
        role: role!,
      });
      notify("success", "Invite sent successfully!");
      onClose();
    } catch (error) {
      setIsLoading(false);
      setError((error as HttpError).message);
    }
  };
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box
          pos="relative"
          borderRadius="8px 8px 0 0"
          bg="primary.100"
          height="180px"
          mb="52px"
        >
          <Center>
            <Image
              pos="absolute"
              top="60%"
              src="/assets/images/invite-user.png"
            />
          </Center>
        </Box>
        <Box p="12px" mt="20px">
          <Center>
            <Heading fontSize="2xl"> Invite</Heading>
            <Heading display="inline" ml="4px" fontSize="2xl" color="brand">
              {" "}
              KonnekApp Portal Admin Users
            </Heading>
          </Center>
          <Center>
            <Text fontSize="xs" color="gray" textAlign="center" mt="12px">
              Admin Users help you manage your KonnekApp Portal and App. Be
              careful giving access to anyone outside your organization.
            </Text>
          </Center>
          <VStack spacing={3} mt="22px">
            <Box w="100%">
              <Input
                variant=""
                color="brand"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                backgroundColor="primary.100"
              />
              {!!error && (
                <Text color="error" mt="4px" fontSize="xs">
                  {error}
                </Text>
              )}
            </Box>
            <Box w="100%">
              <Select
                variant=""
                value={role}
                color="brand"
                onChange={(e) => setRole(e.target.value as UserRole)}
                backgroundColor="primary.100"
              >
                {menuOptions}
              </Select>
            </Box>

            {isLoading ? (
              <Spinner color="brand" size="lg" />
            ) : (
              <Button colorScheme="primary" onClick={inviteUser}>
                Send
              </Button>
            )}
          </VStack>
        </Box>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default InviteUserDialog;
