import React from "react";
import { Box, Button, Heading, Spinner, Text } from "@chakra-ui/react";
import { Formik } from "formik";
import TextField from "../../../shared/textfield/TextField";
import OnboardingLayout from "../../Layout";
import { UserModel } from "../../../models/UserModel";
import { CommonUtils } from "../../../utils/CommonUtils";
import PrimarySelect from "../../../shared/primary-select/PrimarySelect";

export interface OnboardingCompanyDetailsPageProps {
  user: UserModel;
  onUpdate: (values: any) => void;
}

const OnboardingCompanyDetailsPage: React.FC<
  OnboardingCompanyDetailsPageProps
> = (props: OnboardingCompanyDetailsPageProps) => {
  const { user, onUpdate } = props;
  return (
    <OnboardingLayout>
      <Heading fontSize="5xl">You are almost there!</Heading>
      <Text mt="16px" mb="44px" color="gray" fontSize="xs">
        Tell us about your company.
      </Text>
      <Formik
        initialValues={{
          company: user.company || "",
          jobTitle: user.jobTitle || "",
          businessArea: user.businessArea || "",
        }}
        onSubmit={onUpdate}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <TextField
              type="text"
              name="company"
              onChange={formProps.handleChange}
              placeholder="Company"
              value={formProps.values.company}
            />
            <PrimarySelect
              name="jobTitle"
              placeholder="Job title"
              onChange={formProps.handleChange}
              value={formProps.values.jobTitle}
              options={CommonUtils.getJobTitles()}
              displayOption={(job, index) => (
                <option key={job} value={job}>
                  {job}
                </option>
              )}
            />
            <PrimarySelect
              name="businessArea"
              placeholder="Business area"
              onChange={formProps.handleChange}
              value={formProps.values.businessArea}
              options={CommonUtils.getBusinessRoles()}
              displayOption={(role, index) => (
                <option key={role} value={role}>
                  {role}
                </option>
              )}
            />
            <br />
            <Box textAlign="end">
              {formProps.isSubmitting ? (
                <Spinner color="brand" />
              ) : (
                <Button
                  colorScheme="primary"
                  disabled={formProps.isSubmitting}
                  type={"submit"}
                >
                  Continue
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </OnboardingLayout>
  );
};

export default OnboardingCompanyDetailsPage;
