import React, { useState } from "react";
import {
  FormLabel,
  Heading,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Flex,
  Button,
  HStack,
  Image,
  Box,
  VStack,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FormikErrors, useFormik } from "formik";
import { z } from "zod";
import { inject, observer } from "mobx-react";
import useSnackBar from "../../hooks/useSnackBar";
import { Privacy } from "../../enums/privacy";
import TextField from "../../shared/textfield/TextField";
import TextAreaField from "../../shared/textareafield/TextAreaField";
import { GlobalProps } from "../../App";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLanguage,
  MdNotifications,
  MdPhone,
  MdPlaylistPlay,
} from "react-icons/md";
import { MdEmail } from "react-icons/all";
import { CreatePlaylistDto } from "../../dtos/create-playlist.dto";
import ThumbnailPicker from "../../shared/thumbnail-picker/ThumbnailPicker";

interface CreatePlaylistDialogProps extends GlobalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddVideo: (playlistId?: string) => void;
  onAddFile: (playlistId?: string) => void;
}

// TODO: only show if current user can create a playlist
const CreatePlaylistDialog: React.FC<CreatePlaylistDialogProps> = (props) => {
  const notify = useSnackBar();
  const { isOpen, onClose } = props;
  const [uploading, setUploading] = useState(false);
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [notifyUsers, setNotifyUsers] = useState(false);
  const [createdPlaylistId, setCreatedPlaylistId] = useState<
    string | undefined
  >();
  const Icon = () => (
    <Box
      height="15px"
      width="15px"
      borderRadius="50%"
      backgroundColor="secondary.500"
    />
  );
  const stepProp = {
    icon: Icon,
    backgroundColor: "white",
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      email: "",
      phone: "",
    },
    validate: (values) => {
      const PlaylistSchema = z.object({
        title: z.string().min(1, "Title is required"),
        description: z.string().optional(),
        previewUrl: z.string().url().optional(),
        email: z.string().email().or(z.literal("")),
        phoneNumber: z.string().optional(),
      });

      const result = PlaylistSchema.safeParse(values);

      if (!result.success) {
        const errors: FormikErrors<z.infer<typeof PlaylistSchema>> = {};

        for (const issue of result.error.issues) {
          errors[issue.path[0] as keyof typeof errors] = issue.message;
        }

        return errors;
      }
    },
    onSubmit: async (values, helper) => {
      const playlistStore = props.store!.playlistStore;

      const { title, description } = values;

      const playlist: CreatePlaylistDto = {
        title,
        description,
        privacy: isPrivate ? Privacy.PRIVATE : Privacy.PUBLIC,
        previewUrl: null,
        contact: {
          email: values.email,
          phoneNumber: values.phone.replace(" ", ""),
        },
        items: [],
      };

      try {
        setUploading(true);
        const p = await playlistStore?.createPlaylist(
          playlist,
          notifyUsers,
          thumbnail
        );
        setCreatedPlaylistId(p?.uid);
        setUploading(false);
        notify("success", `Playlist created`);
      } catch (e) {
        setUploading(false);
        notify("error", `Problem creating playlist. Please try again.`);
      }
      helper.resetForm();
    },
  });

  // Shorthand for repeated attributes in fields
  const _fields = (name: keyof typeof formik.values) => ({
    id: name,
    name: name,
    value: formik.values[name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    isInvalid: formik.touched[name] && Boolean(formik.errors[name]),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />

      <ModalContent p="40px" borderRadius="21px">
        <ModalCloseButton />
        <ModalHeader>
          <Steps
            size="sm"
            colorScheme="secondary"
            orientation="horizontal"
            activeStep={activeStep}
          >
            <Step {...stepProp}>
              <Heading fontSize="2xl" my="20px">
                Add playlist
              </Heading>
              <HStack spacing={6} alignItems="space-between">
                <Box width="50%">
                  <TextField
                    type="text"
                    {..._fields("title")}
                    value={formik.values.title}
                    error={formik.errors.title}
                    placeholder="Title"
                    label="Title"
                  />
                  <TextAreaField
                    {..._fields("description")}
                    value={formik.values.description}
                    error={formik.errors.description}
                    label="Description"
                    placeholder="Description"
                  />
                  <TextField
                    type="email"
                    {..._fields("email")}
                    value={formik.values.email}
                    error={formik.errors.email}
                    label="Email"
                    placeholder="contact@example.com"
                  />

                  <TextField
                    type="tel"
                    {..._fields("phone")}
                    value={formik.values.phone}
                    error={formik.errors.phone}
                    label="Phone number"
                    placeholder="+1 323-810-6923"
                  />
                </Box>
                <Box>
                  <FormLabel color="gray.500" fontWeight="600" fontSize="xs">
                    Thumbnail
                  </FormLabel>
                  <ThumbnailPicker
                    thumbnail={thumbnail}
                    onThumbnailPicked={(file) => setThumbnail(file)}
                  />
                  <Flex alignItems="center" mt={1}>
                    <Switch
                      isChecked={isPrivate}
                      mr={1}
                      mt={1}
                      onChange={(event) => setIsPrivate(event.target.checked)}
                      colorScheme="primary"
                    />
                    <Text color="brand" fontSize="xs">
                      Hide playlist from public users view
                    </Text>
                  </Flex>

                  <Flex alignItems="center" mt={1}>
                    <Switch
                      isChecked={notifyUsers}
                      mr={1}
                      mt={1}
                      onChange={(event) => setNotifyUsers(event.target.checked)}
                      colorScheme="primary"
                    />
                    <Text color="brand" fontSize="xs">
                      Notify users
                    </Text>
                  </Flex>
                </Box>
              </HStack>
              <HStack mt="12px" justifyContent="flex-end">
                <Button
                  onClick={async () => {
                    try {
                      const errors = await formik.validateForm();
                      if (Object.keys(errors).length === 0) {
                        nextStep();
                      }
                    } catch (e) {
                      notify("error", "Invalid Form");
                      return;
                    }
                  }}
                  rightIcon={<MdKeyboardArrowRight size="24px" />}
                  colorScheme="secondary"
                  fontSize="12px"
                >
                  Next
                </Button>
              </HStack>
            </Step>
            <Step {...stepProp}>
              <Heading fontSize="2xl" my="20px">
                Confirm details
              </Heading>
              <HStack spacing={6} alignItems="space-between">
                <Box width="50%">
                  {!!thumbnail && (
                    <Image
                      width="320px"
                      height="180px"
                      borderRadius="lg"
                      src={URL.createObjectURL(thumbnail)}
                    />
                  )}
                  {!thumbnail && (
                    <Image
                      width="320px"
                      height="180px"
                      borderRadius="lg"
                      src="/assets/images/thumbnail.png"
                    />
                  )}
                </Box>
                <Box width="50%">
                  <HStack justifyContent="space-between">
                    <MdPlaylistPlay />
                    <HStack>
                      <MdLanguage />
                      <Text fontWeight="400" fontSize="0.7rem" color="brand">
                        {!isPrivate ? "Public" : "Private"}
                      </Text>
                    </HStack>
                  </HStack>
                  <Heading fontSize="1xl" my="20px">
                    {formik.values.title}
                  </Heading>
                  <Text mb={2} fontWeight="400" fontSize="0.7rem" color="gray">
                    {formik.values.description || "    No description added"}
                  </Text>
                  <Flex
                    fontWeight="400"
                    fontSize="0.7rem"
                    color="gray"
                    direction="row"
                    alignItems="center"
                  >
                    <MdPhone fontSize="20px" />
                    <Text ml="4px"> {formik.values.phone || "    N/A"}</Text>
                  </Flex>
                  <Flex
                    fontWeight="400"
                    fontSize="0.7rem"
                    color="gray"
                    direction="row"
                    alignItems="center"
                    mt="2"
                  >
                    <MdEmail fontSize="20px" />
                    <Text ml="4px"> {formik.values.email || "   N/A"}</Text>
                  </Flex>

                  <Flex
                    fontWeight="400"
                    fontSize="0.7rem"
                    color="gray"
                    direction="row"
                    alignItems="center"
                    mt="2"
                  >
                    <MdNotifications fontSize="20px" />
                    <Text ml="4px">{notifyUsers ? "On" : "Off"}</Text>
                  </Flex>
                </Box>
              </HStack>
              <HStack mt="20px" justifyContent="space-between">
                <Button
                  leftIcon={<MdKeyboardArrowLeft size="24px" />}
                  fontSize="12px"
                  onClick={prevStep}
                >
                  Back
                </Button>

                <Button
                  onClick={async () => {
                    try {
                      await formik.submitForm();
                      nextStep();
                    } catch (e) {
                      return;
                    }
                  }}
                  colorScheme="secondary"
                  fontSize="12px"
                  isLoading={uploading}
                >
                  Finish
                </Button>
              </HStack>
            </Step>
            <Step {...stepProp}>
              <Heading fontSize="2xl" my="20px">
                Well done!
              </Heading>
              <VStack m={4} spacing={4} mt="16px">
                <Flex
                  height="150px"
                  width="150px"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="50%"
                  backgroundColor="#ECECEC"
                >
                  <Image src="/assets/icons/finish.png" boxSize="70px" />
                </Flex>
                <Heading fontSize="lg">Playlist added!</Heading>
                <Text fontSize="sm" fontWeight="400">
                  Add content to your playlist now or later.
                </Text>
                <Text fontSize="sm" fontWeight="400">
                  Just make sure you've got content visible in public playlists
                  :)
                </Text>
                <HStack width="100%" mt="12px" justifyContent="flex-end">
                  <Button fontSize="12px" onClick={onClose}>
                    Skip for now
                  </Button>
                  <Button
                    onClick={() => {
                      props.onAddFile(createdPlaylistId);
                    }}
                    colorScheme="secondary"
                    fontSize="12px"
                  >
                    Add file
                  </Button>
                  <Button
                    onClick={() => {
                      props.onAddVideo(createdPlaylistId);
                    }}
                    colorScheme="secondary"
                    fontSize="12px"
                  >
                    Add video
                  </Button>
                </HStack>
              </VStack>
            </Step>
          </Steps>
        </ModalHeader>
      </ModalContent>
    </Modal>
  );
};

export default inject("store")(observer(CreatePlaylistDialog));
