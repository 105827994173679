import { Box, Text, Textarea, HStack, FormLabel } from "@chakra-ui/react";
import React from "react";
import { MdInfoOutline } from "react-icons/md";

interface TextAreaFieldProps {
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  error?: string | undefined;
  suggestedLength?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const { placeholder, label, name, value, onChange, error } = props;
  return (
    <Box w="100%" my="18px">
      {label && (
        <FormLabel
          mb="2px"
          color="gray.500"
          fontWeight="500"
          fontSize="xs"
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        variant="outline"
        backgroundColor="#F7F7F7"
        _placeholder={{ color: "gray.500" }}
        border="2px solid #E1E1E1"
        width="100%"
        height="48px"
        py="3"
        mt="4px"
        placeholder={placeholder}
        value={value}
        name={name}
        id={name}
        onChange={onChange}
      />

      <HStack justifyContent="space-between" mt="1">
        {!!error ? (
          <Text fontSize="sm" color="error">
            {error}
          </Text>
        ) : (
          <div></div>
        )}
      </HStack>
      {props.suggestedLength && (
        <HStack>
          <MdInfoOutline fontSize="14px" color="gray" />
          <Text fontSize="0.6rem" fontWeight="400" color="gray">
            Max {props.suggestedLength} characters
          </Text>
        </HStack>
      )}
    </Box>
  );
};

export default TextAreaField;
