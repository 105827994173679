import React, { useEffect } from "react";
import { GlobalProps } from "../App";
import { inject, observer } from "mobx-react";
import useSnackBar from "../hooks/useSnackBar";
import { CommonUtils } from "../utils/CommonUtils";
import { reaction } from "mobx";
import { HttpError } from "../services/ApiService";
import {
  Avatar,
  Box,
  Center,
  Grid,
  Heading,
  HStack,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdTimeline, MdRemoveRedEye, MdAvTimer } from "react-icons/md";
import ContentCard from "../content/ContentCard";
import AppLayout from "../shared/app-layout/AppLayout";

const STAT_FALLBACK = "N/A";
const getTotalViews = (views: number | undefined) => {
  if (!views || views < 0) {
    return "0";
  }
  return views;
};

const getWatchTime = (watchTime: number | undefined) => {
  if (!watchTime || watchTime < 0) {
    return "0";
  }
  const mins = CommonUtils.msToMin(watchTime);
  return `${mins} mins`;
};

const Home: React.FC<GlobalProps> = (props: GlobalProps) => {
  const rootStore = props.store;
  const notify = useSnackBar();
  const user = rootStore?.authStore?.loggedInUser;
  const disposer = reaction(
    () => rootStore?.organizationRoleStore?.selectedOrganizationId!,
    (orgId: string) => {
      if (!orgId) {
        return;
      }
      rootStore
        ?.loadDashboardAnalytics(true)
        .catch((error) => notify("error", (error as HttpError).message));
    }
  );

  // FIXME Analytics API keeps getting repeatedly hit when on Dashboard Home page
  // also won't get hit unless user switches organisations (stats stay 'N/A')
  const { analytics } = rootStore!;
  useEffect(() => {
    rootStore
      ?.loadDashboardAnalytics()
      .then()
      .catch((error) => notify("error", (error as HttpError).message));
    return () => {
      disposer();
    };
    // eslint-disable-next-line
  }, [analytics]);

  const views =
    analytics && analytics.totalStats
      ? getTotalViews(analytics.totalStats.views)
      : STAT_FALLBACK;
  const watchtime =
    analytics && analytics.totalStats
      ? getWatchTime(analytics.totalStats.watchTimeMs)
      : STAT_FALLBACK;

  return (
    <AppLayout
      colorScheme="primary"
      title={`Welcome, ${CommonUtils.getUserDisplayDetail(user!)}`}
      description={
        <>
          In KonnekApp, you can check out at a glance the performance of key
          metrics for your organisation. <br />
          Discover more about your audience, their behaviours and what they love
          about your content.
          <br />
          And best of all, find recommendations on users who are more likely to
          engage with you.
        </>
      }
      startLogo={
        <Avatar
          size="xl"
          color="white"
          backgroundColor="brand"
          name={CommonUtils.getUserDisplayDetail(user!)}
          src={user?.photoURL || ""}
        />
      }
    >
      {rootStore?.isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Stack direction={{ base: "column", lg: "row" }} spacing="30px">
          <Box
            height="320px"
            width="280px"
            minW="280px"
            p={{ base: "8%", md: "2%" }}
            backgroundColor="secondary.100"
            borderRadius="12px"
          >
            <VStack spacing={4} alignItems="start">
              <HStack spacing={3} mb="10px">
                <Icon boxSize="30px" color="secondary.500" as={MdTimeline} />
                <Heading fontSize="4xl">Analytics</Heading>
              </HStack>
              <HStack spacing={3}>
                <Icon
                  boxSize="24px"
                  color="secondary.500"
                  as={MdRemoveRedEye}
                />
                <Heading fontSize="xs">Content views</Heading>
              </HStack>
              <Heading color="secondary.500" fontSize="3xl" fontWeight={900}>
                {views}
              </Heading>
              <HStack spacing={3}>
                <Icon boxSize="24px" color="secondary.500" as={MdAvTimer} />
                <Heading fontSize="xs">Watch time (minutes)</Heading>
              </HStack>
              <Heading color="secondary.500" fontSize="3xl" fontWeight={900}>
                {watchtime}
              </Heading>
            </VStack>
          </Box>
          <Box>
            {!rootStore?.analytics?.totalStats ||
            rootStore?.analytics?.totalStats?.views! < 0 ? (
              <Box mt="20px">
                <Center>
                  <Image src="/assets/images/no-analytics.svg" alt="No data" />
                </Center>
                <Center mt="24px">
                  <Text textAlign="center" color="gray" size="xs" width="60%">
                    Want to see metrics on your recent content? Upload and
                    publish a content to get started.
                  </Text>
                </Center>
              </Box>
            ) : (
              <>
                <Heading mb="22px">Most popular content</Heading>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                  gap="32px"
                >
                  {rootStore?.analytics?.content.map((c) => (
                    <ContentCard key={c.uid} content={c} />
                  ))}
                </Grid>
              </>
            )}
          </Box>
        </Stack>
      )}
    </AppLayout>
  );
};
export default inject("store")(observer(Home));
