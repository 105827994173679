import React from "react";
import { useDropzone } from "react-dropzone";
import useSnackBar from "../../hooks/useSnackBar";
import { Button, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { MdCloudUpload, MdInfo } from "react-icons/md";

const MAX_SIZE_LIMIT = 5 * 1024 * 1024;
const THRESHOLD_SIZE_LIMIT = 2 * 1024 * 1024;

export interface ThumbnailPickerProps {
  thumbnail: File | null;
  onThumbnailPicked: (file: File | null) => void;
}

const ThumbnailPicker: React.FC<ThumbnailPickerProps> = (
  props: ThumbnailPickerProps
) => {
  const { thumbnail, onThumbnailPicked } = props;
  const notify = useSnackBar();
  const onDropThumbnail = (files: File[]) => {
    const file = files[0];
    if (file.size > MAX_SIZE_LIMIT) {
      notify(
        "error",
        "File size too large. Maximum file size should be within 5mb."
      );
      return;
    }
    if (file.size > THRESHOLD_SIZE_LIMIT) {
      notify(
        "warning",
        "File size exceeds threshold limit. Please upload file within 2mb for better experience."
      );
    }
    onThumbnailPicked(file);
  };
  const {
    getRootProps: getThumbnailRootProps,
    getInputProps: getThumbnailInputProps,
  } = useDropzone({ onDrop: onDropThumbnail, accept: "image/*" });
  return (
    <>
      {!thumbnail ? (
        <Flex
          width="320px"
          height="180px"
          backgroundColor="#F7F7F7"
          borderRadius="9px"
          direction="column"
          justifyContent="center"
          alignItems="center"
          fontSize="xs"
          color="gray"
          {...getThumbnailRootProps()}
        >
          <MdCloudUpload size="38px" color="gray" />
          <Text my="4px">Drag and drop an image here to upload</Text>
          <Text my="4px">or</Text>
          <Button variant="link" size="xs" my="4px" fontSize="12px">
            Click to select image
          </Button>
          <input {...getThumbnailInputProps()} />
        </Flex>
      ) : (
        <Image
          width="320px"
          height="180px"
          borderRadius="lg"
          src={URL.createObjectURL(thumbnail)}
        />
      )}
      <HStack mt="6px">
        <MdInfo fontSize="14px" color="gray" />
        <Text fontWeight="400" fontSize="0.6rem" color="gray">
          Recommended size 800x400 pixels
        </Text>
        {thumbnail && (
          <Button
            onClick={() => onThumbnailPicked(null)}
            size="xs"
            variant="ghost"
          >
            Change
          </Button>
        )}
      </HStack>
    </>
  );
};

export default ThumbnailPicker;
