import React from "react";
import OnboardingLayout from "../../Layout";
import { Button, Heading, Spinner, Text } from "@chakra-ui/react";
import {
  BillingAccountSchema,
  BillingAccountSchemaType,
} from "../../views/CreateBillingAccountView";
import { Formik, FormikErrors } from "formik";
import TextField from "../../../shared/textfield/TextField";

export interface CreateBillingAccountPageProps {
  initialFormState: BillingAccountSchemaType;
  onCreate: (values: BillingAccountSchemaType) => void;
}

const CreateBillingAccountPage: React.FC<CreateBillingAccountPageProps> = (
  props: CreateBillingAccountPageProps
) => {
  const { initialFormState, onCreate } = props;
  return (
    <OnboardingLayout>
      <Heading fontSize="5xl">Add your billing information.</Heading>
      <Text mt="16px" mb="44px" color="gray" fontSize="xs">
        Please put in the details of the person responsible for handling the
        billing of your KonnekApp account. If you already have billing
        information associated with your account you can use that too.
      </Text>

      <Formik
        initialValues={initialFormState}
        validate={(values) => {
          const result = BillingAccountSchema.safeParse(values);

          if (!result.success) {
            const errors: FormikErrors<BillingAccountSchemaType> = {};
            result.error.issues.forEach((issue) => {
              errors[issue.path[0] as keyof typeof errors] = issue.message;
            });
            return errors;
          }
        }}
        onSubmit={onCreate}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <TextField
              type="text"
              name="billingName"
              onChange={formProps.handleChange}
              placeholder="Name of Payee"
              value={formProps.values.billingName}
              error={
                formProps.errors.billingName && formProps.touched.billingName
                  ? formProps.errors.billingName
                  : undefined
              }
            />
            <TextField
              type="text"
              name="billingEmail"
              onChange={formProps.handleChange}
              placeholder="Email of Payee"
              value={formProps.values.billingEmail}
              error={
                formProps.errors.billingEmail && formProps.touched.billingEmail
                  ? formProps.errors.billingEmail
                  : undefined
              }
            />
            <TextField
              type="text"
              name="billingPhone"
              onChange={formProps.handleChange}
              placeholder="Phone number"
              value={formProps.values.billingPhone}
              error={
                formProps.errors.billingPhone && formProps.touched.billingPhone
                  ? formProps.errors.billingPhone
                  : undefined
              }
            />
            <TextField
              type="text"
              name="friendlyName"
              onChange={formProps.handleChange}
              placeholder="Nick name of Payee"
              value={formProps.values.friendlyName}
              error={
                formProps.errors.friendlyName && formProps.touched.friendlyName
                  ? formProps.errors.friendlyName
                  : undefined
              }
            />
            <br />
            {formProps.isSubmitting ? (
              <Spinner />
            ) : (
              <Button
                colorScheme="primary"
                disabled={formProps.isSubmitting}
                type={"submit"}
              >
                Continue
              </Button>
            )}
          </form>
        )}
      </Formik>
    </OnboardingLayout>
  );
};

export default CreateBillingAccountPage;
