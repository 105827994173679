import Model from "./Model";
import { observable } from "mobx";
import { ContentSourceType } from "../enums/content-source-type";
import { MimeString } from "../content/TypeIcon";
import { Privacy } from "../enums/privacy";

export class ContentModel extends Model {
  static _store: any;

  @observable uid!: string;
  @observable orgId!: string;

  @observable title!: string;
  @observable description!: string;

  @observable timeCreated!: Date;
  @observable timeModified!: Date;

  @observable previewUrl!: string;
  @observable privacy!: Privacy;

  @observable type!: ContentSourceType;

  @observable url?: string;
  @observable file?: {
    checksum: string;
    id: string;
    size: number;
    type: MimeString;
  };

  @observable stats!: {
    views: number;
    watchTime: number;
  };

  getId(): string {
    return this.uid;
  }
}
