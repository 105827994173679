import { apiService } from "./ApiService";
import { z } from "zod";

export const analyticGraphSchema = z.object({
  total: z.number(),
  data: z.array(
    z.object({
      time: z.preprocess((x) => new Date(x as any), z.date()),
      value: z.number(),
    })
  ),
});

export type AnalyticGraph = z.infer<typeof analyticGraphSchema>;

/// Helper method to hit API
const _fetch = async (
  path: string,
  queryParams: {
    [key: string]: string;
  }
): Promise<unknown> => {
  return apiService.get(path, null, { queryParams });
};

/// Helper method to hit API and parse graph-like data
const _fetchGraph = async (
  path: string,
  queryParams: {
    [key: string]: string;
  }
): Promise<AnalyticGraph> => {
  return _fetch(path, queryParams).then((data) => {
    return analyticGraphSchema.parseAsync(data);
  });
};

/*
 * Return watch time in entire organization, across all content.
 */
export async function fetchOrgWatchTime(
  orgId: string,
  startTime: Date,
  endTime: Date
): Promise<AnalyticGraph> {
  return _fetchGraph("/analytics/watch_time", {
    orgId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  });
}

/**
 * Return watch time for specific user within organisation.
 */
export async function fetchUserWatchTime(
  userId: string,
  orgId: string,
  startTime: Date,
  endTime: Date
): Promise<AnalyticGraph> {
  return _fetchGraph(`/analytics/watch_time`, {
    orgId,
    userId: userId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  });
}

export async function fetchUserViews(
  userId: string,
  orgId: string,
  startTime: Date,
  endTime: Date
): Promise<AnalyticGraph> {
  return _fetchGraph(`/analytics/views`, {
    orgId,
    userId: userId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  });
}
export async function fetchUserVisits(
  userId: string,
  orgId: string,
  startTime: Date,
  endTime: Date
): Promise<AnalyticGraph> {
  return _fetchGraph(`/analytics/visits`, {
    orgId,
    userId: userId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  });
}

const detailedLeadSchema = z.object({
  total: z.number(),
  mostViewedContent: z.array(
    z.object({ id: z.string(), viewCount: z.number() })
  ),
  longestWatchedContent: z.array(
    z.object({ id: z.string(), watchTimeMs: z.number() })
  ),
  mostViewedPlaylists: z.array(
    z.object({ id: z.string(), viewCount: z.number() })
  ),
  longestWatchedPlaylists: z.array(
    z.object({ id: z.string(), watchTimeMs: z.number() })
  ),
});
type DetailedLead = z.infer<typeof detailedLeadSchema>;

export async function fetchLead(
  userId: string,
  orgId: string,
  startDate: Date,
  endDate: Date
): Promise<DetailedLead> {
  return _fetch(`/analytics/users/${userId}`, {
    orgId,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  }).then((r) => detailedLeadSchema.parseAsync(r));
}

export const LeadSchema = z.object({
  userId: z.string(),
  orgId: z.string(),
  score: z.number(),
  temperature: z.enum(["cold", "warm", "hot"]),
  lastModified: z.preprocess((d: any) => new Date(d), z.date()),
});

export type Lead = z.infer<typeof LeadSchema>;
const LeadResponseSchema = z.object({ data: z.array(LeadSchema) });
type LeadResponseType = z.infer<typeof LeadResponseSchema>;

export async function fetchLeads(orgId: string): Promise<LeadResponseType> {
  return _fetch(`/analytics/leads`, {
    orgId,
  }).then((r: unknown) => {
    return LeadResponseSchema.parseAsync(r);
  });
}
