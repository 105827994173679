import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  MdModeEditOutline,
  MdAdd,
  MdPhone,
  MdCheck,
  MdArrowBackIos,
  MdInsertDriveFile,
  MdVideocam,
  MdMenu,
} from "react-icons/md";
import { ContentModel } from "../../models/ContentModel";
import { PlaylistModel } from "../../models/PlaylistModel";
import { inject } from "../../stores/helpers/inject";
import {
  Text,
  Flex,
  Heading,
  Icon,
  useDisclosure,
  Link,
  ButtonGroup,
  Box,
  Stack,
  Image,
  Skeleton,
  SkeletonText,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import ReorderableContentCard from "../ReorderableContentCard";
import { RolesUtils } from "../../utils/RolesUtils";
import { OrganizationPolicy } from "../../enums/access-policy";
import Button from "../../shared/button/Button";
import EditPlaylistDialog from "../edit-playlist-dialog/EditPlaylistDialog";
import { NavLink } from "react-router-dom";
import PageNotFound from "../../errors/page-not-found/PageNotFound";
import { MdEmail } from "react-icons/all";
import {
  Item,
  ReorderableList,
} from "../../shared/reorderable-list/ReorderableList";
import { NoContentFallback } from "../no-content/NoContentFallback";
import useSnackBar from "../../hooks/useSnackBar";
import CreateContentDialog from "../create-content-dialog/CreateContentDialog";
import EditContentDialog from "../edit-content-dialog/EditContentDialog";
import ContentPreview from "../content-preview/ContentPreview";

export const PureVideoList = (props: {
  loading: boolean;
  updating: boolean;
  playlist?: PlaylistModel; // If undefined, returns page not found
  content?: ContentModel[];
  allowedToAddContent: boolean;
  allowedToEdit: boolean;
  organizationId: string | undefined;
  updatePlaylist: (updates: Partial<PlaylistModel>) => Promise<void>;
}) => {
  const notify = useSnackBar();
  const addContentDialog = useDisclosure();
  const updateContentDialog = useDisclosure();
  const editPlaylistDialog = useDisclosure();
  const [uploadType, setUploadType] = useState<"file" | "url">("file");
  const [newOrder, updateNewOrder] = useState<Item[]>([]);
  const [previewContent, setPreviewContent] = useState<ContentModel>();
  const [isReordering, setIsReordering] = useState(false);

  const onReorderComplete = (items: Item[]) => {
    updateNewOrder(items);
  };

  const saveNewOrderContent = async () => {
    if (!newOrder.length) {
      setIsReordering(false);
      return;
    }
    try {
      await props.updatePlaylist({
        items: newOrder.map((e) => e.id),
      } as Partial<PlaylistModel>);
      setIsReordering(false);
      updateNewOrder([]);
      notify("success", "Content Reordered");
    } catch (e) {
      notify("error", "Content Reorder Failed");
    }
  };
  const items =
    props.content?.map((content) => {
      return {
        id: content.id.toString(),
        children: (
          <ReorderableContentCard key={content.uid} content={content} />
        ),
      };
    }) || [];
  if (!props.loading && !props.playlist) {
    return <PageNotFound />;
  }
  return (
    <Box>
      <Box
        padding="1.2rem 2.8rem"
        minH={{ base: "100px", md: "190px" }}
        backgroundColor={`rgba(0, 0, 0, 0.03)`}
        borderRadius="0 0 22px 22px"
      >
        <Box mb="1.2rem">
          <Link as={NavLink} to={`/org/${props.organizationId}/playlists`}>
            <Flex fontSize="1rem" fontWeight="500" alignItems="center">
              <Icon as={MdArrowBackIos} />
              <Text ml="10px">Back to playlists</Text>
            </Flex>
          </Link>
        </Box>
        <Flex>
          <Stack
            width="100%"
            direction={{ base: "column", lg: "row" }}
            spacing={8}
          >
            <Image
              rounded="md"
              objectFit="cover"
              height="14rem"
              width="23rem"
              src={
                props.playlist?.previewUrl ||
                "/assets/images/video-placeholder.jpg"
              }
            />
            <Box width="90%" height="100%">
              <Flex
                width="100%"
                direction="column"
                justifyContent="space-between"
              >
                {!props.playlist && (
                  <Box>
                    <Skeleton height="20px" width="100px" />
                    <Skeleton
                      height="100px"
                      width="500px"
                      mt="12px"
                      mb="10px"
                    />
                  </Box>
                )}
                {props.playlist && (
                  <Box mt="1.2rem">
                    <Heading fontSize="5xl">{props.playlist.title}</Heading>
                    <Text
                      mt="12px"
                      mb="10px"
                      color="rgba(0, 0, 0, 0.42)"
                      fontSize="s"
                    >
                      {props.playlist.description}
                    </Text>
                  </Box>
                )}
                <Flex
                  width="100%"
                  direction={{ base: "column", lg: "row" }}
                  justify="space-between"
                  alignItems={{ base: "flex-start", lg: "center" }}
                >
                  <Box fontWeight="500">
                    <Flex direction="row" alignItems="center">
                      <Icon fontSize="1rem" mr="2" as={MdPhone} />
                      {!props.playlist && (
                        <Skeleton height="20px" width="100px" />
                      )}
                      {props.playlist && (
                        <Text fontSize="xs">
                          {" "}
                          {props.playlist.contact?.phoneNumber || "N/A"}
                        </Text>
                      )}
                    </Flex>
                    <Flex direction="row" alignItems="center" mt="2">
                      <Icon fontSize="1rem" mr="2" as={MdEmail} />
                      {!props.playlist && (
                        <Skeleton height="20px" width="100px" />
                      )}
                      {props.playlist && (
                        <Text fontSize="xs">
                          {" "}
                          {props.playlist.contact?.email || "N/A"}
                        </Text>
                      )}
                    </Flex>
                  </Box>
                  <ButtonGroup spacing="1rem" mt={{ base: "10px", lg: "0" }}>
                    {props.allowedToAddContent && (
                      <Menu>
                        <MenuButton
                          size="sm"
                          as={Button}
                          colorScheme="secondary"
                          leftIcon={<Icon as={MdAdd} />}
                        >
                          Add
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            icon={<MdInsertDriveFile size="22px" />}
                            onClick={() => {
                              setUploadType("file");
                              addContentDialog.onOpen();
                            }}
                          >
                            Add file
                          </MenuItem>
                          <MenuItem
                            icon={<MdVideocam size="22px" />}
                            onClick={() => {
                              setUploadType("url");
                              addContentDialog.onOpen();
                            }}
                          >
                            Add video
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                    {props.allowedToEdit && (
                      <Button
                        size="sm"
                        backgroundColor="#FFFFFF"
                        onClick={editPlaylistDialog.onOpen}
                        leftIcon={<Icon as={MdModeEditOutline} />}
                      >
                        Edit
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </Flex>
            </Box>
          </Stack>
        </Flex>
      </Box>
      <Box p={{ base: "7", lg: "3%" }}>
        <Box width="100%">
          {addContentDialog.isOpen && (
            <CreateContentDialog
              isOpen={true}
              onClose={addContentDialog.onClose}
              uploadType={uploadType}
              playlistId={props.playlist?.uid}
            />
          )}
          {updateContentDialog.isOpen && (
            <EditContentDialog
              title={previewContent?.title!}
              description={previewContent?.description!}
              uid={previewContent?.uid!}
              type={previewContent?.type!}
              previewUrl={previewContent?.previewUrl!}
              url={previewContent?.url!}
              privacy={previewContent?.privacy!}
              playlist={props.playlist}
              isOpen={true}
              onClose={updateContentDialog.onClose}
            />
          )}

          {props.playlist && (
            <EditPlaylistDialog
              isOpen={editPlaylistDialog.isOpen}
              onClose={editPlaylistDialog.onClose}
              playlist={props.playlist}
            />
          )}
          {!props.loading && props.content && props.content.length === 0 && (
            <NoContentFallback onAddContent={addContentDialog.onOpen} />
          )}
          <Flex width="100%">
            {!props.loading && props.content && props.content.length !== 0 && (
              <Box width="50%">
                <Text fontWeight="600">Preview</Text>
                {!isReordering && (
                  <ContentPreview
                    content={previewContent || props.content![0]}
                    onUpdate={() => {
                      setPreviewContent(previewContent || props.content![0]);
                      updateContentDialog.onOpen();
                    }}
                  />
                )}
                {isReordering && (
                  <VStack height="30rem" width="100%" justifyContent="center">
                    <Image
                      rounded="md"
                      width="20rem"
                      height="20rem"
                      objectFit="cover"
                      src={"/assets/images/reorder.gif"}
                    />
                    <Box
                      width="25rem"
                      textAlign="center"
                      color="#BABABA"
                      mt={2}
                    >
                      You can also drag and drop to reorder your content.
                    </Box>
                  </VStack>
                )}
              </Box>
            )}
            <Box width="50%">
              <Flex mb="2rem" width="100%" justifyContent="space-between">
                {!props.playlist && props.loading && (
                  <SkeletonText height="20px" width="40px" />
                )}
                {props.playlist &&
                  !props.loading &&
                  props.content &&
                  props.content.length !== 0 && (
                    <HStack>
                      <Text fontWeight="600">Contents: </Text>
                      <Text fontWeight="400" color="secondary.500">
                        {props.playlist.items.length}
                      </Text>
                    </HStack>
                  )}
                {!props.loading &&
                  props.content &&
                  props.content.length !== 0 &&
                  !isReordering && (
                    <Button
                      size="sm"
                      backgroundColor="#F7F7F7"
                      leftIcon={<MdMenu />}
                      onClick={() => {
                        setPreviewContent(props.content![0]);
                        setIsReordering(true);
                      }}
                    >
                      Reorder
                    </Button>
                  )}
                {!props.loading &&
                  props.content &&
                  props.content.length !== 0 &&
                  isReordering && (
                    <Button
                      size="sm"
                      colorScheme="secondary"
                      isLoading={props.updating}
                      onClick={saveNewOrderContent}
                      leftIcon={<Icon as={MdCheck} />}
                    >
                      Done
                    </Button>
                  )}
              </Flex>
              {props.loading && (
                <Box width="30vw" height="4rem">
                  <Skeleton width="30vw" height="3.5rem" />
                  <SkeletonText />
                </Box>
              )}
              {!props.loading && props.content && props.content.length !== 0 && (
                <Box>
                  {!isReordering &&
                    props.content?.map((content) => {
                      return (
                        <Box
                          key={content.uid}
                          mb={8}
                          onClick={() => {
                            setPreviewContent(content);
                          }}
                        >
                          <ReorderableContentCard
                            key={content.uid}
                            content={content}
                            editContent={updateContentDialog.onOpen}
                          />
                        </Box>
                      );
                    })}
                  {isReordering && (
                    <ReorderableList
                      items={items}
                      onMoveComplete={onReorderComplete}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default inject((store) => {
  const playlistId = useParams<{ playlistId: string }>().playlistId;
  useEffect(() => {
    store.playlistStore?.getPlaylistById(playlistId);
    store.playlistStore?.fetchPlaylistVideos(playlistId);
  }, [playlistId, store]);
  return {
    loading: store.playlistStore!.isVideosLoading,
    organizationId: store.organizationRoleStore?.selectedOrganizationId,
    playlist: store.playlistStore!.get(playlistId),
    content: store.playlistStore!.playlistVideos[playlistId],
    allowedToAddContent: RolesUtils.isAccessAllowed(
      store.organizationRoleStore?.selectedOrgUserRole!,
      OrganizationPolicy.ORGANIZATION_CONTENT_CREATE
    ),
    allowedToEdit: RolesUtils.isAccessAllowed(
      store.organizationRoleStore?.selectedOrgUserRole!,
      OrganizationPolicy.PLAYLIST_UPDATE
    ),
    updating: store.playlistStore!.isPlaylistUpdating,
    updatePlaylist: (updates: Partial<PlaylistModel>) =>
      store.playlistStore!.updatePlaylist(playlistId, updates),
  };
}, PureVideoList);
