import firebase from "firebase/app";
import "firebase/auth";

class FirebaseService {
  private static _app?: firebase.app.App;

  static getInstance(): FirebaseService {
    return new FirebaseService();
  }

  private _initializeApp() {
    // Already initialised
    if (FirebaseService._app) return;

    // Initialize Firebase
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };
    FirebaseService._app = firebase.initializeApp(firebaseConfig);
  }

  private constructor() {
    this._initializeApp();
  }

  getFirebaseApp(): firebase.app.App {
    if (!FirebaseService._app) throw new Error("Firebase SDK not initialised");
    return FirebaseService._app;
  }

  async signInWithGoogle() {
    await this.getFirebaseApp()
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  isSignInWithEmailLink(link: string): boolean {
    return this.getFirebaseApp().auth().isSignInWithEmailLink(link)!;
  }

  async sendMagicLink(email: string, action: "login" | "signup") {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_URL}/authenticate?action=${action}`,
      handleCodeInApp: true,
    };
    await this.getFirebaseApp()
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings);
  }

  async signInWithMagicLink(email: string, link: string) {
    return this.getFirebaseApp().auth().signInWithEmailLink(email, link);
  }

  async signInWithApple() {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    await this.getFirebaseApp().auth().signInWithPopup(provider);
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    await this.getFirebaseApp()
      .auth()
      .signInWithEmailAndPassword(email, password);
  }

  async signUpWithEmailAndPassword(email: string, password: string) {
    await this.getFirebaseApp()
      .auth()
      .createUserWithEmailAndPassword(email, password);
  }

  async sendPasswordResetEmail(email: string) {
    await this.getFirebaseApp().auth().sendPasswordResetEmail(email);
  }

  async fetchToken() {
    return this.getFirebaseApp().auth().currentUser?.getIdToken();
  }

  async logout() {
    return this.getFirebaseApp().auth().signOut();
  }
}

export const firebaseService = FirebaseService.getInstance();
