import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  Spacer,
  Spinner,
  Stack,
  HStack,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import useSnackBar from "../hooks/useSnackBar";
import { Theme } from "../interface/theme";
import { OrganizationModel } from "../models/OrganizationModel";
import { HttpError } from "../services/ApiService";
import { Privacy } from "../enums/privacy";
import { AiFillCopy } from "react-icons/all";
import KonnekAppTooltip from "../shared/tooltip/Tooltip";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import { organizationService } from "../services/OrganizationService";
import TextField from "../shared/textfield/TextField";
import PrimarySelect from "../shared/primary-select/PrimarySelect";
import Avatar from "../shared/avatar/Avatar";
import { uploadService } from "../services/UploadService";
import { MdInfo, MdDomain } from "react-icons/md";
import ColorPicker from "../shared/color-picker/ColorPicker";
import WelcomeScreen from "./welcome-screen/WelcomeScreen";
import IntroScreen from "./intro-screen/IntroScreen";
import AppLayout from "../shared/app-layout/AppLayout";

const OrganizationProfile: React.FC = (props: any) => {
  const orgRoleStore = props.store?.organizationRoleStore;
  const orgStore = props.store?.organizationStore;
  const selectedOrganization = orgRoleStore?.selectedOrganization;
  const [organization, setOrganization] = React.useState(selectedOrganization);
  const [organizationAvatarLoading, setOrganizationAvatarLoading] =
    React.useState(false);
  const [organizationBgLoading, setOrganizationBgLoading] =
    React.useState(false);
  const notify = useSnackBar();

  const uploadOrganizationLogo = async (file: File) => {
    try {
      setOrganizationAvatarLoading(true);
      const imageUrl = await uploadService.uploadFile(file);
      setOrganization((s: OrganizationModel) => {
        return {
          ...s,
          logoUrl: imageUrl,
        } as OrganizationModel;
      });
    } catch (error) {
      notify(
        "error",
        (error as Error).message ? (error as Error).message : (error as string)
      );
    } finally {
      setOrganizationAvatarLoading(false);
    }
  };

  const uploadOrganizationBackground = async (file: File) => {
    try {
      setOrganizationBgLoading(true);
      const imageUrl = await uploadService.uploadFile(file);
      setOrganization((s: OrganizationModel) => {
        return {
          ...s,
          theme: {
            ...s?.theme,
            backgroundUrl: imageUrl,
          },
        } as OrganizationModel;
      });
    } catch (error) {
      notify(
        "error",
        (error as Error).message ? (error as Error).message : (error as string)
      );
    } finally {
      setOrganizationBgLoading(false);
    }
  };
  const updateOrganizationTheme = (changes: Partial<Theme>) => {
    setOrganization((s: any) => {
      return {
        ...s,
        theme: {
          ...s?.theme,
          ...changes,
        },
      } as OrganizationModel;
    });
  };
  const setPrimaryColor = (color: string) => {
    updateOrganizationTheme({
      primaryColor: color,
    });
  };
  const setSecondaryColor = (color: string) => {
    updateOrganizationTheme({
      secondaryColor: color,
    });
  };
  const setBrandTextColor = (color: string) => {
    updateOrganizationTheme({
      brandTextColor: color,
    });
  };

  const generateJoinUrl = async () => {
    try {
      await orgStore?.generateJoinUrl(organization?.uid!);
      notify("success", "Join Url generated successfully!");
    } catch (error) {
      notify("error", (error as HttpError).message);
    }
  };

  const copyToClipboard = () => {
    try {
      const el = document.createElement("textarea");
      el.value = selectedOrganization?.publicJoinUrl!;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      notify("success", "Public join URL copied successfully!");
    } catch (e) {
      notify("error", "Unable to copy!");
    }
  };
  return (
    <AppLayout
      colorScheme="primary"
      startLogo={<Icon fontSize="4rem" as={MdDomain} />}
      title={`${selectedOrganization?.name || ""} Organization`}
      description={
        <>
          Here, you can apply your brand to your KonnekApp App on iOS or Android
          devices.
          <br />
          Update your organization details such as your website and adjust your
          privacy level settings.
          <br />
          Add your logo, swatch colours and more to personalize your KonnekApp
          to you.
        </>
      }
      action={
        selectedOrganization?.privacy === Privacy.PUBLIC ? (
          <>
            {orgStore?.isLoading ? (
              <Spinner size="xs" />
            ) : (
              <>
                {!!selectedOrganization?.publicJoinUrl ? (
                  <Flex>
                    <Text width="min-content" mr="6px">
                      <Text color="brand" fontWeight={900} display="inline">
                        Join URL:
                      </Text>{" "}
                      {selectedOrganization?.publicJoinUrl}
                    </Text>
                    <KonnekAppTooltip label="Click to copy! (Share your Join URL anywhere and get new users to join your KonnekApp.)">
                      <span>
                        <IconButton
                          variant="ghost"
                          colorScheme="primary"
                          onClick={copyToClipboard}
                          aria-label="Copy"
                          icon={<AiFillCopy size="20px" />}
                        />
                      </span>
                    </KonnekAppTooltip>
                  </Flex>
                ) : (
                  <KonnekAppTooltip label="Share your Join URL anywhere and get new users to join your KonnekApp.">
                    <Button
                      mt={{ base: "10px", md: "0" }}
                      colorScheme="primary"
                      size="sm"
                      onClick={generateJoinUrl}
                    >
                      Generate joining link
                    </Button>
                  </KonnekAppTooltip>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )
      }
    >
      <Box>
        <Flex mb="8px" direction={{ base: "column", lg: "row" }}>
          <Heading fontSize="4xl">ORGANIZATION</Heading>
          <Spacer />
        </Flex>

        <Stack spacing={14} direction={{ base: "column-reverse", lg: "row" }}>
          <Box w={{ base: "90%", lg: "45%" }}>
            <Stack direction={{ base: "column", lg: "row" }} spacing={8}>
              <Box>
                <Avatar
                  avatarUrl={
                    organization?.logoUrl || "/assets/images/org-logo.svg"
                  }
                  onChange={uploadOrganizationLogo}
                  loading={organizationAvatarLoading}
                  label="Logo"
                />
                <HStack>
                  <MdInfo size={35} />
                  <Text fontSize="xs">
                    We recommend a picture of aspect ratio 1:1 for best
                    experience.
                  </Text>
                </HStack>
              </Box>
              <Box>
                <Avatar
                  avatarUrl={
                    organization?.theme?.backgroundUrl ??
                    "/assets/images/org-background.svg"
                  }
                  onChange={uploadOrganizationBackground}
                  loading={organizationBgLoading}
                  width="180px"
                  height="270px"
                  label="Background Wallpaper"
                />
                <HStack>
                  <MdInfo size={35} />
                  <Text fontSize="xs">
                    We recommend a picture of aspect ratio 2:3 for best
                    experience.
                  </Text>
                </HStack>
              </Box>
            </Stack>
            <Formik
              initialValues={{
                name: organization?.name,
                description: organization?.description,
                website: organization?.website,
                privacy: organization?.privacy,
              }}
              onSubmit={async (values, action) => {
                try {
                  await organizationService.updateOrganization(
                    organization?.uid!,
                    {
                      ...values,
                      logoUrl: organization?.logoUrl,
                      theme: {
                        backgroundUrl:
                          organization?.theme?.backgroundUrl || null,
                        primaryColor: organization?.theme?.primaryColor || null,
                        secondaryColor:
                          organization?.theme?.secondaryColor || null,
                        brandTextColor:
                          organization?.theme?.brandTextColor || null,
                      },
                    }
                  );
                  action.setSubmitting(false);
                  notify("success", "Profile updated successfully");
                } catch (error) {
                  action.setSubmitting(false);
                  notify("error", (error as HttpError).message);
                }
              }}
            >
              {(formProps) => (
                <form onSubmit={formProps.handleSubmit}>
                  <TextField
                    type="text"
                    label="Organization Name"
                    onChange={formProps.handleChange}
                    name="name"
                    value={formProps.values.name}
                  />
                  <TextField
                    type="text"
                    label="Your Organization's strapline or slogan"
                    onChange={formProps.handleChange}
                    name="description"
                    value={formProps.values.description}
                  />
                  <TextField
                    type="text"
                    label="What's your website URL?"
                    onChange={formProps.handleChange}
                    name="website"
                    value={formProps.values.website}
                  />
                  <PrimarySelect
                    onChange={formProps.handleChange}
                    label="Privacy"
                    info={
                      <>
                        Public: When Public is selected a 'Join URL' is
                        generated to share your KonnekApp. Anyone can publicly
                        find and view your KonnekApp, its playlists and content
                        with the 'Join URL'. <br /> <br /> Unlisted: When
                        Unlisted is selected a 'Join URL' is generated to share
                        your KonnekApp. Only users with your 'Join URL' can join
                        and view your KonnekApp, its playlists and content.{" "}
                        <br />
                        <br />
                        Private: When Private is selected a 'Join URL' is NOT
                        generated to share your KonnekApp. Only you and your
                        Admins can view your KonnekApp, its playlists and
                        content. You must selected either Unlisted or Public to
                        generate a 'Join URL'.
                      </>
                    }
                    value={formProps.values.privacy || "public"}
                    name="privacy"
                    options={[
                      {
                        title: "Public",
                        value: "public",
                      },
                      {
                        title: "Private",
                        value: "private",
                      },
                      {
                        title: "Unlisted",
                        value: "unlisted",
                      },
                    ]}
                    displayOption={(
                      privacy: { title: string; value: string },
                      index: number
                    ) => (
                      <option key={privacy.value} value={privacy.value}>
                        {privacy.title}
                      </option>
                    )}
                  />
                  {selectedOrganization?.privacy === Privacy.PUBLIC &&
                    !!selectedOrganization?.publicJoinUrl && (
                      <HStack justifyContent="center">
                        <TextField
                          type="text"
                          label="Join Url"
                          disabled={true}
                          onChange={formProps.handleChange}
                          name="joinUrl"
                          inputRightElement={
                            <KonnekAppTooltip label="Click to copy! (Share your Join URL anywhere and get new users to join your KonnekApp.)">
                              <IconButton
                                variant="ghost"
                                color="gray"
                                onClick={copyToClipboard}
                                aria-label="Copy"
                                icon={<AiFillCopy fontSize="24px" />}
                              />
                            </KonnekAppTooltip>
                          }
                          value={selectedOrganization?.publicJoinUrl}
                        />
                      </HStack>
                    )}
                  <Box>
                    {formProps.isSubmitting ? (
                      <Spinner color="brand" />
                    ) : (
                      <Button
                        colorScheme="primary"
                        disabled={formProps.isSubmitting}
                        type={"submit"}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <Box w="55%">
            <Box>
              <Heading>Branding</Heading>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={6}
                mt="12px"
              >
                <ColorPicker
                  label="Background"
                  hexValue={organization?.theme?.primaryColor!}
                  onValueChange={setPrimaryColor}
                />
                <ColorPicker
                  label="Button color"
                  hexValue={organization?.theme?.secondaryColor!}
                  onValueChange={setSecondaryColor}
                />
                <ColorPicker
                  label="Text color"
                  hexValue={organization?.theme?.brandTextColor}
                  onValueChange={setBrandTextColor}
                />
              </Stack>
            </Box>
            <Stack
              direction={{ base: "column", xl: "row" }}
              mt="42px"
              spacing={6}
            >
              <WelcomeScreen
                logoUrl={organization?.logoUrl}
                backgroundUrl={organization?.theme?.backgroundUrl}
                secondaryColor={organization?.theme?.secondaryColor}
                description={organization?.description}
              />
              <IntroScreen
                primaryColor={organization?.theme?.primaryColor}
                secondaryColor={organization?.theme?.secondaryColor}
                textColor={organization?.theme?.brandTextColor}
                logoUrl={organization?.logoUrl}
              />
            </Stack>
          </Box>
        </Stack>
      </Box>
    </AppLayout>
  );
};

export default inject("store")(observer(OrganizationProfile));
