import React, { ReactElement, useEffect, useState } from "react";
import { OrganizationPolicy } from "../enums/access-policy";
import {
  Avatar,
  Box,
  Button,
  DrawerContent,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  useDisclosure,
  usePrefersReducedMotion,
  keyframes,
  Tag,
} from "@chakra-ui/react";
import { Route, Switch, useHistory, useLocation } from "react-router";
import OrganizationContainer from "./organization-container/OrganizationContainer";
import PageNotFound from "../errors/page-not-found/PageNotFound";
import useSnackBar from "../hooks/useSnackBar";
import useLogout from "../hooks/useLogout";
import { inject, observer } from "mobx-react";
import {
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineExpandMore,
  MdHome,
  MdVideoLibrary,
  MdPeople,
  MdPerson,
  MdDomain,
  MdOutlineSettings,
  MdMenu,
  MdAttachMoney,
  MdPieChart,
} from "react-icons/md";
import { BsBriefcaseFill } from "react-icons/bs";
import { OrganizationRoleModel } from "../models/OrganizationRoleModel";
import KonnekAppTooltip from "../shared/tooltip/Tooltip";
import { CommonUtils } from "../utils/CommonUtils";
import AuthenticatedRoute from "../guards/AuthenticatedRoutes";
import UserProfile from "../profile/UserProfile";
import BillingManagement from "../billing-management/BillingManagement";
import Settings from "../settings/Settings";
import PrivacyTag from "../shared/privacy-tag/PrivacyTag";

const BASE_URL = process.env.REACT_APP_URL;
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 100; }
`;

const Dashboard = (props: any) => {
  const rootStore = props.store!;
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion ? undefined : `${fadeIn} 0.6s linear`;
  const orgRoleStore = rootStore.organizationRoleStore;
  const loggedInUser = rootStore.authStore?.loggedInUser;
  const selectedOrgId = orgRoleStore!.selectedOrganizationId;
  const selectedOrg = orgRoleStore.selectedOrganization;

  const history = useHistory();
  const location = useLocation();
  const notify = useSnackBar();
  const logout = useLogout();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const menItems = [
    {
      title: "Home",
      icon: MdHome,
      urlPath: `/org/${selectedOrgId}/home`,
      policy: OrganizationPolicy.ORGANIZATIONS_ANALYTICS,
      disabled: false,
    },
    {
      title: "Content",
      icon: MdVideoLibrary,
      urlPath: `/org/${selectedOrgId}/playlists`,
      policy: OrganizationPolicy.PLAYLIST_LIST,
      disabled: false,
    },
    {
      title: "Users",
      icon: MdPeople,
      urlPath: `/org/${selectedOrgId}/users`,
      policy: OrganizationPolicy.ORGANIZATIONS_USERS_LIST,
      disabled: false,
    },
    {
      title: "Analytics",
      icon: MdPieChart,
      urlPath: `/org/${selectedOrgId}/analytics`,
      policy: OrganizationPolicy.ORGANIZATION_UPDATE,
      disabled: false,
    },
    {
      title: "Leads",
      icon: BsBriefcaseFill,
      urlPath: `/org/${selectedOrgId}/leads`,
      policy: OrganizationPolicy.ORGANIZATION_UPDATE,
      disabled: false,
    },
    {
      title: "Profile",
      icon: MdPerson,
      urlPath: `/user-profile`,
      policy: "",
      disabled: false,
    },
    {
      title: "Organization",
      icon: MdDomain,
      urlPath: `/org/${selectedOrgId}/profile`,
      policy: OrganizationPolicy.ORGANIZATIONS_ANALYTICS,
      disabled: false,
    },
    {
      title: "Billing",
      icon: MdAttachMoney,
      urlPath: `/billing`,
      disabled: false,
    },
    {
      title: "Settings",
      icon: MdOutlineSettings,
      urlPath: `/settings`,
      policy: "",
      disabled: false,
    },
  ];

  const navigateToUrl = (path: string) => {
    history.push(path);
  };

  const changeSelectedOrganization = (orgId: string) => {
    if (!orgId) {
      return;
    }
    // const route = location.pathname.split("/");
    // const path = route.slice(3).join("/");
    orgRoleStore?.setSelectedOrganizationId(orgId);
    // FIXME: Find a better way of dealing with this, specially in case when the route path does not contain orgId. For ex. /billing
    const url = `${BASE_URL}/org/${orgId}/home`;
    window.open(url, "_blank");
  };

  const getSelectedOrganizationName = () => {
    let orgName = orgRoleStore?.selectedOrganization?.name;
    if (!orgName) {
      return "N/A";
    }
    if (orgName?.length > 18) {
      orgName = orgName?.substr(0, 18) + "...";
    }
    return orgName;
  };

  const getDisplayName = () => {
    let displayName = loggedInUser?.displayName || loggedInUser?.email || "";
    if (displayName?.length > 18) {
      displayName = displayName.substr(0, 18) + "...";
    }
    return displayName;
  };

  const bootstrapApp = async () => {
    try {
      await orgRoleStore?.listUserOrganizations();
      if (!orgRoleStore?.hasAccessToDashboard) {
        return history.push(`/no-plan`);
      }
      if (location.pathname === "/") {
        orgRoleStore.setSelectedOrganizationId();
        const selectedOrgId = orgRoleStore!.selectedOrganizationId;
        history.push(`/org/${selectedOrgId}/home`);
      }
    } catch (error) {
      notify("error", "Error bootstrapping app");
    }
  };

  useEffect(() => {
    bootstrapApp();
    // eslint-disable-next-line
  }, []);

  const getMenuItems = () =>
    menItems.map((item) => (
      <HStack
        key={item.title}
        onClick={() => {
          if (item.urlPath) {
            navigateToUrl(item.urlPath);
          }
          onClose();
        }}
        spacing={6}
        my="24px"
        color={location.pathname === item.urlPath ? "brand" : "gray"}
        cursor="pointer"
      >
        <KonnekAppTooltip label={item.title}>
          <span>
            <Icon fontSize="26px" cursor="pointer" as={item.icon} />
          </span>
        </KonnekAppTooltip>

        {isDrawerOpen && <Text fontSize="sm">{item.title}</Text>}
      </HStack>
    ));

  const getDrawerFooter = () => (
    <Box>
      <HStack spacing={4}>
        <Avatar
          size="sm"
          color="white"
          backgroundColor="secondary.500"
          name={CommonUtils.getUserDisplayDetail(loggedInUser!)}
          src={loggedInUser?.photoURL || ""}
        />
        {isDrawerOpen && (
          <>
            {" "}
            {orgRoleStore?.isOrganizationRolesLoading ||
            !orgRoleStore?.isOrganizationRolesLoaded ? (
              <Spinner size="xs" />
            ) : (
              <VStack alignItems="flex-start">
                <Heading fontSize="sm">{getDisplayName()}</Heading>
                <HStack>
                  <Text fontSize="xs">{getSelectedOrganizationName()}</Text>
                  <Menu placement="auto">
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          boxSize="20px"
                          _hover={{
                            backgroundColor: "white",
                          }}
                          _active={{
                            outline: "none",
                            backgroundColor: "white",
                          }}
                          _focus={{
                            outline: "none",
                            backgroundColor: "white",
                          }}
                          variant="ghost"
                          as={IconButton}
                          icon={<MdOutlineExpandMore />}
                        />
                        <MenuList>
                          {orgRoleStore?.roleSelection.map(
                            (orgRole: OrganizationRoleModel) => (
                              <MenuItem
                                key={orgRole.uid}
                                onClick={() =>
                                  changeSelectedOrganization(
                                    orgRole.organisation.uid
                                  )
                                }
                                _hover={{ backgroundColor: "white" }}
                              >
                                <HStack spacing={2}>
                                  <Image
                                    boxSize="30px"
                                    src={
                                      orgRole.organisation.logoUrl ||
                                      "/assets/images/org-logo.svg"
                                    }
                                  />
                                  <Text
                                    css={
                                      orgRole.organisation.uid ===
                                        selectedOrgId && {
                                        color: "#491bc9",
                                        fontWeight: 700,
                                      }
                                    }
                                    fontSize="sm"
                                  >
                                    {orgRole.organisation.name}
                                  </Text>
                                </HStack>
                              </MenuItem>
                            )
                          )}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </HStack>
              </VStack>
            )}
          </>
        )}
      </HStack>
      {isDrawerOpen && (
        <Button
          mt="20px"
          size="sm"
          fontWeight={400}
          variant="ghost"
          color="gray"
          onClick={logout}
        >
          Logout
        </Button>
      )}
    </Box>
  );

  const getMobileDrawer = () => (
    <Drawer
      isOpen={isOpen}
      size="xs"
      placement="left"
      onClose={onClose}
      variant=""
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Image
            src="/assets/icons/konnek-brand-black.png"
            width="120px"
            fit="contain"
            alt="KonnekApp image"
          />
        </DrawerHeader>

        <DrawerBody>
          <Box my="10px">
            {!!selectedOrg && isDrawerOpen && (
              <PrivacyTag privacy={selectedOrg.privacy} />
            )}
          </Box>
          {getMenuItems()}
          {getDrawerFooter()}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  return (
    <>
      <Box padding="8px" display={{ base: "block", md: "none" }}>
        <IconButton
          onClick={onOpen}
          variant="ghost"
          color="secondary.500"
          aria-label="menu"
          icon={<MdMenu size="24px" />}
        />
        {getMobileDrawer()}
      </Box>
      <Flex width="100%" height="100vh" overflow="hidden">
        <Box
          display={{ base: "none", md: "block" }}
          position="relative"
          boxShadow="0 0 25px 0 rgba(0, 0, 0, 0.09)"
          width={isDrawerOpen ? "270px" : "90px"}
          transition="width 0.3s"
          padding="26px 32px"
        >
          {isDrawerOpen ? (
            <>
              <Image
                src="/assets/icons/konnek-brand-black.png"
                width="120px"
                fit="contain"
                alt="KonnekApp image"
              />
            </>
          ) : (
            <>
              <Image
                src="/assets/icons/favicon.png"
                boxSize="40px"
                fit="contain"
                alt="KonnekApp image"
              />
            </>
          )}
          <Box my="10px">
            {!!selectedOrg && isDrawerOpen && (
              <PrivacyTag privacy={selectedOrg.privacy} />
            )}
          </Box>

          <Box mt="20px">{getMenuItems()}</Box>

          {getDrawerFooter()}
          <Box
            position="absolute"
            right="-16px"
            top="40%"
            width="36px"
            height="36px"
            boxShadow="0 0 25px 0 rgba(0, 0, 0, 0.09)"
            backgroundColor="#ffffff"
            animation={animation}
            borderRadius="50%"
            border="2px solid #FF9900"
            bottom="60%"
            zIndex={3}
          >
            {isDrawerOpen ? (
              <Icon
                onClick={() => setIsDrawerOpen(false)}
                fontSize="32px"
                color="brand"
                cursor="pointer"
                as={MdOutlineChevronLeft}
              />
            ) : (
              <Icon
                onClick={() => setIsDrawerOpen(true)}
                fontSize="32px"
                color="brand"
                cursor="pointer"
                as={MdOutlineChevronRight}
              />
            )}
          </Box>
        </Box>
        <Box width="inherit" overflowY="scroll" overflowX="hidden">
          {orgRoleStore?.isOrganizationRolesLoading ||
          !orgRoleStore?.isOrganizationRolesLoaded ? (
            <Spinner
              pos="absolute"
              top="50%"
              left="50%"
              transform="translateY(-50%, -50%)"
            />
          ) : (
            <Switch>
              <Route path="/org/:orgId" component={OrganizationContainer} />
              <AuthenticatedRoute
                path="/user-profile"
                component={UserProfile}
              />
              <AuthenticatedRoute
                path="/billing"
                component={BillingManagement}
              />
              <AuthenticatedRoute path="/settings" component={Settings} />
              <Route
                render={(props) => (
                  <div>
                    <PageNotFound />
                  </div>
                )}
              />
            </Switch>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default inject("store")(observer(Dashboard));
