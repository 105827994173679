import React, { useEffect, useState } from "react";
import PolicyProtectedRoute from "../../guards/PolicyProtectedRoutes";
import { OrganizationPolicy } from "../../enums/access-policy";
import Home from "../../home/Home";
import PlaylistDetail from "../../content/playlist-detail/PlaylistDetail";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import { Route, useParams } from "react-router";
import { OrganizationRoleModel } from "../../models/OrganizationRoleModel";
import PageNotFound from "../../errors/page-not-found/PageNotFound";
import AuthenticatedRoute from "../../guards/AuthenticatedRoutes";
import { OrganizationRoleStore } from "../../stores/OrganizationRoleStore";
import UserProfile from "../../profile/UserProfile";
import OrganizationProfile from "../../organization-profile/OrganizationProfile";
import Users from "../../users/Users";
import Settings from "../../settings/Settings";
import AnalyticsPage from "../../analytics/AnalyticsPage";
import LeadsPage from "../../analytics/LeadsPage";
import Playlists from "../../content/playlist-list/Playlists";

/**
 * Assert that an organisation ID was passed in parameters,
 * and that the store matches the selected organization
 *
 * @param params Organization container parameters
 * @param orgStore Organiszation role store
 * @param callback Callback to return validation boolean
 * @returns True if validation passes, false otherwise
 */
const validateRoute = (
  params: OrganizationContainerParams,
  orgStore: OrganizationRoleStore,
  callback: { (valid: boolean): void }
) => {
  if (!params.orgId) {
    return callback(false);
  }
  if (params.orgId !== orgStore.selectedOrganizationId) {
    const roles = orgStore.entities as OrganizationRoleModel[];
    const isPartOfOrganization = roles.some(
      (r) => r.organisation.id === params.orgId
    );
    if (!isPartOfOrganization) {
      return callback(false);
    } else {
      orgStore.setSelectedOrganizationId(params.orgId);
    }
  }
  callback(true);
};

type OrganizationContainerParams = {
  orgId: string;
};

const OrganizationContainer: React.FC<GlobalProps> = (props: GlobalProps) => {
  const [isRouteValid, setIsRouteValid] = useState(true);
  const params = useParams<OrganizationContainerParams>();
  const rootStore = props.store!;
  const orgRoleStore = rootStore.organizationRoleStore!;

  useEffect(() => {
    validateRoute(params, orgRoleStore, setIsRouteValid);
    // eslint-disable-next-line
  }, [params, orgRoleStore, setIsRouteValid]);

  return isRouteValid ? (
    <>
      <PolicyProtectedRoute
        policy={OrganizationPolicy.ORGANIZATIONS_ANALYTICS}
        role={orgRoleStore?.selectedOrgUserRole!}
        path="/org/:orgId/home"
        component={Home}
      />
      <PolicyProtectedRoute
        exact
        policy={OrganizationPolicy.ORGANIZATIONS_USERS_LIST}
        role={orgRoleStore?.selectedOrgUserRole!}
        path="/org/:orgId/users"
        component={Users}
      />
      <PolicyProtectedRoute
        exact
        policy={OrganizationPolicy.PLAYLIST_LIST}
        role={orgRoleStore?.selectedOrgUserRole!}
        path="/org/:orgId/playlists"
        component={Playlists}
      />
      <PolicyProtectedRoute
        exact
        policy={OrganizationPolicy.ORGANIZATIONS_ANALYTICS}
        role={orgRoleStore?.selectedOrgUserRole!}
        path="/org/:orgId/analytics"
        component={AnalyticsPage}
      />
      <PolicyProtectedRoute
        exact
        policy={OrganizationPolicy.PLAYLIST_LIST}
        role={orgRoleStore?.selectedOrgUserRole!}
        path="/org/:orgId/playlists/:playlistId"
        component={PlaylistDetail}
      />

      <PolicyProtectedRoute
        exact
        policy={OrganizationPolicy.ORGANIZATION_UPDATE}
        role={orgRoleStore?.selectedOrgUserRole!}
        path="/org/:orgId/profile"
        component={OrganizationProfile}
      />
      <PolicyProtectedRoute
        exact
        policy={OrganizationPolicy.ORGANIZATIONS_ANALYTICS}
        role={orgRoleStore?.selectedOrgUserRole!}
        path="/org/:orgId/leads"
        component={LeadsPage}
      />
    </>
  ) : (
    <Route render={(props) => <PageNotFound />} />
  );
};

export default inject("store")(observer(OrganizationContainer));
