import { UserFilter } from "../enums/user-filter";
import { UserRole } from "../enums/user-roles";
import { BusinessPlan } from "../enums/business-plan";
import { Privacy } from "../enums/privacy";

export type EnumTitleMap = { [key: string]: string };

export class EnumUtils {
  private static USERS_FILTER_TITLE: EnumTitleMap = {
    [UserFilter.ALL_USERS_GDPR]: "All Users GDRP",
    [UserFilter.ALL_NEW_USERS]: "All New Users",
    [UserFilter.ALL_USERS]: "All Users",
  };

  private static ROLE_TITLE: EnumTitleMap = {
    [UserRole.SUPER_ADMIN]: "Super Admin",
    [UserRole.ACCOUNT_ADMIN]: "Account Admin",
    [UserRole.TEAM_LEADER]: "Team Leader",
    [UserRole.MANAGER]: "Manager",
    [UserRole.TEAM_USER]: "Team User",
    [UserRole.USER]: "User",
  };
  private static PLAN_TITLE: EnumTitleMap = {
    [BusinessPlan.ESSENTIALS_MONTHLY]: "Essentials Monthly",
    [BusinessPlan.ESSENTIALS_ANNUAL]: "Essentials Annual",
    [BusinessPlan.PROFESSIONAL_MONTHLY]: "Professional Monthly",
    [BusinessPlan.PROFESSIONAL_ANNUAL]: "Professional Annual",
  };
  private static PRIVACY_TITLE = {
    [Privacy.PRIVATE]: "Private",
    [Privacy.PUBLIC]: "Public",
    [Privacy.UNLISTED]: "Unlisted",
  };

  static getUserFilterTitle(filter: UserFilter): string {
    return this.USERS_FILTER_TITLE[filter];
  }

  static getUserRoleTitle(role: UserRole): string {
    return this.ROLE_TITLE[role];
  }

  static getPlanTitle(plan: BusinessPlan): string {
    return this.PLAN_TITLE[plan];
  }

  static getPrivacyTitle(privacy: Privacy): string {
    return this.PRIVACY_TITLE[privacy];
  }
}
