import Model from "./Model";
import { action, observable } from "mobx";
import { UserModel } from "./UserModel";
import { UserRoleStore } from "../stores/UserRoleStore";
import { UserRole } from "../enums/user-roles";

export class UserRoleModel extends Model {
  uid!: string;

  static _store: UserRoleStore;

  @observable orgId!: string;
  @observable role!: UserRole;
  @observable timeCreated!: string;
  @observable userId!: string;
  @observable user!: UserModel;

  @action
  deserializeUser(data: any) {
    this.user = UserModel.fromJson(data) as UserModel;
  }

  getId(): string {
    return this.uid;
  }
}
