export enum OrganizationPolicy {
  // Organizations

  ORGANIZATION_READ = "organisations:read",
  ORGANIZATION_LIST = "organisations:list",
  ORGANIZATION_CREATE = "organisations:create",
  ORGANIZATION_UPDATE = "organisations:update",
  ORGANIZATION_DELETE = "organisations:delete",

  // Roles

  ORGANIZATION_ROLES_LIST = "organisations.roles:list",
  ORGANIZATION_ROLES_CREATE = "organisations.roles:create",
  ORGANIZATION_ROLES_DELETE = "organisations.roles:delete",
  ORGANIZATION_ROLES_ADMIN_LIST = "organisations.roles.admin:list",
  ORGANIZATION_ROLES_ADMIN_CREATE = "organisations.roles.admin:create",
  ORGANIZATION_ROLES_ADMIN_DELETE = "organisations.roles.admin:delete",

  // Playlist

  PLAYLIST_LIST = "playlists:list",
  PLAYLIST_CREATE = "playlists:create",
  PLAYLIST_UPDATE = "playlists:update",
  PLAYLIST_DELETE = "playlists:delete",
  PLAYLIST_READ = "playlists:read",
  PLAYLIST_CONTENT_LIST = "playlists.content:list",

  // Content

  ORGANIZATION_CONTENT_READ = "content:read",
  ORGANIZATION_CONTENT_LIST = "content:list",
  ORGANIZATION_CONTENT_CREATE = "content:create",
  ORGANIZATION_CONTENT_UPDATE = "content:update",
  ORGANIZATION_CONTENT_DELETE = "content:delete",

  // Users
  ORGANIZATIONS_USERS_LIST = "organisations.users:list",
  ORGANIZATIONS_USERS_CONSENT_LIST = "organisations.users.consent:list",
  ORGANIZATIONS_USERS_CONSENT_UPDATE = "organisations.users.consent:update",

  // Analytics

  ORGANIZATIONS_ANALYTICS = "organisations.analytics",
}
