import React from "react";
import { Box, Text, Heading, Button, Flex, Spinner } from "@chakra-ui/react";
import { BillingAccountModel } from "../../../models/BillingAccountModel";

export interface BillingAccountCardProps {
  billingAccount: BillingAccountModel;
  onSelect: () => void;
  loading: boolean;
}

const BillingAccountCard: React.FC<BillingAccountCardProps> = (
  props: BillingAccountCardProps
) => {
  const { billingAccount, onSelect, loading } = props;
  return (
    <Box borderRadius="10px" my="12px" p="16px 24px" boxShadow="lg">
      <Flex
        direction={{ md: "row", base: "column" }}
        justifyContent="space-between"
        alignItems={{ md: "center", base: "flex-start" }}
      >
        <Box>
          <Heading fontSize="xl">{billingAccount?.friendlyName}</Heading>
          <Text fontSize="xs" color="gray" mt="12px">
            Company name: {billingAccount?.billingName} <br />
            Phone no.: {billingAccount?.billingPhone} <br />
            Email: {billingAccount?.billingEmail}
          </Text>
        </Box>
        <Button
          onClick={onSelect}
          fontSize="0.8rem"
          height="30px"
          width="80px"
          marginTop={{ base: "10px", md: 0 }}
          colorScheme="primary"
        >
          {loading ? <Spinner size="xs" /> : "Continue"}
        </Button>
      </Flex>
    </Box>
  );
};

export default BillingAccountCard;
