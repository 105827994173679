import React from "react";

import { Box, Heading, HStack, Image, Text } from "@chakra-ui/react";
import { MdRemoveRedEye } from "react-icons/md";

import { inject, observer } from "mobx-react";
import TypeIcon from "./TypeIcon";
import { ContentSourceType } from "../enums/content-source-type";
import { ContentModel } from "../models/ContentModel";

/**
 * <ContentCard /> displays the thumbnail of the content, the title, and the
 * truncated description.
 */

interface ContentCardProps {
  content: ContentModel;
}
const ContentCard: React.FC<ContentCardProps> = (props) => {
  return (
    <Box
      borderRadius="6px"
      width="15rem"
      p={5}
      boxShadow="0px 0px 25px rgba(0, 0, 0, 0.08)"
      m={0}
    >
      <Box mb={3}>
        <Image
          rounded="md"
          objectFit="cover"
          height="8rem"
          width="14rem"
          src={
            props.content.previewUrl || "/assets/images/video-placeholder.jpg"
          }
        />
      </Box>

      <HStack justifyContent="space-between">
        <HStack>
          <TypeIcon
            integration={props.content.type === ContentSourceType.INTEGRATION}
            mime={props.content.file?.type || null}
          />
          <Heading
            noOfLines={2}
            as="h3"
            fontSize="sm"
            color="gray"
            width="full"
          >
            {props.content.title}
          </Heading>
        </HStack>
        <HStack>
          <MdRemoveRedEye color="gray" />
          <Text fontSize="xs" color="gray">
            {props.content.stats?.views || 0}
          </Text>
        </HStack>
      </HStack>
    </Box>
  );
};

export default inject("store")(observer(ContentCard));
