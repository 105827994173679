import React from "react";
import { GlobalProps } from "../../App";
import { inject } from "mobx-react";
import { useHistory } from "react-router";
import { authService } from "../../services/AuthService";
import useSnackBar from "../../hooks/useSnackBar";
import SignupPage from "../components/SignupPage/SignupPage";
import { firebaseService } from "../../services/FirebaseService";
import { EMAIL_FOR_SIGN_IN, REDIRECT_URL } from "../../utils/ConstantUtils";

const SignupView: React.FC<GlobalProps> = (props: GlobalProps) => {
  const history = useHistory();
  const notify = useSnackBar();
  const store = props.store;

  const signUpWithGoogle = async () => {
    try {
      await authService.signInWithGoogle();
      _navigateToRoute();
    } catch (error) {
      notify("error", "There was a problem signing in with Google");
    }
  };

  const signUpWithApple = async () => {
    try {
      await authService.signInWithApple();
      _navigateToRoute();
    } catch (error) {
      notify("error", "There was a problem signing up with Apple");
    }
  };

  const signUpWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    try {
      await authService.signUpWithEmailAndPassword(email, password);
      _navigateToRoute();
    } catch (error) {
      notify("error", "There was a problem signing in.");
    }
  };

  const sendMagicLink = async (email: string) => {
    try {
      await firebaseService.sendMagicLink(email, "signup");
      localStorage.setItem(EMAIL_FOR_SIGN_IN, email);
      localStorage.setItem(REDIRECT_URL, store?.redirectUrl!);
    } catch (error) {
      notify("error", "There was a problem sending the magic link.");
    }
  };

  const _navigateToRoute = () => {
    history.push("/on-boarding/details/user");
  };

  return (
    <>
      <SignupPage
        sendMagicLink={sendMagicLink}
        signUpWithApple={signUpWithApple}
        signUpWithGoogle={signUpWithGoogle}
        signUpWithEmailAndPassword={signUpWithEmailAndPassword}
      />
    </>
  );
};

export default inject("store")(SignupView);
