import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import Lottie from "react-lottie";
import animationData from "./lotties/no_content.json";
export interface NoContentProps {
  onAddContent: () => void;
}

export const NoContentFallback: React.FC<NoContentProps> = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Box>
      <Flex
        width="100%"
        minH={{ base: "200px", md: "190px" }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Lottie options={defaultOptions} height="20rem" width="20rem" />
        <Heading mb={4}>This playlist has no content.</Heading>
      </Flex>
    </Box>
  );
};
