import React from "react";
import { RootStore } from "../RootStore";
import { observer } from "mobx-react";
import { StoreContext } from "./context";

/**
 * inject fills a PureComponent with props mapped from the store.
 *
 *
 * @example
 *
 *		function TodosView ({ todos }) {
 *			return (
 *				<>
 *					{ todos.map( todo => <li>{todo}</li> )}
 * 				</>
 *			)
 *		}
 *
 *		export default inject( store => ({ todos: store.todos }), TodosView )
 *
 *
 * ## Limitations
 *
 * `inject` does not work with setters. No errors are thrown, but setters won't
 * pass through the function.
 *
 *
 * @param mapper - `mapper` maps store to props. `inject` will provide the
 * store to this function. The return value of `mapper` is passed into the
 * wrapped component (`PureComponent`).
 * @param PureComponent - A React functional component. The props of this
 * component must be the same as the return type of `mapper`.
 * @returns The PureComponent with props mapped from the store.
 */

export function inject<P>(
  mapper: (store: RootStore) => P,
  PureComponent: React.FC<P>
): React.FC {
  const Component = () => {
    const store = React.useContext(StoreContext);
    const props = mapper(store);

    return <PureComponent {...props} />;
  };

  Component.displayName = PureComponent.name;
  return observer(Component);
}
