import React from "react";
import { MdDelete } from "react-icons/md";
import { Button } from "@chakra-ui/react";

interface DeleteButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const DeleteButton: React.FC<DeleteButtonProps> = (
  props: DeleteButtonProps
) => {
  return (
    <Button
      backgroundColor="rgba(219, 44, 47, 0.1)"
      color="#DB2C2F"
      leftIcon={<MdDelete size="18px" />}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default DeleteButton;
