import {
  Box,
  Text,
  Input,
  HStack,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { HTMLInputTypeAttribute, ReactNode } from "react";
import { MdInfoOutline } from "react-icons/md";

interface TextFieldProps {
  name: string;
  value: string;
  placeholder?: string;
  label?: string;
  error?: string | undefined;
  type: HTMLInputTypeAttribute;
  inputRightElement?: ReactNode;
  suggestedLength?: number;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const {
    placeholder,
    label,
    name,
    value,
    type,
    onChange,
    error,
    disabled,
    inputRightElement,
  } = props;
  return (
    <Box w="100%" mb="8px">
      {label && (
        <FormLabel
          mb="2px"
          color="gray.500"
          fontWeight="500"
          fontSize="xs"
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Input
          variant="outline"
          backgroundColor="actionAreaBackgroundColor"
          _placeholder={{ color: "gray.500" }}
          border="2px solid #E1E1E1"
          width="100%"
          height="48px"
          value={value}
          placeholder={placeholder}
          type={type}
          name={name}
          id={name}
          disabled={!!disabled}
          onChange={onChange}
        />
        {inputRightElement && (
          <InputRightElement mt="4px">{inputRightElement}</InputRightElement>
        )}
      </InputGroup>

      <HStack justifyContent="space-between" mt="1">
        {!!error ? (
          <Text my="2px" fontSize="xs" color="error">
            {error}
          </Text>
        ) : (
          <div />
        )}
      </HStack>
      {props.suggestedLength && (
        <HStack>
          <MdInfoOutline size="14px" color="gray" />
          <Text fontSize="0.6rem" fontWeight="400" color="gray">
            Max {props.suggestedLength} characters
          </Text>
        </HStack>
      )}
    </Box>
  );
};

export default TextField;
