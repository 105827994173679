import React from "react";
import * as Sentry from "@sentry/react";
import FallBack from "../fall-back-ui/FallBack";

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const env = process.env.REACT_APP_ENV;
    if (env === "production" || env === "staging") {
      Sentry.captureException(error);
    }
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <FallBack />;
    } else {
      return this.props.children;
    }
  }
}
