import React, { useEffect, useState } from "react";
import AppLayout from "../shared/app-layout/AppLayout";
import { MdAttachMoney } from "react-icons/md";
import { Box, Center, Heading, Text, Icon, Spinner } from "@chakra-ui/react";
import { stripeService } from "../services/StripeService";
import BillingAccountCard from "../onboarding/components/BillingAccountCard/BillingAccountCard";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../App";
import useSnackBar from "../hooks/useSnackBar";
import { HttpError } from "../services/ApiService";

const BillingManagement: React.FC<GlobalProps> = (props: GlobalProps) => {
  const billingAccountStore = props.store?.billingAccountStore;
  const notify = useSnackBar();
  const [urlLoading, setUrlLoading] = useState<{
    id: string | undefined;
    loading: boolean;
  }>({
    id: undefined,
    loading: false,
  });

  const manage = async (accId: string) => {
    setUrlLoading({
      id: accId,
      loading: true,
    });
    try {
      const res: any = await stripeService.generateBillingManagementSession(
        accId
      );
      if (!!res && res.url) {
        window.open(res.url, "_blank");
      }
    } catch (err) {
      notify("error", (err as HttpError).message);
    } finally {
      setUrlLoading({ id: undefined, loading: false });
    }
  };

  useEffect(() => {
    billingAccountStore?.fetchBillingAccounts();
  }, [billingAccountStore]);
  return (
    <AppLayout
      title="Billing Management"
      description={<>Manage all your linked billing accounts here.</>}
      startLogo={<Icon fontSize="4rem" as={MdAttachMoney} />}
      colorScheme="primary"
    >
      <>
        <Heading mb="22px" fontSize="lg">
          Your billing accounts
        </Heading>

        {billingAccountStore?.isLoading ? (
          <Center>
            <Spinner size="sm" />
          </Center>
        ) : billingAccountStore?.entities &&
          billingAccountStore.entities.length > 0 ? (
          <Box width={{ base: "100%", lg: "70%" }}>
            {billingAccountStore.entities.map((acc) => (
              <BillingAccountCard
                key={acc.uid}
                loading={urlLoading.id === acc.uid && urlLoading.loading}
                billingAccount={acc}
                onSelect={() => manage(acc.uid)}
              />
            ))}
          </Box>
        ) : (
          <Center>
            <Text>You don't have any billing account!</Text>
          </Center>
        )}
      </>
    </AppLayout>
  );
};

export default inject("store")(observer(BillingManagement));
