import React from "react";
import { BiSearchAlt } from "react-icons/all";
import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

export interface SearchBarProps {
  onChange: (value: string) => void;
  width?: string;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
  return (
    <InputGroup
      size="sm"
      width={props.width || "100%"}
      backgroundColor="primary.100"
      borderRadius="10px"
      border="none"
    >
      <InputLeftElement
        pointerEvents="none"
        children={<Icon as={BiSearchAlt} color="brand" />}
      />
      <Input
        size="sm"
        borderRadius="10px"
        border="none"
        id="search"
        name="search"
        onChange={(e) => props.onChange(e.target.value)}
        placeholder="Search"
        color="brand"
      />
    </InputGroup>
  );
};

export default SearchBar;
