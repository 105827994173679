import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import useSnackBar from "../../hooks/useSnackBar";
import Button from "../../shared/button/Button";
import {
  Text,
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  VStack,
  Heading,
  HStack,
  Grid,
  Spinner,
  Center,
} from "@chakra-ui/react";
import {
  MdExpandMore,
  MdInsertDriveFile,
  MdPlaylistPlay,
  MdVideocam,
  MdVideoLibrary,
} from "react-icons/md";
import { RolesUtils } from "../../utils/RolesUtils";
import { OrganizationPolicy } from "../../enums/access-policy";
import InfiniteScroll from "react-infinite-scroll-component";
import AppLayout from "../../shared/app-layout/AppLayout";
import CreateContentDialog from "../create-content-dialog/CreateContentDialog";
import CreatePlaylistDialog from "../create-playlist-dialog/CreatePlaylistDialog";
import Playlist from "../../playlists/components/Playlist/Playlist";
import PinnedPlaylist from "../../playlists/components/PinnedPlaylist/PinnedPlaylist";
import { useHistory } from "react-router";

const Playlists: React.FC<GlobalProps> = (props: GlobalProps) => {
  const notify = useSnackBar();
  const history = useHistory();
  const [uploadType, setUploadType] = useState<"file" | "url">("file");

  const playlistStore = props.store?.playlistStore;
  const role = props.store!.organizationRoleStore?.selectedOrgUserRole!;
  const orgName = props.store?.organizationRoleStore?.selectedOrganization.name;
  const orgId = props.store?.organizationRoleStore?.selectedOrganization.id;

  // Dialogs
  const createPlaylistDialogController = useDisclosure();
  const addContentDialogController = useDisclosure();
  // Used when add content dialog is opened from playlist created
  const [createdPlaylistId, setCreatedPlaylistId] = useState<
    string | undefined
  >();

  useEffect(() => {
    playlistStore?.listPlaylists().catch((err) => {
      notify(
        "error",
        "There was a problem fetching your playlists. Try refreshing the page."
      );
    });
  }, [playlistStore, notify]);

  const fetchMorePlaylists = () => {
    playlistStore?.listMorePlaylists().catch((err) => {
      notify("error", "There was a problem loading more playlists.");
    });
  };

  const togglePinPlaylist = (playlistId: string) => {
    const pinnedPlaylistsIds = playlistStore?.pinnedPlaylistsIds;
    if (
      pinnedPlaylistsIds?.length === 4 &&
      !pinnedPlaylistsIds.includes(playlistId)
    ) {
      notify("error", "You cannot pin more than 4 playlists.");
      return;
    }
    playlistStore?.togglePinPlaylist(playlistId);
  };

  const navigateToPlaylist = (playlistId: string) => {
    history.push(`playlists/${playlistId}`);
  };

  return (
    <AppLayout
      colorScheme="secondary"
      startLogo={<Icon fontSize="4rem" as={MdVideoLibrary} />}
      title={`${orgName || "Your"} Content`}
      description={
        <>
          Here, you can easily and quickly add new content and create playlists
          to put them into. <br />
          Your content can be in any digital format from a YouTube video to an
          MP3 or a PDF. <br />
          It's easy to add and group any type of content into a single playlist.
        </>
      }
      action={
        !playlistStore?.hasNoPlaylists ? (
          <Menu>
            {
              // Must have one of these roles to see the "New" button.
              (RolesUtils.isAccessAllowed(
                role,
                OrganizationPolicy.ORGANIZATION_CONTENT_CREATE
              ) ||
                RolesUtils.isAccessAllowed(
                  role,
                  OrganizationPolicy.PLAYLIST_CREATE
                )) && (
                <MenuButton
                  colorScheme="secondary"
                  size="sm"
                  as={Button}
                  rightIcon={<Icon as={MdExpandMore} />}
                >
                  Add
                </MenuButton>
              )
            }
            <MenuList color="blackAlpha.900">
              {RolesUtils.isAccessAllowed(
                role,
                OrganizationPolicy.ORGANIZATION_CONTENT_CREATE
              ) && (
                <>
                  <MenuItem
                    icon={<MdInsertDriveFile size="22px" />}
                    onClick={() => {
                      setUploadType("file");
                      addContentDialogController.onOpen();
                    }}
                  >
                    Add file
                  </MenuItem>
                  <MenuItem
                    icon={<MdVideocam size="22px" />}
                    onClick={() => {
                      setUploadType("url");
                      addContentDialogController.onOpen();
                    }}
                  >
                    Add video
                  </MenuItem>
                </>
              )}
              {RolesUtils.isAccessAllowed(
                role,
                OrganizationPolicy.PLAYLIST_CREATE
              ) && (
                <MenuItem
                  icon={<MdPlaylistPlay size="22px" />}
                  onClick={createPlaylistDialogController.onOpen}
                >
                  Add playlist
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        ) : (
          <></>
        )
      }
    >
      {playlistStore?.isPlaylistLoading || !playlistStore?.isPlaylistLoaded ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Box>
          <>
            {playlistStore?.hasNoPlaylists ? (
              <VStack>
                <Text color="gray">
                  This organization does not have any playlist!
                </Text>
                {RolesUtils.isAccessAllowed(
                  role,
                  OrganizationPolicy.PLAYLIST_CREATE
                ) && (
                  <Button
                    colorScheme="secondary"
                    onClick={createPlaylistDialogController.onOpen}
                  >
                    Create a playlist
                  </Button>
                )}
              </VStack>
            ) : (
              <>
                <PinnedPlaylist
                  pinnedPlaylists={playlistStore.pinnedPlaylists}
                  isPinned={(id) =>
                    playlistStore?.pinnedPlaylistsIds.includes(id)
                  }
                  togglePinPlaylist={(id) => togglePinPlaylist(id)}
                  isPinLoading={(id) => playlistStore?.isPlaylistPinning[id]}
                  onSubmit={(ids) => playlistStore?.reorderPlaylists(ids)}
                  onPlaylistClick={(id) => navigateToPlaylist(id)}
                />

                <HStack alignItems="center" mt="20px">
                  <Icon
                    color="secondary.700"
                    fontSize="20px"
                    as={MdPlaylistPlay}
                  />
                  <Heading fontSize="2xl">All playlists</Heading>
                </HStack>
                <InfiniteScroll
                  dataLength={playlistStore?.organizationPlaylists.length ?? 0}
                  next={fetchMorePlaylists}
                  hasMore={!playlistStore?.isOnLastPage}
                  loader={<></>}
                >
                  <Grid
                    templateColumns={{
                      base: "repeat(1, 1fr)",
                      lg: "repeat(2, 1fr)",
                    }}
                    gap={6}
                    padding="10px"
                  >
                    {playlistStore?.organizationPlaylists.map((p) => (
                      <Playlist
                        isPinned={playlistStore?.pinnedPlaylistsIds.includes(
                          p.uid
                        )}
                        isPinLoading={playlistStore?.isPlaylistPinning[p.uid]}
                        onPinToggle={() => {
                          togglePinPlaylist(p.uid);
                        }}
                        isReordering={false}
                        key={p.id}
                        playlist={p}
                        onClick={() => navigateToPlaylist(p.uid)}
                      />
                    ))}
                  </Grid>
                </InfiniteScroll>
              </>
            )}
          </>

          {createPlaylistDialogController.isOpen && (
            <CreatePlaylistDialog
              isOpen={true}
              onClose={createPlaylistDialogController.onClose}
              onAddFile={(playlistId) => {
                setUploadType("file");
                setCreatedPlaylistId(playlistId);
                addContentDialogController.onOpen();
                createPlaylistDialogController.onClose();
              }}
              onAddVideo={(playlistId) => {
                setUploadType("url");
                setCreatedPlaylistId(playlistId);
                addContentDialogController.onOpen();
                createPlaylistDialogController.onClose();
              }}
            />
          )}

          {addContentDialogController.isOpen && (
            <CreateContentDialog
              isOpen={true}
              playlistId={createdPlaylistId}
              onClose={addContentDialogController.onClose}
              uploadType={uploadType}
            />
          )}
        </Box>
      )}
    </AppLayout>
  );
};

export default inject("store")(observer(Playlists));
