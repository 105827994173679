import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { Box, Text } from "@chakra-ui/react";

export interface ColorPickerProps {
  hexValue: string;
  onValueChange: (val: string) => void;
  label: string;
}

const ColorPicker: React.FC<ColorPickerProps> = (props: ColorPickerProps) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <Box
      width="120px"
      overflow="visible"
      display="flex"
      flexDirection="column"
      alignItems="center"
      backgroundColor="actionAreaBackgroundColor"
      border="2px solid #E1E1E1"
      padding="18px"
      borderRadius="12px"
    >
      <Box
        width="60px"
        height="60px"
        boxShadow="0 2px 8px gray"
        position="relative"
        borderRadius="12px"
        backgroundColor={props.hexValue || ""}
        onClick={toggleOpen}
      />
      <Text fontSize="xs" mt="12px">
        {props.label}
      </Text>
      <Box pos="absolute" zIndex={2} ref={wrapperRef}>
        {open && (
          <ChromePicker
            disableAlpha={false}
            color={props.hexValue || ""}
            onChange={(color, event) => {
              props.onValueChange(color?.hex);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ColorPicker;
