import React from "react";
import { Tag, TagLeftIcon } from "@chakra-ui/react";
import { Privacy } from "../../enums/privacy";
import { EnumUtils } from "../../utils/EnumUtils";
import {
  MdOutlineLink,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";

export interface PrivacyTagProps {
  privacy: Privacy;
}

const PrivacyTag: ({ privacy }: PrivacyTagProps) => JSX.Element = ({
  privacy,
}: {
  privacy: Privacy;
}) => {
  const colorScheme = () => {
    if (privacy === Privacy.PUBLIC) {
      return "green";
    } else if (privacy === Privacy.PRIVATE) {
      return "red";
    } else return "secondary";
  };

  const icon = () => {
    if (privacy === Privacy.PUBLIC) {
      return MdOutlineVisibility;
    } else if (privacy === Privacy.PRIVATE) {
      return MdOutlineVisibilityOff;
    } else return MdOutlineLink;
  };
  return (
    <Tag padding="2px 8px" colorScheme={colorScheme()} fontSize="xs">
      <TagLeftIcon as={icon()} />
      {EnumUtils.getPrivacyTitle(privacy)}
    </Tag>
  );
};

export default PrivacyTag;
