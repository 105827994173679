import { createHash } from "crypto";
import { apiService } from "./ApiService";

/**
 * Upload service implementing public uploads, e.g. organisation content
 * thumbnails or user profile pictures
 */
export class UploadService {
  static getInstance(): UploadService {
    return new UploadService();
  }

  /**
   * Given a File object, return its md5 hash base64 digest
   *
   * @param file File object to hash
   * @param hashName Hash method to use (default md5)
   * @returns Hash checksum
   */
  static async getChecksum(file: File, hashName = "md5"): Promise<string> {
    return new Promise((resolve, reject) => {
      // Create a React-compatible FileReader (note: fs is not available)
      const reader = new FileReader();

      // Set up a callback to work out a digest when file is loaded
      reader.onloadend = (_) => {
        // Assert file contents are available
        const { result } = reader;
        if (!result) return reject(`Cannot read file ${file.name}`);

        // Create an update hasher with file contents
        const buffer = Buffer.from(result);
        const hasher = createHash(hashName);
        hasher.update(buffer);

        // Output the base64 hash digest
        const digest = hasher.digest("base64");
        return resolve(digest);
      };

      // Reject on errors
      reader.onerror = reject;

      // Kick off the reader
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * Given a File object, create an upload and return its ingest
   * and CDN URLs
   *
   * @param file File to upload
   * @param orgId Optional organisation ID to link to
   * @returns Pair of URLs, [0] to upload, [1] to access
   */
  private async getUploadUrl(
    file: File,
    orgId?: string
  ): Promise<[string, string]> {
    const { name, lastModified, size, type } = file;
    const objectResponse = await apiService.post("/uploads", {
      file: {
        name,
        lastModified,
        size,
        type,
        checksum: await UploadService.getChecksum(file),
      },
      orgId,
    });
    const response = await JSON.parse(JSON.stringify(objectResponse));
    const { url, path } = response["ingest"];
    return [url, path];
  }

  /**
   * Given a File object, upload it against optional organisation ID
   *
   * @param file File to upload
   * @param orgId Optional organisation ID to link to
   * @returns CDN URL for access
   */
  async uploadFile(file: File, orgId?: string): Promise<string> {
    const [ingestUrl, contentUrl] = await this.getUploadUrl(file, orgId);
    await apiService.upload(ingestUrl, file);
    return contentUrl;
  }
}

export const uploadService = UploadService.getInstance();
