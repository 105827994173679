import { action, observable, runInAction } from "mobx";
import { UserStore } from "./UserStore";
import { AuthStore } from "./AuthStore";
import { ContentStore } from "./ContentStore";
import { PlaylistStore } from "./PlaylistStore";
import { UiStore } from "./UiStore";
import { OrganizationStore } from "./OrganizationStore";
import { OrganizationRoleStore } from "./OrganizationRoleStore";
import { UserConsentStore } from "./UserConsentStore";
import { UserRoleStore } from "./UserRoleStore";
import { BusinessPlan } from "../enums/business-plan";
import { AnalyticsModel } from "../models/AnalyticsModel";
import { organizationService } from "../services/OrganizationService";
import { AnalyticsStore } from "./AnalyticsStore";
import { ProfileStore } from "./ProfileStore";
import { BillingAccountStore } from "./BillingAccountStore";

export class RootStore {
  @observable userRoleStore?: UserRoleStore;
  @observable userConsentStore?: UserConsentStore;
  @observable userStore?: UserStore;
  @observable authStore?: AuthStore;
  @observable contentStore?: ContentStore;
  @observable playlistStore?: PlaylistStore;
  @observable uiStore?: UiStore;
  @observable organizationStore?: OrganizationStore;
  @observable organizationRoleStore?: OrganizationRoleStore;
  @observable analyticsStore?: AnalyticsStore;
  @observable profileStore?: ProfileStore;
  @observable billingAccountStore?: BillingAccountStore;

  @observable isLoading = false;
  @observable redirectUrl = "/";
  @observable defaultOrgId?: string;
  @observable selectedPlanId?: BusinessPlan;
  @observable planId?: BusinessPlan;
  @observable analytics?: AnalyticsModel;

  private constructor() {
    this._initializeStores();
  }

  private _initializeStores() {
    this.userStore = new UserStore(this);
    this.userConsentStore = new UserConsentStore(this);
    this.userRoleStore = new UserRoleStore(this);
    this.authStore = new AuthStore(this);
    this.contentStore = new ContentStore(this);
    this.playlistStore = new PlaylistStore(this);
    this.uiStore = new UiStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.organizationRoleStore = new OrganizationRoleStore(this);
    this.analyticsStore = new AnalyticsStore(this);
    this.profileStore = new ProfileStore(this);
    this.billingAccountStore = new BillingAccountStore();
  }

  static getInstance(): RootStore {
    return new RootStore();
  }

  @action
  resetStoreOnLogout() {
    this._initializeStores();
  }

  @action
  resetStoreOnOrgSwitch() {
    this.userRoleStore = new UserRoleStore(this);
    this.userStore = new UserStore(this);
    this.contentStore = new ContentStore(this);
    this.playlistStore = new PlaylistStore(this);
  }

  @action
  async loadDashboardAnalytics(force = false) {
    try {
      if ((!this.analytics && !this.isLoading) || force) {
        this.isLoading = true;
        const data = await organizationService.getDashboardAnalytics(
          this.organizationRoleStore?.selectedOrganizationId!
        );
        runInAction(() => {
          const analytics = new AnalyticsModel("");
          analytics.updateFromJson(data as any);
          this.analytics = analytics;
          this.isLoading = false;
        });
      }
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }
}

export const rootStore = RootStore.getInstance();
