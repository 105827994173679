import React, { useEffect } from "react";
import { stripeService } from "../../services/StripeService";
import { useParams } from "react-router";
import { inject } from "mobx-react";
import { GlobalProps } from "../../App";
import useSnackBar from "../../hooks/useSnackBar";
import { Center, Spinner } from "@chakra-ui/react";

const StripeCheckoutView: React.FC<GlobalProps> = (props: GlobalProps) => {
  const params = useParams<{ accountId: string }>();
  const notify = useSnackBar();
  useEffect(() => {
    const planId = props.store?.planId;
    if (!planId) {
      notify("error", "Please select the plan to proceed!");
      return;
    }
    stripeService.redirectToCheckout(params.accountId, planId).then(() => {
      props.store!.planId = undefined;
    });
  }, []);

  return (
    <Center>
      <Spinner
        thickness="4px"
        color="brand"
        size="xl"
        pos="absolute"
        top="50%"
        left="50%"
        transform="translateY(-50%, -50%)"
      />
    </Center>
  );
};

export default inject("store")(StripeCheckoutView);
