import Model from "./Model";
import { action, observable } from "mobx";
import { UserModel } from "./UserModel";
import { UserConsentStore } from "../stores/UserConsentStore";

export class UserConsentModel extends Model {
  uid!: string;

  static _store: UserConsentStore;

  @observable orgId!: string;
  @observable consent!: boolean;
  @observable timeCreated!: string;
  @observable userId!: string;
  @observable user!: UserModel;

  @action
  deserializeUser(data: any) {
    // Do not call fromJson to avoid overriding a potential de-anonimised profile
    // FIXME multiple sources of truth for profile leads to race condition
    // Profile can be returned from:
    // - User's own profile (full)
    // - Staff role listing (full)
    // - Consent listing (full or anonimised)
    // Race condition occurs when a full profile is loaded first, then
    // a second read occurs of an anonimised profile, resulting in the
    // anonimised data being shown.
    // In rare cases, the anonimised profile is loaded first, then
    // it displays correctly when the full profile is loaded
    this.user = data as UserModel;
  }

  getId(): string {
    return this.uid;
  }
}
