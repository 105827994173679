import { CreatePlaylistDto } from "../dtos/create-playlist.dto";
import { PlaylistModel } from "../models/PlaylistModel";
import { apiService } from "./ApiService";

class PlaylistService {
  static getInstance(): PlaylistService {
    return new PlaylistService();
  }

  createPlaylist(
    orgId: string,
    playlist: CreatePlaylistDto,
    notifyUsers: boolean
  ): Promise<PlaylistModel> {
    return apiService
      .post(`/playlists`, { playlist, orgId, notifyUsers })
      .then((res) => PlaylistModel.fromJson(res) as PlaylistModel);
  }

  listPlaylists(orgId: string, data?: any): Promise<PlaylistModel[]> {
    return apiService
      .get<any[]>(`/playlists?orgId=${orgId}`, data)
      .then((res) =>
        res.map((v) => PlaylistModel.fromJson(v) as PlaylistModel)
      );
  }

  fetchPlaylistById(listId: string): Promise<PlaylistModel> {
    return apiService
      .get<any>(`/playlists/${listId}`)
      .then((res) => PlaylistModel.fromJson(res) as PlaylistModel);
  }

  updatePlaylist(listId: string, data: any) {
    return apiService
      .patch(`/playlists/${listId}`, { playlist: data })
      .then((res) => PlaylistModel.fromJson(res) as PlaylistModel);
  }

  deletePlaylist(listId: string) {
    return apiService.delete(`/playlists/${listId}`);
  }
}

export const playlistService = PlaylistService.getInstance();
