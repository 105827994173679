import {
  Box,
  Text,
  Flex,
  Td,
  Tr,
  useDisclosure,
  Avatar,
  Tag,
  TagLeftIcon,
  Icon,
  TagLabel,
  Skeleton,
  DrawerCloseButton,
  Heading,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
} from "@chakra-ui/react";
import {
  mdiEmail,
  mdiFire,
  mdiPhone,
  mdiSnowflake,
  mdiWeatherWindy,
} from "@mdi/js";
import React, { useContext, useState } from "react";
import { User } from "../models/UserModel";
import * as AnalyticsService from "../services/AnalyticsService";
import { StoreContext } from "../stores/helpers/context";
import Chart from "./Chart";
import { CommonUtils } from "../utils/CommonUtils";
import { useQuery } from "react-query";

import { fetchLead } from "../services/AnalyticsService";
import PlaylistPreview, { SkeletonPlaylistPreview } from "./PlaylistPreview";
import ContentPreview, { SkeletonContentPreview } from "./ContentPreview";
import { times } from "lodash";
import ReactCountryFlag from "react-country-flag";

interface LeadProps {
  profile?: User;
  userId: string;
  startDate: Date;
  endDate: Date;
}

function Lead(props: LeadProps & PublicLeadProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const temperatureMapping = {
    hot: { colorScheme: "red", icon: mdiFire, label: "Hot lead" },
    warm: { colorScheme: "orange", icon: mdiWeatherWindy, label: "Warm lead" },
    cold: { colorScheme: "blue", icon: mdiSnowflake, label: "Cold lead" },
  };

  const leadTemp = temperatureMapping[props.temperature];

  const profile = props.profile;

  const store = useContext(StoreContext);
  const orgId = store.organizationRoleStore!.selectedOrganizationId!;
  const [startDate, setStartDate] = useState(() => {
    return CommonUtils.ranges[CommonUtils.defaultRange].start;
  });

  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
  });

  const { isLoading: detailsLoading, data: details } = useQuery(
    ["/analytics/users", props.userId, orgId, startDate, endDate],
    () => fetchLead(props.userId, orgId, startDate, endDate),
    {
      enabled: Boolean(props.userId && isOpen),
    }
  );

  const { data: watchTime } = useQuery(
    ["/analytics/watch_time", props.userId, orgId],
    () =>
      AnalyticsService.fetchUserWatchTime(
        props.userId,
        orgId,
        startDate,
        endDate
      ),
    {
      enabled: Boolean(props.userId && isOpen),
    }
  );
  const { data: visits } = useQuery(
    ["/analytics/visits", props.userId, orgId],
    () =>
      AnalyticsService.fetchUserVisits(props.userId, orgId, startDate, endDate),
    {
      enabled: Boolean(props.userId && isOpen),
    }
  );
  const { data: views } = useQuery(
    ["/analytics/views", props.userId, orgId],
    () =>
      AnalyticsService.fetchUserViews(props.userId, orgId, startDate, endDate),
    {
      enabled: Boolean(props.userId && isOpen),
    }
  );
  return (
    <>
      <Tr _hover={{ bgColor: "gray.50" }} onClick={onOpen}>
        <Td>
          <Flex align="center">
            {profile ? (
              <Avatar
                src={profile.photoURL || ""}
                name={CommonUtils.getUserDisplayDetail(profile)}
                width="32px"
                height="32px"
                rounded="full"
                objectFit={"cover"}
                marginRight="1em"
              />
            ) : (
              <Skeleton width="32px" height="32px" />
            )}
            {profile ? (
              <Text>{CommonUtils.getUserDisplayDetail(profile)}</Text>
            ) : (
              <Skeleton />
            )}
          </Flex>
        </Td>
        <Td>{profile ? profile.email : <Skeleton height="20px" />}</Td>
        <Td>{profile ? profile.phoneNumber : <Skeleton height="20px" />}</Td>
        <Td>
          {profile ? (
            CommonUtils.getCountryFromPhoneNumber(profile.phoneNumber)
          ) : (
            <Skeleton height="20px" />
          )}
        </Td>
        <Td>
          <Skeleton isLoaded={!!profile}>
            <Tag
              colorScheme={leadTemp.colorScheme}
              title={`Score: ${props.score.toFixed(2)}`}
            >
              <TagLeftIcon>
                <Icon>
                  <path d={leadTemp.icon} fill="currentColor" />
                </Icon>
              </TagLeftIcon>
              <TagLabel>{leadTemp.label}</TagLabel>
            </Tag>
          </Skeleton>
        </Td>
        <Td>{profile ? profile.company : <Skeleton height="20px" />}</Td>
        <Td>{profile ? profile.businessArea : <Skeleton height="20px" />}</Td>
        <Td>{profile ? profile.jobTitle : <Skeleton height="20px" />}</Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="1200px">
          <DrawerCloseButton />

          <ModalBody rounded="xl" padding="0">
            <Box roundedTop="xl" bg="#FFF5E7" height="4em" mb="2" />

            <Box padding="2em">
              <Box mb="4" display="flex">
                <Box pr="8">
                  <Avatar
                    marginBottom={6}
                    objectFit={"cover"}
                    src={props.profile?.photoURL || ""}
                    name={
                      props.profile
                        ? CommonUtils.getUserDisplayDetail(props.profile)
                        : undefined
                    }
                    width="115px"
                    height="115px"
                    rounded="full"
                  />
                </Box>

                <Box>
                  <Heading marginBottom=".3em" size="lg">
                    {profile?.displayName} |{"  "}
                    {CommonUtils.getCountryFromPhoneNumber(
                      profile?.phoneNumber || ""
                    )}
                    {"  "}
                    <ReactCountryFlag
                      countryCode={
                        CommonUtils.getCountryFromPhoneNumber(
                          profile?.phoneNumber || ""
                        ) || ""
                      }
                    />
                  </Heading>
                  <Text textColor="gray.600" fontSize="sm">
                    {profile?.jobTitle}, {profile?.company}
                  </Text>

                  <Flex mt="4" align="center">
                    <Icon mr=".6em">
                      <path d={mdiPhone} fill="currentColor" />
                    </Icon>

                    <a href={`tel:${profile?.phoneNumber}`}>
                      {profile?.phoneNumber}
                    </a>
                  </Flex>
                  <Flex align="center">
                    <Icon mr=".6em">
                      <path d={mdiEmail} fill="currentColor" />
                    </Icon>

                    <a href={`mailto:${profile?.email}`}>{profile?.email}</a>
                  </Flex>

                  <Tag
                    mt="4"
                    colorScheme={leadTemp.colorScheme}
                    title={`Score: ${props.score.toFixed(2)}`}
                  >
                    <TagLeftIcon>
                      <Icon>
                        <path d={leadTemp.icon} fill="currentColor" />
                      </Icon>
                    </TagLeftIcon>
                    <TagLabel>{leadTemp.label}</TagLabel>
                  </Tag>
                </Box>
              </Box>

              <Grid
                templateColumns={{ base: "1fr", lg: "500px auto" }}
                gridGap="1em"
              >
                <Box gridColumn="1/4">
                  <Heading size="lg">Top 3 playlists</Heading>
                  <Text size="sm" color="gray.500">
                    Last 28 days
                  </Text>
                  <Box paddingBottom="2" overflowX="auto" whiteSpace="nowrap">
                    {details
                      ? details.mostViewedPlaylists
                          .slice(0, 3)
                          .map((playlist) => (
                            <PlaylistPreview
                              id={playlist.id}
                              viewCount={playlist.viewCount}
                            />
                          ))
                      : times(3, (i) => <SkeletonPlaylistPreview key={i} />)}
                  </Box>

                  <Heading mt={8} size="lg">
                    Top 3 content
                  </Heading>
                  <Text size="sm" color="gray.500">
                    Last 28 days
                  </Text>

                  <Box paddingBottom="2" overflowX="scroll" paddingX="1">
                    {details
                      ? details.mostViewedContent
                          .slice(0, 3)
                          .map((content) => (
                            <ContentPreview
                              id={content.id}
                              viewCount={content.viewCount}
                            />
                          ))
                      : times(3, (i) => <SkeletonContentPreview key={i} />)}
                  </Box>
                </Box>

                <VStack gridColumn="4/4" maxW={300}>
                  <Box shadow="md" rounded={2} height="100%" width="100%">
                    <Chart
                      label="Sign ins"
                      totalCount={visits?.total}
                      data={visits?.data}
                      showAxes={false}
                    />
                  </Box>
                  <Box shadow="md" rounded={2} height="100%" width="100%">
                    <Chart
                      label="Watch time (minutes)"
                      showAxes={false}
                      data={watchTime?.data.map((x) => {
                        return {
                          value: +(x.value / 1000 / 60).toFixed(2),
                          time: x.time,
                        };
                      })}
                      totalCount={
                        +((watchTime?.total || 0) / 1000 / 60).toFixed(2)
                      }
                    />
                  </Box>
                  <Box shadow="md" rounded={2} height="100%" width="100%">
                    <Chart
                      showAxes={false}
                      label="Views"
                      totalCount={views?.total}
                      data={views?.data}
                    />
                  </Box>
                </VStack>
              </Grid>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

interface PublicLeadProps {
  userId: string;
  score: number;
  lastModified: Date;
  temperature: "hot" | "warm" | "cold";
}

export default (props: PublicLeadProps) => {
  const store = useContext(StoreContext);

  const startDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1)
  );

  return (
    <Lead
      {...props}
      profile={store.profileStore!.profiles[props.userId]}
      startDate={startDate}
      endDate={new Date()}
    />
  );
};
