import React from "react";
import { Route } from "react-router";
import PageNotFound from "../errors/page-not-found/PageNotFound";
import { RolesUtils } from "../utils/RolesUtils";

const PolicyProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const isAllowed = rest.policy
    ? RolesUtils.isAccessAllowed(rest.role, rest.policy)
    : true;
  if (!isAllowed) {
    return (
      <Route {...rest}>
        <PageNotFound />
      </Route>
    );
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default PolicyProtectedRoute;
