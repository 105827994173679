import { Icon } from "@chakra-ui/react";

import {
  mdiHeadphones,
  mdiVideoOutline,
  mdiFilePdfBox,
  mdiImage,
  mdiFilePowerpoint,
  mdiFileTable,
  mdiFile,
  mdiLink,
} from "@mdi/js";
import React from "react";

const MimeMap = {
  "application/pdf": mdiFilePdfBox,
  "image/png": mdiImage,

  "image/webp": mdiVideoOutline,
  "image/mpeg": mdiVideoOutline,

  "text/csv": mdiFileTable,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    mdiFileTable,
  "image/svg+xml": mdiFileTable,

  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    mdiFileTable,

  "audio/wav": mdiHeadphones,

  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    mdiFilePowerpoint,
};

export type MimeString = keyof typeof MimeMap;

interface TypeIconProps {
  mime: MimeString | null;
  integration?: boolean;
}

/**
 * Returns an icon representing the MIME type passed in.
 *
 * If the `integration` prop is set to true, the icon will always be a link.
 * If `integration` isn't set, or is set to false, TypeIcon looks up which
 * icon to use depending on `mime`.
 *
 * @example
 *
 *     <TypeIcon mime="application/pdf" />
 *     => pdf icon in brand color
 */
function TypeIcon(props: TypeIconProps): JSX.Element {
  let path = "";

  if (props.mime) {
    path = MimeMap[props.mime];
  }

  if (props.integration) {
    path = mdiLink;
  }

  if (!path) {
    path = mdiFile;
  }

  return (
    <Icon
      fontSize="1.5rem"
      title={`File type: ${props.mime}`}
      color="secondary.500"
    >
      <path fill="currentColor" d={path} />
    </Icon>
  );
}

export default TypeIcon;
