import React, { useEffect, useState } from "react";
import { Center, Heading, Image, Spinner, VStack } from "@chakra-ui/react";
import { EMAIL_FOR_SIGN_IN, REDIRECT_URL } from "../../utils/ConstantUtils";
import { firebaseService } from "../../services/FirebaseService";
import { useHistory, useLocation } from "react-router";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import { CommonUtils } from "../../utils/CommonUtils";
import { HttpError } from "../../services/ApiService";

const MagicLinkAuthenticationView = (props: GlobalProps) => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const params = CommonUtils.parseQueryString(location.search);
  useEffect(() => {
    const link = window.location.href;
    if (!link || !firebaseService.isSignInWithEmailLink(link)) {
      return;
    }
    let emailForSignIn = localStorage.getItem(EMAIL_FOR_SIGN_IN);
    if (!emailForSignIn) {
      emailForSignIn = window.prompt(
        "Please provide your email for confirmation"
      );
    }

    firebaseService
      .signInWithMagicLink(emailForSignIn!, link)
      .then((res) => {
        let redirectUrl = localStorage.getItem(REDIRECT_URL);
        if (!redirectUrl) {
          redirectUrl = props.store?.redirectUrl!;
        }
        if (params.action === "login") {
          history.push(`${redirectUrl}`);
        } else {
          props.store!.redirectUrl = redirectUrl;
          history.push("/on-boarding/details/user");
        }

        localStorage.removeItem(REDIRECT_URL);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(
          (error as HttpError).message ? (error as HttpError).message : error
        );
      });
  });
  return (
    <Center mt="10%">
      {isLoading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand"
          size="xl"
        />
      ) : (
        error && (
          <VStack spacing={5}>
            <Image src="/assets/icons/error-404.png" />
            <Heading fontSize="lg">{error}</Heading>
          </VStack>
        )
      )}
    </Center>
  );
};

export default inject("store")(observer(MagicLinkAuthenticationView));
