import React from "react";
import { Box, Center, Spinner, VStack } from "@chakra-ui/react";
import {
  VictoryTheme,
  VictoryAxis,
  VictoryArea,
  VictoryContainer,
} from "victory";
import { VictoryChart } from "victory-chart";

interface ChartProps {
  label: string;
  height?: number;
  width?: number;
  data?: {
    time: Date;
    value: number;
  }[];
  totalCount?: number;
  showAxes?: boolean;
}

export default function Chart({
  label,
  data,
  height,
  width,
  totalCount,
  showAxes,
}: ChartProps) {
  return (
    <VStack position="relative" alignItems="flex-start">
      <VStack
        // position="absolute"
        // top="1rem"
        // left="2rem"
        alignItems="flex-start"
      >
        <Box pl="10px" pt="10px" fontSize="lg">
          {label}
        </Box>
        {totalCount && (
          <Box pl="10px" fontSize="lg" fontWeight="bold" color="#898989">
            {totalCount}
          </Box>
        )}
      </VStack>
      {!data && (
        <VStack alignItems="center" flexGrow={1} justifyContent="center">
          <Spinner />
        </VStack>
      )}
      {data && (
        <Center width="100%" justifyItems="stretch">
          <VictoryChart
            domainPadding={10}
            height={height}
            width={width}
            theme={VictoryTheme.grayscale}
            style={{
              parent: {
                padding: 0,
                margin: 0,
              },
            }}
            containerComponent={
              <VictoryContainer
                style={{
                  padding: 0,
                  width: "100%",
                }}
              />
            }
          >
            <VictoryAxis
              dependentAxis
              tickCount={5}
              data={data.map((v) => v.value)}
              tickFormat={(y: number) => {
                if (!showAxes) return "";
                return y;
              }}
              style={{
                axis: { stroke: !!showAxes ? "#000000" : "transparent" },
                tickLabels: {
                  fontSize: 8,
                  padding: 0,
                  margin: 0,
                },
              }}
            />
            <VictoryAxis
              scale="time"
              data={data.map((v) => v.time)}
              tickCount={6}
              maxDomain={Date.now()}
              tickFormat={(x: number) => {
                if (!showAxes) return "";
                const data = new Date(x);
                return `${data.getDate()}-${
                  data.getMonth() + 1
                }-${data.getUTCFullYear()}`;
              }}
              style={{
                axis: { stroke: !!showAxes ? "#000000" : "transparent" },
                tickLabels: {
                  fontSize: 8,
                  padding: 0,
                  margin: 0,
                },
                axisLabel: {
                  padding: 0,
                },
              }}
            />
            <VictoryArea
              data={data || undefined}
              style={{
                data: {
                  fill: "#d9d9d9",
                  stroke: "#919191",
                  strokeWidth: 2,
                  padding: 0,
                  margin: 0,
                },
              }}
              x="time"
              height={50}
              y="value"
            />
          </VictoryChart>
        </Center>
      )}
    </VStack>
  );
}
