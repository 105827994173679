import React from "react";
import {
  Box,
  Center,
  Divider,
  Heading,
  Icon,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/all";
import { BusinessPlan } from "../../../enums/business-plan";
import { EnumUtils } from "../../../utils/EnumUtils";

export interface BillingSuccessPageProps {
  plan: BusinessPlan;
}

const BillingSuccessPage: React.FC<BillingSuccessPageProps> = (
  props: BillingSuccessPageProps
) => {
  return (
    <Box backgroundColor="#232323" height="100vh">
      <Center>
        <Image my="20px" width="150px" src="/assets/icons/konnek-brand.png" />
      </Center>
      <Center>
        <Box
          backgroundColor="white"
          width={{ base: "90%", lg: "25%" }}
          mt="3rem"
          p="2.5rem 3rem"
          borderRadius="xl"
        >
          <VStack spacing={4}>
            <Icon boxSize={12} as={FiCheckCircle} color="success" />
            <Text color="success" fontSize="lg">
              Payment successful for
            </Text>
            <Heading fontSize="lg">
              {EnumUtils.getPlanTitle(props.plan)}
            </Heading>
            <Divider />
            <Text fontSize="xs" color="gray" textAlign="center">
              Please wait while we process your data
            </Text>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
            <Text fontSize="xs" color="gray" textAlign="center">
              You will be redirected automatically
            </Text>
          </VStack>
        </Box>
      </Center>
    </Box>
  );
};

export default BillingSuccessPage;
