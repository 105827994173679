import React from "react";
import { Button, Heading, Image, Stack } from "@chakra-ui/react";

const FallBack: React.FC = () => (
  <Stack
    spacing={4}
    direction="column"
    justifyContent="center"
    alignItems="center"
  >
    <Image src="/assets/icons/error-404.png" alt="error-404" />
    <Heading fontSize="6xl">Error!</Heading>
    <Heading>Oops! We encountered an error.</Heading>
    <Button onClick={() => window.location.reload()} colorScheme="primary">
      Reload
    </Button>
  </Stack>
);

export default FallBack;
