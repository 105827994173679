import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

const theme = extendTheme({
  colors: {
    primary: {
      50: "#fdf5e8",
      100: "#ffebcc",
      200: "#ffd699",
      300: "#ffcc80",
      400: "#ffad33",
      500: "#FF9900",
      600: "#e68a00",
      700: "#cc7a00",
      800: "#b36b00",
      900: "#995c00",
    },
    secondary: {
      100: "#dbd1f4",
      200: "#b6a4e9",
      300: "#a48de4",
      400: "#6d49d4",
      500: "#491bc9",
      600: "#4218b5",
      700: "#3a16a1",
      800: "#33138d",
      900: "#2c1079",
    },
    brand: "#FF9900",
    success: "#40D647",
    error: "#DB2C2F",
    warning: "#FFA93A",
    black: "#141414",
    actionAreaBackgroundColor: "#F7F7F7",
  },
  fonts: {
    body: "Poppins, sans-serif",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.1rem",
    xl: "1.125rem",
    "2xl": "1.2rem",
    "3xl": "1.3rem",
    "4xl": "1.6rem",
    "5xl": "1.9rem",
    "6xl": "2.7rem",
    "7xl": "3.7rem",
    "8xl": "4.5rem",
    "9xl": "7.3rem",
  },
  components: {
    Steps,
    FormLabel: {
      baseStyle: {
        color: "gray.600",
        fontSize: "sm",
        fontWeight: "semibold",
      },
    },
  },
});

export default theme;
