import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";

interface SocialButtonProps {
  text: string;
  imageSrc: string;
  onClick: () => void;
}

const SocialButton: React.FC<SocialButtonProps> = (
  props: SocialButtonProps
) => {
  const { text, imageSrc, onClick } = props;
  return (
    <Box
      my="20px"
      cursor="pointer"
      onClick={onClick}
      borderRadius="8px"
      display="flex"
      py="16px"
      justifyContent="center"
      boxShadow="lg"
    >
      <Image mr="12px" boxSize="22px" src={imageSrc} />
      <Text fontSize="2xl">{text}</Text>
    </Box>
  );
};

export default SocialButton;
