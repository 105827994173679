import React from "react";
import { Box, Center, Image } from "@chakra-ui/react";

const OnboardingLayout = (props: any) => {
  const { children } = props;
  return (
    <Box display="flex" height="100vh">
      <Box width={{ sm: "100%", lg: "60%" }} p="4%" overflowY="scroll">
        <Image
          src="/assets/icons/konnek-brand-black.png"
          width="180px"
          fit="contain"
          alt="Auth image"
          mb="2rem"
        />
        {children}
      </Box>
      <Box
        position="relative"
        backgroundColor="black"
        width="40%"
        display={{ base: "none", lg: "block" }}
      >
        <Center>
          <Image
            position="absolute"
            top="50%"
            boxSize="340px"
            src="/assets/images/onboarding.svg"
            alt="Onboarding image"
          />
        </Center>
      </Box>
    </Box>
  );
};

export default OnboardingLayout;
