import { action, observable, runInAction } from "mobx";
import { UserModel } from "../models/UserModel";
import { userService } from "../services/UserService";
import { RootStore } from "./RootStore";
import { firebaseService } from "../services/FirebaseService";
import { UpdateUserDto } from "../dtos/update-user.dto";
import { apiService } from "../services/ApiService";

export class AuthStore {
  @observable isUserLoading = false;
  @observable isLoggedIn = false;
  @observable isInternallyChecking = true;
  @observable loggedInUser?: UserModel;
  @observable userId?: string;
  private cookieTimer?: NodeJS.Timeout;

  constructor(private rootStore: RootStore) {
    if (!!this.cookieTimer) {
      clearInterval(this.cookieTimer);
    }
    firebaseService
      .getFirebaseApp()
      .auth()
      .onAuthStateChanged(async (data) => {
        this.isLoggedIn = !!data;
        let user: UserModel;
        if (this.isLoggedIn) {
          this.userId = data?.uid;
          user = new UserModel(this.userId!);
          user.email = data?.email!;
          user.displayName = data?.displayName!;
          // Setting the logged in user to a temporary user object until the fetchLoggedIn user api returns a success response
          this.loggedInUser = user;
          this.fetchLoggedInUser();
        }
        if (this.isInternallyChecking) {
          this.isInternallyChecking = false;
        }
      });
  }

  @action
  async updateLoggedInUser(user: UpdateUserDto) {
    if (this.isUserLoading) {
      return;
    }
    this.isUserLoading = true;
    try {
      const updatedUser = await userService.updateUserDetails(user);
      runInAction(() => {
        this.loggedInUser = updatedUser;
        this.isUserLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isUserLoading = false;
      });
      throw error;
    }
  }

  @action
  private async fetchLoggedInUser() {
    if (!this.isUserLoading) {
      this.isUserLoading = true;
      try {
        const user = await apiService.retryUntilSuccess(
          userService.getUserDetails,
          [404]
        );
        runInAction(() => {
          this.loggedInUser = user;
          this.isUserLoading = false;
        });
      } catch (e) {
        runInAction(() => {
          this.isUserLoading = false;
        });
      }
    }
  }

  setInternalChecking(value: boolean) {
    this.isInternallyChecking = value;
  }
}
