import React, { FC, useState, useCallback } from "react";
import { ReorderableListItem } from "./ReorderableListItem";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Box } from "@chakra-ui/react";

export interface Item {
  id: string;
  children?: React.ReactElement;
}

export interface ReorderableListProps {
  items: Item[];
  onMoveComplete: (items: Item[]) => void;
}

export const ReorderableList: FC<ReorderableListProps> = (props) => {
  const [cards, setCards] = useState(props.items);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      setCards(() => {
        const newCards = update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        });
        props.onMoveComplete(newCards);
        return newCards;
      });
    },
    [cards, props]
  );

  const renderCard = (card: Item, index: number) => {
    return (
      <ReorderableListItem
        key={card.id}
        index={index}
        id={card.id}
        children={card.children}
        moveReorderableListItem={moveCard}
      />
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box m={0} width="100%">
        {cards.map((card, i) => renderCard(card, i))}
      </Box>
    </DndProvider>
  );
};
