import React from "react";
import SelectAccountTypePage from "../components/SelectAccountTypePage/SelectAccountTypePage";
import { useHistory } from "react-router";
import { AccountType } from "../../enums/account-type";

const SelectAccountTypeView = (props: any) => {
  const history = useHistory();
  const _navigateToRoute = (accountType: AccountType) => {
    history.push(`/create-billing-account?type=${accountType}`);
  };
  return <SelectAccountTypePage onSelect={_navigateToRoute} />;
};

export default SelectAccountTypeView;
