import React, { useState } from "react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Text,
  VStack,
  Icon as ChakraIcon,
} from "@chakra-ui/react";
import {
  MdCloudUpload,
  MdFileCopy,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineInsertDriveFile,
  MdVideocam,
  MdOpenInNew,
  MdClose,
  MdPublic,
  MdPublicOff,
  MdNotifications,
} from "react-icons/md";
import TextField from "../../shared/textfield/TextField";
import { useDropzone } from "react-dropzone";
import { FormikErrors, useFormik } from "formik";
import TextAreaField from "../../shared/textareafield/TextAreaField";
import PrimarySelect from "../../shared/primary-select/PrimarySelect";
import { inject, observer } from "mobx-react";
import { GlobalProps } from "../../App";
import { ContentSchema } from "../../dtos/create-video.dto";
import { z } from "zod";
import { ContentSourceType } from "../../enums/content-source-type";
import useSnackBar from "../../hooks/useSnackBar";
import { CommonUtils } from "../../utils/CommonUtils";
import { Privacy } from "../../enums/privacy";
import ThumbnailPicker from "../../shared/thumbnail-picker/ThumbnailPicker";
import ReactPlayer from "react-player";

export interface CreateContentDialogProps extends GlobalProps {
  playlistId?: string;
  isOpen: boolean;
  onClose: () => void;
  uploadType: "file" | "url";
}

const CreateContentDialog: React.FC<CreateContentDialogProps> = (
  props: CreateContentDialogProps
) => {
  const playlistStore = props.store?.playlistStore;
  const { isOpen, onClose, uploadType, playlistId } = props;
  const notify = useSnackBar();
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const [upload, setUpload] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [isPrivate, setIsPrivate] = useState(false);

  const onDropFile = React.useCallback((files: File[]) => {
    setUpload(files[0]);
  }, []);

  const { getRootProps: getFileRootProps, getInputProps: getFileInputProps } =
    useDropzone({ onDrop: onDropFile });

  const validateUrl = (url: string | null) => {
    if (!url) {
      return true; // Create a draft content
    }
    return CommonUtils.validateYoutubeUrl(url) || url.includes("vimeo");
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      url: null,
      playlist: playlistStore?.organizationPlaylists[0].uid!,
      thumbnail: null,
      upload: null,
      stats: {},
      notifyUsers: false,
    },
    validate: (values) => {
      const result = ContentSchema.safeParse(values);

      // TODO: check thumbnail is ok: e.g. dimensions, file size, ratio

      if (!result.success) {
        const errors: FormikErrors<z.infer<typeof ContentSchema>> = {};

        for (const issue of result.error.issues) {
          errors[issue.path[0] as keyof typeof errors] = issue.message;
        }

        return errors;
      }
    },
    onSubmit: async (values, helper) => {
      const video = {
        title: values.title,
        description: values.description,
        url: values.url,
        previewUrl: null,
        privacy: isPrivate ? Privacy.PRIVATE : Privacy.PUBLIC,
        type: upload ? ContentSourceType.NATIVE : ContentSourceType.INTEGRATION,
      };
      let createVideoRes;

      try {
        const contentStore = props.store?.contentStore;
        createVideoRes = await contentStore?.createVideo(
          video,
          values.notifyUsers,
          upload,
          thumbnail
        );
      } catch (e) {
        notify("error", "Problem creating content. Please try again.");
        return;
      }

      if (!createVideoRes) {
        notify("error", "Problem creating content. Please try again.");
        return;
      }

      const playlist = !!playlistId ? playlistId : values.playlist;

      if (playlist) {
        try {
          await playlistStore?.addVideosToPlaylist([createVideoRes], playlist);
        } catch (e) {
          notify(
            "error",
            "Problem adding content to playlist. Please try again."
          );
        }
      }
      notify("success", "Added content.");
      helper.resetForm();
      nextStep();
    },
  });
  // Shorthand for repeated attributes in fields
  const _fields = (name: keyof typeof formik.values) => ({
    id: name,
    name: name,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    isInvalid: formik.touched[name] && Boolean(formik.errors[name]),
  });

  const Icon = () => (
    <Box
      height="15px"
      width="15px"
      borderRadius="50%"
      backgroundColor="secondary.500"
    />
  );
  const stepProp = {
    icon: Icon,
    backgroundColor: "white",
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent p="40px" borderRadius="21px">
        <ModalCloseButton />
        <ModalHeader>
          <Steps
            size="sm"
            colorScheme="secondary"
            orientation="horizontal"
            activeStep={activeStep}
          >
            <Step {...stepProp}>
              {uploadType === "url" ? (
                <>
                  <Heading fontSize="2xl" my="20px">
                    Add video
                  </Heading>
                  <HStack spacing={2} my="8px">
                    <Image src="/assets/icons/youtube.png" />
                    <Image src="/assets/icons/vimeo.png" />
                    <Text fontSize="xs">
                      Copy and paste a YouTube / Vimeo share link
                    </Text>
                  </HStack>
                  <HStack spacing={6}>
                    <TextField
                      type="text"
                      {..._fields("url")}
                      value={formik.values.url || ""}
                      error={formik.errors.url}
                    />
                    <Button
                      colorScheme="secondary"
                      width="120px"
                      fontSize="14px"
                      onClick={() => {
                        if (!validateUrl(formik.values.url)) {
                          formik.setErrors({ url: "URL is invalid." });
                          return;
                        }
                        nextStep();
                      }}
                    >
                      Proceed
                    </Button>
                  </HStack>
                </>
              ) : (
                <>
                  <Heading fontSize="2xl" my="20px">
                    Pick file
                  </Heading>
                  {!upload && (
                    <Flex
                      height="400px"
                      backgroundColor="actionAreaBackgroundColor"
                      borderRadius="9px"
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="xs"
                      color="gray"
                      {...getFileRootProps()}
                    >
                      <MdCloudUpload size="38px" color="gray" />
                      <Text my="4px">Drag and drop to upload</Text>
                      <Text my="4px">or</Text>
                      <Button my="4px" colorScheme="secondary" fontSize="12px">
                        Click to select file
                      </Button>
                      <input {...getFileInputProps()} />
                    </Flex>
                  )}
                </>
              )}
              {!!upload && (
                <>
                  <HStack
                    mt="20px"
                    padding="18px"
                    backgroundColor="actionAreaBackgroundColor"
                    borderRadius="8px"
                    justifyContent="space-between"
                  >
                    <HStack spacing={4}>
                      <ChakraIcon
                        as={MdOutlineInsertDriveFile}
                        color="secondary.500"
                        fontSize="28px"
                      />
                      <VStack alignItems="flex-start">
                        <Text fontSize="sm"> {upload?.name || "File"}</Text>
                        <Text color="gray" fontSize="xs">
                          {CommonUtils.formatFileSize(upload?.size || 0)}
                        </Text>
                      </VStack>
                    </HStack>
                    <IconButton
                      size="sm"
                      variant="ghost"
                      icon={<MdClose color="gray" fontSize="28px" />}
                      onClick={() => setUpload(null)}
                      aria-label="close"
                    />
                  </HStack>
                  <Flex justifyContent="flex-end" mt="18px">
                    <Button
                      onClick={nextStep}
                      rightIcon={<MdKeyboardArrowRight size="24px" />}
                      colorScheme="secondary"
                      fontSize="12px"
                    >
                      Next
                    </Button>
                  </Flex>
                </>
              )}
            </Step>
            <Step {...stepProp}>
              <Heading fontSize="2xl" my="20px">
                Add details
              </Heading>
              <HStack spacing={10} alignItems="flex-start">
                <Box width="50%">
                  <TextField
                    type="text"
                    placeholder="Add a title"
                    {..._fields("title")}
                    value={formik.values.title}
                    error={formik.errors.title}
                    label="Title"
                    suggestedLength={100}
                  />
                  <TextAreaField
                    placeholder="Add a description"
                    {..._fields("description")}
                    value={formik.values.description}
                    label="Description"
                    suggestedLength={1000}
                  />
                  {!playlistId && (
                    <PrimarySelect
                      {..._fields("playlist")}
                      label="Select playlist"
                      value={formik.values.playlist || ""}
                      options={playlistStore?.organizationPlaylists}
                      displayOption={(playlist) => (
                        <option key={playlist.id} value={playlist.id}>
                          {playlist.title} ({playlist.items.length} items)
                        </option>
                      )}
                    />
                  )}
                </Box>
                <Box>
                  <FormLabel color="gray.500" fontWeight="500" fontSize="xs">
                    Thumbnail
                  </FormLabel>
                  <ThumbnailPicker
                    thumbnail={thumbnail}
                    onThumbnailPicked={(file) => setThumbnail(file)}
                  />
                  <HStack mt="18px" alignItems="flex-start">
                    <Switch
                      isChecked={isPrivate}
                      onChange={(event) => setIsPrivate(event.target.checked)}
                      colorScheme="primary"
                    />
                    <Text color="brand" fontSize="xs">
                      Hide content from public users view
                    </Text>
                  </HStack>
                  <HStack alignItems="flex-start">
                    <Switch
                      {..._fields("notifyUsers")}
                      isChecked={formik.values.notifyUsers}
                      colorScheme="primary"
                    />
                    <Text color="brand" fontSize="xs">
                      Notify users
                    </Text>
                  </HStack>
                </Box>
              </HStack>
              <HStack mt="12px" justifyContent="space-between">
                <Button
                  leftIcon={<MdKeyboardArrowLeft size="24px" />}
                  fontSize="12px"
                  onClick={prevStep}
                >
                  Back
                </Button>
                <Button
                  onClick={async () => {
                    const err = await formik.validateForm();
                    if (Object.keys(err).length === 0) {
                      nextStep();
                    }
                  }}
                  rightIcon={<MdKeyboardArrowRight size="24px" />}
                  colorScheme="secondary"
                  fontSize="12px"
                >
                  Next
                </Button>
              </HStack>
            </Step>
            <Step {...stepProp}>
              <Heading fontSize="2xl" my="20px">
                {uploadType === "url" ? "Review video" : "Review file"}
              </Heading>
              <HStack spacing={6} alignItems="flex-start">
                <Image
                  width="320px"
                  height="180px"
                  borderRadius="lg"
                  src={
                    thumbnail
                      ? URL.createObjectURL(thumbnail)
                      : "/assets/images/thumbnail.png"
                  }
                />
                <Box width="100%">
                  <HStack width="100%" justifyContent="space-between">
                    {uploadType === "file" ? (
                      <MdFileCopy size="24px" />
                    ) : (
                      <MdVideocam size="24px" />
                    )}
                    <Box fontSize="xs" color="brand">
                      <ChakraIcon
                        mr="2px"
                        color="brand"
                        as={isPrivate ? MdPublicOff : MdPublic}
                      />
                      {isPrivate ? "Private" : "Public"}
                    </Box>
                  </HStack>

                  <Heading mt="10px" fontSize="lg">
                    {formik.values.title}
                  </Heading>
                  <Text mt="10px" fontWeight="500" color="gray" fontSize="xs">
                    {formik.values.description}
                  </Text>
                  <Flex
                    fontWeight="400"
                    fontSize="0.7rem"
                    color="gray"
                    direction="row"
                    alignItems="center"
                    mt="2"
                  >
                    <MdNotifications fontSize="20px" />
                    <Text ml="4px">
                      {formik.values.notifyUsers ? "On" : "Off"}
                    </Text>
                  </Flex>
                </Box>
              </HStack>
              {uploadType === "url" ? (
                <Box mt="20px" borderRadius="14px">
                  <ReactPlayer
                    style={{ borderRadius: "14px", overflow: "hidden" }}
                    width="100%"
                    url={formik.values.url!}
                  />
                </Box>
              ) : (
                <HStack
                  mt="20px"
                  padding="18px"
                  backgroundColor="actionAreaBackgroundColor"
                  borderRadius="8px"
                  justifyContent="space-between"
                >
                  <HStack spacing={4}>
                    <MdOutlineInsertDriveFile size="30px" />
                    <VStack alignItems="flex-start">
                      <Heading fontSize="sm"> {upload?.name || "File"}</Heading>
                      <Text color="gray" fontSize="xs">
                        {CommonUtils.formatFileSize(upload?.size || 0)}
                      </Text>
                    </VStack>
                  </HStack>
                  <Button
                    size="sm"
                    colorScheme="secondary"
                    rightIcon={<MdOpenInNew />}
                    onClick={() =>
                      window.open(URL.createObjectURL(upload), "_blank")
                    }
                  >
                    Open
                  </Button>
                </HStack>
              )}
              <HStack mt="18px" justifyContent="space-between">
                <Button
                  leftIcon={<MdKeyboardArrowLeft size="24px" />}
                  fontSize="12px"
                  onClick={prevStep}
                >
                  Back
                </Button>
                <Button
                  onClick={formik.submitForm}
                  rightIcon={<MdKeyboardArrowRight size="24px" />}
                  colorScheme="secondary"
                  fontSize="12px"
                >
                  {formik.isSubmitting ? <Spinner size="xs" /> : "Save"}
                </Button>
              </HStack>
            </Step>
            <Step {...stepProp}>
              <Heading fontSize="2xl" my="20px">
                Well done!
              </Heading>
              <VStack spacing={4} mt="16px">
                <Flex
                  height="150px"
                  width="150px"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="50%"
                  backgroundColor="#ECECEC"
                >
                  <Image src="/assets/icons/finish.png" boxSize="70px" />
                </Flex>
                <Heading fontSize="lg">Upload successful!</Heading>
                <Button
                  onClick={onClose}
                  colorScheme="secondary"
                  fontSize="12px"
                >
                  Close
                </Button>
              </VStack>
            </Step>
          </Steps>
        </ModalHeader>
      </ModalContent>
    </Modal>
  );
};

export default inject("store")(observer(CreateContentDialog));
