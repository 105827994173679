import React from "react";
import { rootStore } from "../RootStore";

export const StoreContext = React.createContext(rootStore);

export function StoreProvider({ children }: any) {
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  );
}
