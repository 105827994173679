export enum UserRole {
  SUPER_ADMIN = "superAdmin",
  ACCOUNT_ADMIN = "accountAdmin",
  MANAGER = "manager",
  TEAM_LEADER = "teamLeader",
  TEAM_USER = "teamUser",
  USER = "user",
}

export enum InviteUserRole {
  SUPER_ADMIN = "superAdmin",
  ACCOUNT_ADMIN = "accountAdmin",
  MANAGER = "manager",
}

export const RoleWeights: Record<UserRole, number> = {
  superAdmin: 100,
  accountAdmin: 20,
  manager: 12,
  teamLeader: 11,
  teamUser: 10,
  user: 0,
};

/**
 * Returns whether base role is greater than compare role
 * @param aBaseRole Base role to compare
 * @param aCompareRole Target role to compare against
 * @returns True if base role weight is higher than compare role
 */
export const compareRoles = (
  aBaseRole: UserRole,
  aCompareRole: UserRole
): boolean => {
  return RoleWeights[aBaseRole] > RoleWeights[aCompareRole];
};
